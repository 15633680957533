import store from '@/store'

export default async (to, from, next) => {
  const user = store.getters['auth/user']

  if (user) {
    const isNotUser = user.role !== 'user'
    const isNotApprover = Number(user.is_approver) === 0
    const isNotBudgetOwner = !(user.budget && Object.hasOwn(user.budget, 'id'))

    // check if next route name is equal and user is not approver then redirect if true
    const cannotApprove = isNotApprover && [
      'user.payments.approvals'
    ].includes(to.name)

    const cannotViewBudgets = isNotBudgetOwner && [
      'user.budgets'
    ].includes(to.name)

    if (isNotUser || cannotApprove || cannotViewBudgets) {
      return next({
        name: `${user.role}.dashboard`
      })
    }

    return next()
  }

  return next({
    name: 'shared.redirect'
  })
}
