<template>
  <b-container
    class="mt-2"
    fluid
  >
    <!-- Filters -->
    <b-row>
      <b-col
        cols="12"
        class="px-1"
      >
        <b-form-group
          label="Search"
          label-for="filter_pending_search"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filter_pending_search"
            v-model="tablePending.filter.search"
            type="text"
            placeholder="search here"
            debounce="1000"
            autocomplete="off"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-row
      v-if="currentTabIndex === 0"
      class="mt-1"
    >
      <b-col
        cols="12"
        class="px-1"
      >
        <b-table
          ref="tablePending"
          small
          hover
          bordered
          responsive
          show-empty
          :stacked="isMobile()"
          :per-page="tablePending.perPage"
          :current-page="tablePending.currentPage"
          :items="tablePendingProvider"
          :fields="tablePending.fields"
          :sort-by.sync="tablePending.sortBy"
          :sort-desc.sync="tablePending.sortDesc"
          :sort-direction="tablePending.sortDirection"
          :filter="tablePending.filter"
          :filter-included-fields="tablePending.filterOn"
          :tbody-tr-class="tableRowClass"
          :busy="tablePending.busy"
        >
          <template #cell(index)="row">
            {{ tablePending.currentPage * tablePending.perPage - tablePending.perPage + (row.index + 1) }}
          </template>

          <template #cell()="row">
            <div class="text-md-nowrap">
              {{ row.value }}
            </div>
          </template>

          <template #cell(approvable.amount)="row">
            <div class="text-md-nowrap text-md-right">
              {{ row.value }}
            </div>
          </template>

          <template #cell(action)="row">
            <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
              <b-button
                size="sm"
                variant="success"
                :disabled="busy"
                @click="onProcess(row.item)"
              >
                Process
              </b-button>
            </div>
          </template>

          <template #table-busy>
            <div class="text-center py-5">
              <b-icon
                icon="stopwatch"
                font-scale="5"
                animation="cylon"
              />
              <p class="h3 py-2">
                <strong>Loading . . .</strong>
              </p>
            </div>
          </template>

        </b-table>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="mt-1 mb-2 px-1"
      >
        <b-select
          v-model="tablePending.perPage"
          :options="tablePending.pageOptions"
          class="w-100 w-md-25"
        />
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
      >
        <b-pagination
          v-model="tablePending.currentPage"
          pills
          last-number
          first-number
          prev-text="Prev"
          next-text="Next"
          aria-controls="table"
          :total-rows="tablePending.totalRows"
          :per-page="tablePending.perPage"
        />
      </b-col>

    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { SRAMPaymentApprovalPending } from '@/services'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'
export default {

  name: 'VRAMPaymentApprovalPending',

  mixins: [formatter, misc],

  props: {
    busy: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    currentTabIndex: {
      type: Number,
      default: () => {
        return 0
      }
    }
  },

  data () {
    return {
      tablePending: {
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 9, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'approvable.created_at', label: 'Requested At', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'approvable.bp_name', label: 'Business Partner' },
          { mobile: 3, key: 'approvable.customer_name', label: 'Cash Advance' },
          { mobile: 4, key: 'approvable.mop', label: 'Mode of Payment' },
          { mobile: 5, key: 'approvable.amount', label: 'Amount', formatter: this.numberFormatter },
          { mobile: 6, key: 'approvable.approvals', label: 'Time Lapsed', formatter: this.getTimeDifferenceFromLastApprover },
          { mobile: 7, key: 'last_seen', formatter: this.dateTimeShortFormatter },
          { mobile: 8, key: 'approvable.requestor', label: 'Requested By', formatter: this.userCurrentRequestor }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {
    async tablePendingProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tablePending.busy = true
      return await SRAMPaymentApprovalPending.get(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search
        })
      ).then(({ data }) => {
        this.tablePending.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tablePending.busy = false
      })
    },

    onProcess (paymentRequest) {
      SRAMPaymentApprovalPending.show(paymentRequest.id).then(
        ({ data }) => {
          const approver = paymentRequest.approvable.approvals.find(
            approver => String(approver.stage) === String(paymentRequest.stage)
          )
          approver.last_seen = paymentRequest.last_seen = data.last_seen
          this.$emit('onProcess', paymentRequest.approvable, paymentRequest, true)
        }
      )
    },

    onPut (paymentRequest) {
      return new Promise((resolve, reject) => {
        SRAMPaymentApprovalPending.put(paymentRequest).then(
          ({ data }) => resolve(data)
        ).catch(
          error => reject(error.message)
        )
      })
    },

    tablePendingRefresh () {
      if (this.currentTabIndex === 0) {
        this.$refs.tablePending.refresh()
      }
    }
  }

}
</script>
