function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/cmo/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const COMRoutes = [
  {
    path: '/cmo/dashboard',
    component: view('Dashboard'),
    name: 'cmo.dashboard',
    authorizedRole: 'cmo'
  },
  // PENDING APPROVALS
  {
    path: '/cmo/payments/checks/approvals/:cmo?',
    component: view('payments/checks/approvals/Base'),
    name: 'cmo.payments.check-approvals',
    authorizedRole: 'cmo',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Check Approvals',
          active: true
        }
      ]
    }
  }
]

export default COMRoutes
