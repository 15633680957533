<template>
  <b-container
    class="px-0"
    fluid
  >
    <ValidationObserver
      v-if="showUploader"
      ref="formBPAttachments"
    >
      <b-row>
        <b-col
          cols="12"
        >
          <ValidationProvider
            #default="{ errors }"
            vid="attachments"
            name="attachments"
          >
            <b-form-group
              label="Attachments"
              label-for="bpAttachments"
              label-class="font-weight-bolder"
              description="Please attach valid Certificate of Registration / Sales Invoice / Receipt"
            >
              <b-form-file
                id="bpAttachments"
                v-model="attachment.list"
                :accept="accept"
                :multiple="multiple"
                :state="(errors.length > 0 ? false : null)"
                :disabled="(compressed.compressing || preparing || uploading)"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col
          v-if="(showProgress && upload.progress > 0)"
          cols="12"
          class="pb-2"
        >
          <b-progress
            :value="upload.progress"
            striped
            animated
            show-progress
            variant="success"
          />
        </b-col>
        <b-col
          cols="12"
        >
          <b-button
            class="mr-1"
            :variant="(attachment.list.length <= 0 ? 'outline-success' : 'success')"
            :disabled="(attachment.list.length <= 0 || compressed.compressing || preparing || uploading)"
            @click="prepareAttachments"
          >
            {{ UploadButton }}
          </b-button>
          <b-button
            :variant="(uploaded.list.length <= 0 ? 'outline-danger' : 'danger')"
            :disabled="(uploaded.list.length <= 0 || compressed.compressing || preparing || uploading)"
            @click="onDeleteAll"
          >
            Delete All
          </b-button>
        </b-col>
      </b-row>
    </ValidationObserver>

    <b-row
      class="mt-3 px-1"
    >
      <b-col
        v-if="uploaded.list.length <= 0"
        cols="12"
      >
        <div class="d-flex justify-content-center align-items-center flex-column">
          <b-img
            :src="getInvalidImageUrl"
          />
          <div class="h4 py-3">
            No Attachments
          </div>
        </div>
      </b-col>
      <b-col
        v-for="(file, key) in uploaded.list"
        :key="key"
        cols="12"
        md="4"
        class="pb-3 px-2"
      >
        <div
          class="d-flex flex-row border rounded h-100 attachment-background"
        >
          <div
            v-if="file.file_extension === 'pdf'"
            class="p-2"
          >
            <b-img
              :src="pdfImageEndpoint"
              class="attachment"
            />
          </div>
          <div
            v-else
            class="p-2"
          >
            <b-img
              :src="uploadedAttachmentEndpoint(file.file_hashed_name)"
              class="attachment"
              @error="invalidAttachment"
            />
          </div>
          <div class="d-flex flex-column justify-content-between w-100 h-100 p-2">
            <div class="d-flex flex-column">
              <!-- <div class="d-flex justify-content-end">
                <b-form-checkbox
                  id="is_active"
                  value="1"
                  name="is_active"
                  unchecked-value="0"
                />
              </div> -->
              <div class="text-break font-weight-bolder">
                {{ file.file_original_name }}
              </div>
            </div>
            <div class="align-self-end">
              <b-button
                size="sm"
                @click="onPreview(file, key)"
              >
                View
              </b-button>
              <b-button
                v-if="showUploader"
                size="sm"
                variant="outline-danger"
                class="ml-1"
                @click="onDelete(file, key)"
              >
                Delete
              </b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-modal
      id="modal-business-partners-attachments"
      size="xl"
      scrollable
      hide-header
      aria-hidden="false"
      no-close-on-backdrop
      modal-class="fullscreen"
    >
      <b-carousel
        id="pr_carousel_attachments"
        v-model="carousel"
        controls
        indicators
        no-touch
        background="#aaa"
        class="h-100"
        :interval="1000000"
      >
        <b-carousel-slide
          v-for="(file, key) in uploaded.list"
          :key="key"
          style="min-height: calc(100vh - 85px);"
        >
          <template #img>
            <div
              v-if="file.file_extension === 'pdf'"
              class="d-flex justify-content-center align-items-center"
            >
              <iframe
                v-if="carousel === key"
                :src="uploadedAttachmentEndpoint(file.file_hashed_name)"
                @error="invalidAttachment"
              />
            </div>
            <div
              v-else
              class="d-flex justify-content-center align-items-center"
            >
              <panZoom
                :options="{
                  minZoom: 0.5,
                  maxZoom: 5,
                  bounds: true,
                  boundsPadding: 0.5
                }"
              >
                <b-img
                  :src="uploadedAttachmentEndpoint(file.file_hashed_name)"
                  class="d-blocks"
                  @error="invalidAttachment"
                />
              </panZoom>
            </div>
          </template>
        </b-carousel-slide>
      </b-carousel>
      <template #modal-footer="{ cancel }">
        <div class="col-12 d-flex justify-content-between px-0 pb-0 mb-0">
          <div />
          <div>
            <b-button
              variant="outline-dark"
              :disabled="busy"
              @click="cancel()"
            >
              Close <span class="d-none d-md-inline-block">Window</span>
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </b-container>
</template>
<script>
import { SSharedBusinessPartnerAttachment } from '@/services'
import Compressor from 'compressorjs'
import misc from '@/mixins/misc'
// import PDFViewer from '@/components/custom/PDFViewer'
export default {
  name: 'VSharedBusinessPartnerAttachment',
  // components: {
  //   PDFViewer
  // },
  mixins: [misc],
  props: {
    accept: {
      type: String,
      default: () => 'image/jpg, image/jpeg, image/png, application/pdf'
    },
    multiple: {
      type: Boolean,
      default: () => true
    },
    showProgress: {
      type: Boolean,
      default: () => true
    },
    showUploader: {
      type: Boolean,
      default: () => true
    },
    filePath: {
      type: String,
      default: () => ''
    },
    fileList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      busy: false,
      preparing: false,
      uploading: false,
      carousel: 0,
      upload: {
        progress: 0,
        loaded: 0,
        total: 0
      },
      attachment: {
        list: [],
        path: this.filePath
      },
      compressed: {
        compressing: false,
        list: []
      },
      uploaded: {
        list: this.fileList ? this.fileList : []
      }
    }
  },
  computed: {

    getInvalidImageUrl () {
      return `${window.location.origin}/img/invalid-image.png`
    },

    pdfImageEndpoint () {
      return `${window.location.origin}/img/pdf.png`
    },

    UploadButton () {
      if (this.compressed.compressing) {
        return 'Compressing'
      }
      if (this.preparing) {
        return 'Preparing'
      }
      if (this.uploading) {
        return 'Uploading'
      }
      return 'Upload'
    }
  },

  watch: {
    async 'attachment.list' (lists) {
      this.compressed.compressing = true

      const PROCESSED_ATTACHMENTS = []
      const BATCH_SIZE = 2
      const TOTAL_COUNT = lists.length - 1
      let cAttachment = 0

      while (cAttachment <= TOTAL_COUNT) {
        const BATCH_END = Math.min(cAttachment + BATCH_SIZE - 1, TOTAL_COUNT)
        const PROMISSES = []
        for (let iAttachment = cAttachment; iAttachment <= BATCH_END; iAttachment++) {
          const currentAttachment = lists[iAttachment]
          if (['application/pdf'].includes(currentAttachment.type)) {
            PROMISSES.push(currentAttachment)
          } else {
            PROMISSES.push(
              new Promise(resolve =>
                new Compressor(currentAttachment, {
                  quality: currentAttachment.size <= 1000000 ? 1 : 0.2,
                  convertSize: currentAttachment.size <= 1000000 ? currentAttachment.size : currentAttachment.size / 3,
                  mimeType: currentAttachment.type,
                  success: blob => {
                    resolve(blob)
                    return blob
                  }
                })
              )
            )
          }
        }
        const BATCH_ATTACHMENTS = await Promise.all(PROMISSES)
        PROCESSED_ATTACHMENTS.push(...BATCH_ATTACHMENTS)
        cAttachment += BATCH_SIZE
      }

      this.compressed.list = PROCESSED_ATTACHMENTS
      this.compressed.compressing = false
    }
  },

  methods: {

    uploadedAttachmentEndpoint (fileName) {
      if (this.showUploader) {
        return `${process.env.VUE_APP_API_BASE_URL}/business-partners-uploads/${this.attachment.path}/${fileName}`
      }
      return `${process.env.VUE_APP_API_BASE_URL}/business-partners-attachments/${this.attachment.path}/${fileName}`
    },

    invalidAttachment ({ target }) {
      target.src = require('@/assets/images/invalid-image.png')
    },

    async prepareAttachments () {
      this.preparing = true
      const oneMegaBytes = 1000000
      const isAttachmentPathEmpty = [null, 'null', ''].includes(this.attachment.path)

      let totalSize = this.uploaded.list.map(file => file.file_size).reduce(
        (totalSize, fileSize) => parseFloat(totalSize) + parseFloat(fileSize), 0
      )

      const form = new FormData()
      form.append('attachment_path', (isAttachmentPathEmpty ? '' : this.attachment.path))
      this.compressed.list.forEach((blob, position) => {
        totalSize += parseFloat(blob.size)
        if (['application/pdf'].includes(blob.type)) {
          form.append(`file[${position}]`, blob)
        } else {
          form.append(`file[${position}]`, blob, blob.name)
        }
      })

      if (parseFloat(totalSize) <= parseFloat(oneMegaBytes * 25)) {
        setTimeout(() => {
          this.uploadAttachments(form)
        }, 500)
      } else {
        this.preparing = false
        this.$refs.formBPAttachments.setErrors({
          attachments: ['The attachment total size must be atleast lesser than or equal to 25MB']
        })
      }
    },

    async uploadAttachments (form) {
      this.preparing = false
      this.uploading = true
      return await SSharedBusinessPartnerAttachment.uploadAttachments(form, (progress, loaded, total) => {
        this.upload.progress = progress
        this.upload.loaded = loaded
        this.upload.total = total
      }).then(({ data }) => {
        this.$refs.formBPAttachments.reset()
        this.attachment.list = this.compressed.list = []
        this.attachment.path = data.attachment_path
        this.uploaded.list.push(...data.attachments)
        this.upload.progress = 0
        this.upload.loaded = 0
        this.upload.total = 0
        this.onUploaded()
      }).finally(() => {
        this.upload.progress = 0
        this.upload.loaded = 0
        this.upload.total = 0
        this.uploading = false
      })
    },

    async onPreview (_attachment, position) {
      this.carousel = position
      this.$bvModal.show('modal-business-partners-attachments')
    },

    async onDelete (attachment) {
      this.swalConfirm({
        html: '<h6>Delete this Attachment?</h6>',
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-sm btn-danger'
        },
        preConfirm: async () => {
          this.busy = true
          return new Promise(resolve => {
            SSharedBusinessPartnerAttachment.removeAttachments({
              attachment_path: this.attachment.path,
              file_name: attachment.file_hashed_name
            }).then(({ data: { message } }) => {
              this.$refs.formBPAttachments.reset()
              this.swalSuccess(message).then(() => {
                this.uploaded.list = this.uploaded.list.filter(file => file.file_hashed_name !== attachment.file_hashed_name)
                this.onUploaded()
              })
              resolve(message)
            }).catch(
              error => resolve(error.message)
            ).finally(() => {
              this.busy = false
            })
          })
        }
      })
    },

    async onDeleteAll () {
      this.swalConfirm({
        html: '<h6>Delete all the Attachments?</h6>',
        confirmButtonText: 'Delete All',
        customClass: {
          confirmButton: 'btn btn-sm btn-danger'
        },
        preConfirm: async () => {
          this.busy = true
          return new Promise(resolve => {
            SSharedBusinessPartnerAttachment.removeAllAttachments({
              attachment_path: this.attachment.path
            }).then(({ data: { message } }) => {
              this.$refs.formBPAttachments.reset()
              this.swalSuccess(message).then(() => {
                this.uploaded.list = []
                this.onUploaded()
              })
              resolve(message)
            }).catch(
              error => resolve(error.message)
            ).finally(() => {
              this.busy = false
            })
          })
        }
      })
    },

    onUploaded () {
      this.$emit('onUploaded', {
        attachment_path: this.attachment.path,
        attachments: this.uploaded.list
      })
    },

    setErrors (message) {
      this.$refs.formBPAttachments.setErrors({
        attachments: [message]
      })
    }

  }
}
</script>
<style>
 .attachment-background {
  background-color: rgb(250, 250, 250);
 }

.carousel-control-next:hover, .carousel-control-prev:hover {
  background-color: #888;
}

img.attachment {
  width: 150px !important;
  height: 150px !important;
  object-fit: contain !important;
  border-radius: 8px;
  border: 1px solid gray !important;
  background-color: #aaa !important;
}

div.attachment {
  width: 150px !important;
  height: 150px !important;
  padding: 4px !important;
  position: relative !important;
  iframe.attachment-frame {
    width: 150px !important;
    height: 150px !important;
    border-radius: 8px;
    border: 1px solid gray !important;
    background-color: #aaa !important;
  }
}

.carousel-item {
  div.d-flex.justify-content-center.align-items-center {
    min-height: calc(100vh - 85px);
    max-height: calc(100vh - 85px);
    min-width: 100vw;
    max-width: 100vw;
    transition: transform 0.3s ease-in-out;
    iframe {
      min-height: calc(100vh - 85px);
      max-height: calc(100vh - 85px);
      min-width: 68vw;
      max-width: 68vw;
    }
    img.d-blocks {
      min-height: calc(100vh - 85px);
      max-height: calc(100vh - 85px);
      min-width: 68vw;
      max-width: 68vw;
      object-fit: contain;
      position: relative;
    }
  }
}

.progress > .progress-bar.bg-success {
    background-image: linear-gradient(
      45deg,
    rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 1rem 1rem;
}
</style>
