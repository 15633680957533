function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/audit/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const AuditRoutes = [
  {
    path: '/audit/dashboard',
    component: view('Dashboard'),
    name: 'audit.dashboard',
    authorizedRole: 'audit'
  },
  {
    path: '/audit/payment-vouchers/:audit?',
    component: view('PaymentVouchers'),
    name: 'audit.payments.vouchers',
    authorizedRole: 'audit',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Payment Vouchers',
          active: true
        }
      ]
    }
  },
  {
    path: '/audit/item-centers',
    component: view('item-centers/Base'),
    name: 'audit.item-centers',
    authorizedRole: 'audit',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Maintenances'
        },
        {
          text: 'Item Centers',
          active: true
        }
      ]
    }
  }
]

export default AuditRoutes
