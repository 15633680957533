<template>
  <b-container
    fluid
  >
    <div class="d-flex justify-content-end mb-3 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-container fluid>
      <b-row>

        <b-col
          cols="12"
          md="10"
          class="px-1"
          order="2"
          order-md="1"
        >
          <b-form-group
            label="Search"
            label-for="filterBusinessPartnerSearch"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filterBusinessPartnerSearch"
              v-model="tableBusinessPartnerDraft.filter.search"
              type="text"
              debounce="1000"
              autocomplete="off"
              placeholder="search here"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="2"
          class="px-1"
          order="1"
          order-md="2"
        >
          <b-form-group
            label="Status"
            label-for="filterStatuses"
            label-class="font-weight-bolder"
          >
            <b-select
              id="filterStatuses"
              v-model="tableBusinessPartnerDraft.filter.status"
              debounce="1000"
              :options="tableBusinessPartnerDraft.options.statuses"
              :disabled="state.busy || fetching.filterOptions"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          order="3"
          class="px-1 mb-2"
        >
          <b-button
            size="sm"
            type="button"
            variant="success"
            @click="onCreateBusinessPartnerDraft"
          >
            Add Business Partner
          </b-button>

          <b-button
            size="sm"
            class="ml-1"
            :variant="['Pending'].includes(tableBusinessPartnerDraft.filter.status) && businessPartnerDrafts.selected.length > 0 ? 'danger' : 'outline-danger'"
            :disabled="tableBusinessPartnerDraft.busy || state.busy || businessPartnerDrafts.selected.length <= 0 || !['Pending'].includes(tableBusinessPartnerDraft.filter.status)"
            @click="onDiscardSelected"
          >
            Discard Selected
          </b-button>
        </b-col>

      </b-row>
      <b-row class="mt-1">
        <b-col
          cols="12"
          class="px-1"
        >
          <b-table
            ref="tableBusinessPartnerDraft"
            hover
            small
            striped
            bordered
            responsive
            show-empty
            :items="tableBusinessPartnerDraftProvider"
            :busy="tableBusinessPartnerDraft.busy"
            :filter="tableBusinessPartnerDraft.filter"
            :fields="tableBusinessPartnerDraft.fields"
            :stacked="tableBusinessPartnerDraft.stacked"
            :sort-by.sync="tableBusinessPartnerDraft.sortBy"
            :sort-desc.sync="tableBusinessPartnerDraft.sortDesc"
            :sort-direction="tableBusinessPartnerDraft.sortDirection"
            :filter-included-fields="tableBusinessPartnerDraft.filterOn"
            :current-page="tableBusinessPartnerDraft.currentPage"
            :per-page="tableBusinessPartnerDraft.perPage"
          >
            <template #cell(index)="row">
              {{ tableBusinessPartnerDraft.currentPage * tableBusinessPartnerDraft.perPage - tableBusinessPartnerDraft.perPage + (row.index + 1) }}
            </template>

            <template #head(selected)>
              <b-form-checkbox
                v-if="['Pending'].includes(tableBusinessPartnerDraft.filter.status)"
                v-model="selected.allSelected"
                :indeterminate="selected.indeterminate"
                :disabled="Number(tableBusinessPartnerDraft.totalRows) === 0 || tableBusinessPartnerDraft.busy"
                size="lg"
                class="ml-2"
                @change="onSelectAllTableRows"
              />
              <div
                v-else
                class="w-30px"
              >
                &nbsp;
              </div>
            </template>

            <template #cell(selected)="row">
              <b-form-checkbox
                v-if="['Pending'].includes(tableBusinessPartnerDraft.filter.status)"
                v-model="businessPartnerDrafts.selected"
                :value="row.item.id"
                :disabled="tableBusinessPartnerDraft.busy"
                size="lg"
                class="ml-2"
              />
            </template>

            <template #cell(active)="row">
              {{ Number(row.value) === 1 ? 'Yes' : 'No' }}
            </template>

            <template #cell()="row">
              <div class="text-md-nowrap">
                {{ row.value }}
              </div>
            </template>

            <template #cell(action)="row">
              <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-md-start justify-content-center">
                <b-button
                  size="sm"
                  :disabled="state.busy"
                  class="mr-0 mr-md-1 mb-1 mb-md-0"
                  @click="onViewBusinessPartner(row.item)"
                >
                  View
                </b-button>
                <b-button
                  v-if="row.item.status === 'Disapproved'"
                  size="sm"
                  variant="success"
                  class="mr-0 mr-md-1 mb-1 mb-md-0"
                  @click="onEditBusinessPartnerDraft(row.item)"
                >
                  Edit as New
                </b-button>
                <b-button
                  v-if="row.item.status === 'Pending'"
                  size="sm"
                  variant="outline-danger"
                  :disabled="state.busy || businessPartnerDrafts.selected.length > 0"
                  @click="onDiscard(row.item)"
                >
                  Discard
                </b-button>
              </div>
            </template>

            <template #table-busy>
              <div class="text-center py-5">
                <b-icon
                  icon="stopwatch"
                  font-scale="5"
                  animation="cylon"
                />
                <p class="h3 py-2">
                  <strong>Loading . . .</strong>
                </p>
              </div>
            </template>

          </b-table>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mt-1 mb-2 px-1"
        >
          <b-select
            v-model="tableBusinessPartnerDraft.perPage"
            :options="tableBusinessPartnerDraft.pageOptions"
            class="w-100 w-md-25"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
        >
          <b-pagination
            v-model="tableBusinessPartnerDraft.currentPage"
            pills
            last-number
            first-number
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
            :total-rows="tableBusinessPartnerDraft.totalRows"
            :per-page="tableBusinessPartnerDraft.perPage"
          />
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="modal-business-partner"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      aria-hidden="false"
      modal-class="full-height"
      :title="ModalBusinessPartnerTitle"
    >
      <b-container
        class="h-100 py-1 px-1"
        fluid
      >
        <b-tabs
          active-nav-item-class="font-weight-bold text-uppercase text-success"
          content-class="scrollable"
        >
          <b-tab class="p-2">
            <template #title>
              <div class="text-md-nowrap">
                <i
                  :class="['ri-file-text-line h4', {
                    'text-danger fat fa-shake': vTabBusinessPartner()
                  }]"
                />
                <b
                  :class="['is-tab-active ml-1', {
                    'text-danger': vTabBusinessPartner()
                  }]"
                >
                  Details
                </b>
              </div>
            </template>

            <ValidationObserver
              ref="formBusinessPartner"
            >
              <form
                role="form"
                novalidate
                @submit.prevent
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-row>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="tin"
                          vid="tin"
                          rules="required|min:17|max:17"
                        >
                          <b-form-group
                            label="TIN"
                            label-for="partner_tin"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="partner_tin"
                              v-model="businessPartnerDraft.tin"
                              v-mask="'###-###-###-###NN'"
                              type="text"
                              maxlength="18"
                              autocomplete="off"
                              placeholder="enter tin here"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="partner type"
                          vid="partner_type"
                          rules="required"
                        >
                          <b-form-group
                            label="Partner Type"
                            label-for="partner_type"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-form-select
                              id="partner_type"
                              v-model="businessPartnerDraft.partner_type"
                              :options="lists.partnerTypes"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy || fetching.options"
                            >
                              <template #first>
                                <b-form-select-option
                                  :value="null"
                                  disabled
                                >
                                  -- select partner type here --
                                </b-form-select-option>
                              </template>
                            </b-form-select>
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="business tax"
                          vid="business_tax"
                          rules="required"
                        >
                          <b-form-group
                            label="Business Tax"
                            label-for="business_tax"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-form-select
                              id="business_tax"
                              v-model="businessPartnerDraft.business_tax"
                              :options="lists.businessTaxes"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy || fetching.options || businessPartnerDraft.partner_type !== 'Vendor'"
                            >
                              <template #first>
                                <b-form-select-option
                                  :value="null"
                                  disabled
                                >
                                  -- select business tax here --
                                </b-form-select-option>
                              </template>
                            </b-form-select>
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="business type"
                          vid="business_type"
                          rules="required_if:partner_type,Vendor"
                        >
                          <b-form-group
                            label="Business Type"
                            label-for="business_type"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-form-select
                              id="business_type"
                              v-model="businessPartnerDraft.business_type"
                              :options="lists.businessTypes"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy || fetching.options || businessPartnerDraft.partner_type !== 'Vendor'"
                            >
                              <template #first>
                                <b-form-select-option
                                  :value="null"
                                  disabled
                                >
                                  -- select business type here --
                                </b-form-select-option>
                              </template>
                            </b-form-select>
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        v-if="businessPartnerDraft.partner_type === 'Vendor'"
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="corporation name"
                          vid="corporation_name"
                          rules="required_if:business_type,Corporation,Cooperative,Government|max:255"
                        >
                          <b-form-group
                            :label="(`${businessPartnerDraft.business_type || ''} Name`)"
                            label-for="corporation_name"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-textarea
                              id="corporation_name"
                              v-model="businessPartnerDraft.corporation_name"
                              rows="3"
                              max-rows="6"
                              maxlength="255"
                              autocomplete="off"
                              :placeholder="(`enter ${businessPartnerDraft.business_type?.toLowerCase() || ''} name here`)"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy || businessPartnerDraft.partner_type !== 'Vendor' || businessPartnerDraft.business_type === null"
                              @keydown="onPreventEnterOnKeyboard"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        v-else
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="cash advance name"
                          vid="partner_name"
                          rules="required_if:business_type,Corporation,Cooperative,Government|max:255"
                        >
                          <b-form-group
                            label="Cash Advance Name"
                            label-for="partner_name"
                            label-class="font-weight-bolder"
                            description="Please follow this format: (SURNAME), (FIRST NAME) (MIDDLE INITIAL)"
                            class="mb-2"
                          >
                            <b-textarea
                              id="partner_name"
                              v-model="businessPartnerDraft.partner_name"
                              rows="3"
                              max-rows="6"
                              maxlength="255"
                              autocomplete="off"
                              placeholder="enter cash advance name here"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy || businessPartnerDraft.partner_type !== 'Customer'"
                              @keydown="onPreventEnterOnKeyboard"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="trade name"
                          vid="trade_name"
                          rules="required_if:partner_type,Vendor|max:255"
                        >
                          <b-form-group
                            label="Trade Name"
                            label-for="trade_name"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-textarea
                              id="trade_name"
                              v-model="businessPartnerDraft.trade_name"
                              rows="3"
                              max-rows="6"
                              maxlength="255"
                              autocomplete="off"
                              placeholder="enter trade name here"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy || businessPartnerDraft.partner_type !== 'Vendor'"
                              @keydown="onPreventEnterOnKeyboard"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <b-row>
                          <b-col
                            cols="12"
                          >
                            <b-form-group
                              label="Owner / Authorized Representative"
                              label-cols-md="12"
                              label-class="font-weight-bolder"
                              class="mb-2"
                            />
                          </b-col>
                          <b-col
                            cols="12"
                          >
                            <ValidationProvider
                              #default="{ errors }"
                              name="first name"
                              vid="owner_first_name"
                              rules="required_if:business_type,Sole-Proprietorship|max:50"
                            >
                              <b-form-group
                                label="First Name"
                                label-for="owner_first_name"
                                label-cols-md="4"
                                label-class="font-weight-bolder"
                                class="mb-2"
                              >
                                <b-input
                                  id="owner_first_name"
                                  v-model="businessPartnerDraft.owner_first_name"
                                  type="text"
                                  maxlength="50"
                                  autocomplete="off"
                                  placeholder="enter first name here"
                                  :state="errors.length > 0 ? false : null"
                                  :disabled="state.busy || businessPartnerDraft.partner_type !== 'Vendor' || businessPartnerDraft.business_type !== 'Sole-Proprietorship'"
                                />
                                <div
                                  v-if="errors.length > 0"
                                  class="invalid-feedback"
                                >
                                  <span>{{ errors[0] }}</span>
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>

                          <b-col
                            cols="12"
                          >
                            <ValidationProvider
                              #default="{ errors }"
                              name="contact person"
                              vid="owner_middle_name"
                              rules="max:100"
                            >
                              <b-form-group
                                label="Middle Name"
                                label-for="owner_middle_name"
                                label-cols-md="4"
                                label-class="font-weight-bolder"
                                class="mb-2"
                              >
                                <b-input
                                  id="owner_middle_name"
                                  v-model="businessPartnerDraft.owner_middle_name"
                                  type="text"
                                  maxlength="100"
                                  autocomplete="off"
                                  placeholder="enter middle name here"
                                  :state="errors.length > 0 ? false : null"
                                  :disabled="state.busy || businessPartnerDraft.partner_type !== 'Vendor' || businessPartnerDraft.business_type !== 'Sole-Proprietorship'"
                                />
                                <div
                                  v-if="errors.length > 0"
                                  class="invalid-feedback"
                                >
                                  <span>{{ errors[0] }}</span>
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>

                          <b-col
                            cols="12"
                          >
                            <ValidationProvider
                              #default="{ errors }"
                              name="surname"
                              vid="owner_last_name"
                              rules="required_if:business_type,Sole-Proprietorship|max:100"
                            >
                              <b-form-group
                                label="Surname"
                                label-for="owner_last_name"
                                label-cols-md="4"
                                label-class="font-weight-bolder"
                                class="mb-2"
                              >
                                <b-input
                                  id="owner_last_name"
                                  v-model="businessPartnerDraft.owner_last_name"
                                  type="text"
                                  maxlength="100"
                                  autocomplete="off"
                                  placeholder="enter surname here"
                                  :state="errors.length > 0 ? false : null"
                                  :disabled="state.busy || businessPartnerDraft.partner_type !== 'Vendor' || businessPartnerDraft.business_type !== 'Sole-Proprietorship'"
                                />
                                <div
                                  v-if="errors.length > 0"
                                  class="invalid-feedback"
                                >
                                  <span>{{ errors[0] }}</span>
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>

                        </b-row>
                      </b-col>

                    </b-row>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-row>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="telephone number"
                          vid="telephone_number"
                          rules="max:30"
                        >
                          <b-form-group
                            label="Tel. Number"
                            label-for="telephone_number"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="telephone_number"
                              v-model="businessPartnerDraft.telephone_number"
                              type="text"
                              maxlength="30"
                              autocomplete="off"
                              placeholder="enter telephone number here"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="mobile number"
                          vid="mobile_number"
                          rules="min:11|max:11"
                        >
                          <b-form-group
                            label="Mobile Number"
                            label-for="mobile_number"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="mobile_number"
                              v-model="businessPartnerDraft.mobile_number"
                              type="text"
                              maxlength="11"
                              autocomplete="off"
                              placeholder="enter mobile number here"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="email"
                          vid="email"
                          rules="email|max:50"
                        >
                          <b-form-group
                            label="Email"
                            label-for="email"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="email"
                              v-model="businessPartnerDraft.email"
                              type="text"
                              maxlength="50"
                              autocomplete="off"
                              placeholder="enter email here"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="contact person"
                          vid="contact_person"
                          rules="max:100"
                        >
                          <b-form-group
                            label="Contact Person"
                            label-for="contact_person"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="contact_person"
                              v-model="businessPartnerDraft.contact_person"
                              type="text"
                              maxlength="100"
                              autocomplete="off"
                              placeholder="enter contact person here"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="address"
                          vid="address"
                          rules="max:255"
                        >
                          <b-form-group
                            label="Address"
                            label-for="address"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-textarea
                              id="address"
                              v-model="businessPartnerDraft.address"
                              rows="3"
                              max-rows="6"
                              maxlength="255"
                              autocomplete="off"
                              placeholder="enter address here"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy"
                              @keydown="onPreventEnterOnKeyboard"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </b-col>

                </b-row>

              </form>
            </ValidationObserver>

          </b-tab>

          <b-tab class="p-2">
            <template #title>
              <div class="text-md-nowrap">
                <i
                  :class="['ri-attachment-line h4', {
                    'text-danger fat fa-shake': vTabAttachment()
                  }]"
                />
                <b
                  :class="['is-tab-active ml-1', {
                    'text-danger': vTabAttachment()
                  }]"
                >
                  Attachments
                </b>
              </div>
            </template>

            <BPAttachment
              ref="bpAttachments"
              :file-path="businessPartnerDraft.attachment_path"
              :file-list="businessPartnerDraft.attachments"
              :editing="state.editing"
              @onUploaded="onUploadedAttachments"
            />
          </b-tab>
        </b-tabs>

      </b-container>

      <template #modal-footer="{cancel}">
        <div class="col-12 d-flex justify-content-between px-0 pb-0 mb-0">
          <div />
          <div>
            <b-button
              class="mr-1"
              variant="success"
              :disabled="state.busy"
              @click="onValidateBusinessPartnerDraft"
            >
              Submit Registration
            </b-button>
            <b-button
              variant="outline-dark"
              :disabled="state.busy"
              @click="cancel()"
            >
              Close <span class="d-none d-md-inline-block">Window</span>
            </b-button>
          </div>
        </div>
      </template>

    </b-modal>

    <b-modal
      id="modal-view-business-partner"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      aria-hidden="false"
      modal-class="full-height"
      :title="ModalBusinessPartnerTitle"
    >
      <b-container
        class="h-100 py-1 px-1"
        fluid
      >
        <b-tabs
          active-nav-item-class="font-weight-bold text-uppercase text-success"
          content-class="scrollable"
        >
          <b-tab class="p-2">
            <template #title>
              <div class="text-md-nowrap">
                <i class="ri-file-text-line h4" />
                <b class="is-tab-active ml-1">
                  Details
                </b>
              </div>
            </template>

            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>

                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      label="TIN"
                      label-for="partner_tin"
                      label-cols-md="4"
                      label-class="font-weight-bolder"
                      class="mb-2"
                    >
                      <b-input
                        id="partner_tin"
                        v-model="viewBusinessPartnerDraft.tin"
                        v-mask="'###-###-###-###NN'"
                        type="text"
                        autocomplete="off"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      label="Partner Type"
                      label-for="partner_type"
                      label-cols-md="4"
                      label-class="font-weight-bolder"
                      class="mb-2"
                    >
                      <b-input
                        id="partner_type"
                        v-model="viewBusinessPartnerDraft.partner_type"
                        type="text"
                        autocomplete="off"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      label="Business Tax"
                      label-for="business_tax"
                      label-cols-md="4"
                      label-class="font-weight-bolder"
                      class="mb-2"
                    >
                      <b-input
                        id="business_tax"
                        v-model="viewBusinessPartnerDraft.business_tax"
                        type="text"
                        autocomplete="off"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      label="Business Type"
                      label-for="business_type"
                      label-cols-md="4"
                      label-class="font-weight-bolder"
                      class="mb-2"
                    >
                      <b-input
                        id="business_type"
                        v-model="viewBusinessPartnerDraft.business_type"
                        type="text"
                        autocomplete="off"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    v-if="viewBusinessPartnerDraft.partner_type === 'Vendor'"
                    cols="12"
                  >
                    <b-form-group
                      :label="(`${viewBusinessPartnerDraft.business_type || ''} Name`)"
                      label-for="corporation_name"
                      label-class="font-weight-bolder"
                      class="mb-2"
                    >
                      <b-textarea
                        id="corporation_name"
                        v-model="viewBusinessPartnerDraft.corporation_name"
                        rows="3"
                        max-rows="6"
                        maxlength="255"
                        autocomplete="off"
                        :placeholder="(`enter ${viewBusinessPartnerDraft.business_type?.toLowerCase() || ''} name here`)"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    v-else
                    cols="12"
                  >
                    <b-form-group
                      label="Cash Advance Name"
                      label-for="partner_name"
                      label-class="font-weight-bolder"
                      description="Format: (SURNAME), (FIRST NAME) (MIDDLE INITIAL)"
                      class="mb-2"
                    >
                      <b-textarea
                        id="partner_name"
                        v-model="viewBusinessPartnerDraft.partner_name"
                        rows="3"
                        max-rows="6"
                        maxlength="255"
                        autocomplete="off"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      label="Trade Name"
                      label-for="trade_name"
                      label-class="font-weight-bolder"
                      class="mb-2"
                    >
                      <b-textarea
                        id="trade_name"
                        v-model="viewBusinessPartnerDraft.trade_name"
                        rows="3"
                        max-rows="6"
                        maxlength="255"
                        autocomplete="off"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          label="Owner / Authorized Representative"
                          label-cols-md="12"
                          label-class="font-weight-bolder"
                          class="mb-2"
                        />
                      </b-col>
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          label="First Name"
                          label-for="owner_first_name"
                          label-cols-md="4"
                          label-class="font-weight-bolder"
                          class="mb-2"
                        >
                          <b-input
                            id="owner_first_name"
                            v-model="viewBusinessPartnerDraft.owner_first_name"
                            type="text"
                            maxlength="50"
                            autocomplete="off"
                            disabled
                          />
                        </b-form-group>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          label="Middle Name"
                          label-for="owner_middle_name"
                          label-cols-md="4"
                          label-class="font-weight-bolder"
                          class="mb-2"
                        >
                          <b-input
                            id="owner_middle_name"
                            v-model="viewBusinessPartnerDraft.owner_middle_name"
                            type="text"
                            maxlength="100"
                            autocomplete="off"
                            disabled
                          />
                        </b-form-group>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          label="Surname"
                          label-for="owner_last_name"
                          label-cols-md="4"
                          label-class="font-weight-bolder"
                          class="mb-2"
                        >
                          <b-input
                            id="owner_last_name"
                            v-model="viewBusinessPartnerDraft.owner_last_name"
                            type="text"
                            maxlength="100"
                            autocomplete="off"
                            disabled
                          />
                        </b-form-group>
                      </b-col>

                    </b-row>
                  </b-col>

                </b-row>
              </b-col>

              <b-col
                cols="12"
                md="6"
              >
                <b-row>

                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      label="Tel. Number"
                      label-for="telephone_number"
                      label-cols-md="4"
                      label-class="font-weight-bolder"
                      class="mb-2"
                    >
                      <b-input
                        id="telephone_number"
                        v-model="viewBusinessPartnerDraft.telephone_number"
                        type="text"
                        maxlength="30"
                        autocomplete="off"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      label="Mobile Number"
                      label-for="mobile_number"
                      label-cols-md="4"
                      label-class="font-weight-bolder"
                      class="mb-2"
                    >
                      <b-input
                        id="mobile_number"
                        v-model="viewBusinessPartnerDraft.mobile_number"
                        type="text"
                        maxlength="11"
                        autocomplete="off"
                        disabled
                      />

                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      label="Email"
                      label-for="email"
                      label-cols-md="4"
                      label-class="font-weight-bolder"
                      class="mb-2"
                    >
                      <b-input
                        id="email"
                        v-model="viewBusinessPartnerDraft.email"
                        type="text"
                        maxlength="50"
                        autocomplete="off"
                        disabled
                      />

                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      label="Contact Person"
                      label-for="contact_person"
                      label-cols-md="4"
                      label-class="font-weight-bolder"
                      class="mb-2"
                    >
                      <b-input
                        id="contact_person"
                        v-model="viewBusinessPartnerDraft.contact_person"
                        type="text"
                        maxlength="100"
                        autocomplete="off"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      label="Address"
                      label-for="address"
                      label-class="font-weight-bolder"
                      class="mb-2"
                    >
                      <b-textarea
                        id="address"
                        v-model="viewBusinessPartnerDraft.address"
                        rows="3"
                        max-rows="6"
                        maxlength="255"
                        autocomplete="off"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <hr class="border border-2 w-100">
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      label="Approvals"
                      label-cols-md="12"
                      label-class="font-weight-bolder"
                      class="mb-2"
                    />
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      label="Status"
                      label-for="status"
                      label-cols-md="4"
                      label-class="font-weight-bolder"
                      class="mb-2"
                    >
                      <b-input
                        id="status"
                        v-model="viewBusinessPartnerDraft.status"
                        type="text"
                        maxlength="100"
                        autocomplete="off"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      label="Time Lapsed"
                      label-for="time_lapsed"
                      label-cols-md="4"
                      label-class="font-weight-bolder"
                      class="mb-2"
                    >
                      <b-input
                        id="time_lapsed"
                        v-model="viewBusinessPartnerDraft.time_lapsed"
                        type="text"
                        maxlength="100"
                        autocomplete="off"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      label="Processed At"
                      label-for="updated_at"
                      label-cols-md="4"
                      label-class="font-weight-bolder"
                      class="mb-2"
                    >
                      <b-input
                        id="updated_at"
                        v-model="viewBusinessPartnerDraft.updated_at"
                        type="text"
                        maxlength="100"
                        autocomplete="off"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      label="Approver Remarks"
                      label-for="approver_remarks"
                      label-class="font-weight-bolder"
                      class="mb-2"
                    >
                      <b-textarea
                        id="approver_remarks"
                        v-model="viewBusinessPartnerDraft.approver_remarks"
                        rows="3"
                        max-rows="6"
                        maxlength="255"
                        autocomplete="off"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab class="p-2">
            <template #title>
              <div class="text-md-nowrap">
                <i class="ri-attachment-line h4" />
                <b class="is-tab-active ml-1">
                  Attachments
                </b>
              </div>
            </template>
            <BPAttachment
              ref="bpAttachments"
              :file-path="viewBusinessPartnerDraft.attachment_path"
              :file-list="viewBusinessPartnerDraft.attachments"
              :show-uploader="false"
            />
          </b-tab>
        </b-tabs>

      </b-container>

      <template #modal-footer="{cancel}">
        <div class="col-12 d-flex justify-content-between px-0 pb-0 mb-0">
          <div />
          <div>
            <b-button
              variant="outline-dark"
              @click="cancel()"
            >
              Close <span class="d-none d-md-inline-block">Window</span>
            </b-button>
          </div>
        </div>
      </template>

    </b-modal>

  </b-container>
</template>

<script>
import QUERY from 'lodash'
import { core } from '@/config/pluginInit'
import { SUserBusinessPartnerDraft, SSharedList } from '@/services'
import BPAttachment from '@/components/custom/BPAttachment'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'
import MOMENT from 'moment'

export default {
  name: 'UserRegistrationBusinessPartners',

  middleware: ['auth', 'user'],

  components: {
    BPAttachment
  },

  mixins: [formatter, misc],

  metaInfo: () => ({
    title: 'Business Partners'
  }),

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      selected: {
        allSelected: false,
        indeterminate: false
      },
      fetching: {
        options: false,
        filterOptions: false
      },
      lists: {
        businessTaxes: [],
        businessTypes: [],
        customerTypes: [],
        partnerTypes: []
      },
      businessPartnerDraft: {
        id: null,
        tin: null,
        business_tax: null,
        partner_type: null,
        business_type: null,
        partner_name: null,
        trade_name: null,
        corporation_name: null,
        owner_first_name: null,
        owner_middle_name: null,
        owner_last_name: null,
        customer_type: null,
        telephone_number: null,
        mobile_number: null,
        email: null,
        contact_person: null,
        address: null,
        status: null,
        attachment_path: null,
        attachments: [],
        active: 1
      },
      viewBusinessPartnerDraft: {
        id: null,
        tin: null,
        business_tax: null,
        partner_type: null,
        business_type: null,
        partner_name: null,
        trade_name: null,
        corporation_name: null,
        owner_first_name: null,
        owner_middle_name: null,
        owner_last_name: null,
        customer_type: null,
        telephone_number: null,
        mobile_number: null,
        email: null,
        contact_person: null,
        address: null,
        status: null,
        attachment_path: null,
        attachments: [],
        approver_remarks: null,
        created_at: null,
        updated_at: null,
        time_lapsed: null,
        active: 1
      },
      businessPartnerDrafts: {
        id: 0,
        selected: []
      },
      tableBusinessPartnerDraft: {
        busy: false,
        filter: {
          search: null,
          status: 'Pending'
        },
        options: {
          statuses: [
            'All',
            'Pending',
            'Approved',
            'Disapproved'
          ]
        },
        filterOn: [],
        stacked: this.isMobile(),
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 1, key: 'selected', class: 'text-center' },
          { mobile: 14, key: 'action', class: 'text-center' },
          { mobile: 2, key: 'created_at', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 3, key: 'tin' },
          { mobile: 4, key: 'partner_name', label: 'Cash Advance Name' },
          { mobile: 5, key: 'trade_name' },
          { mobile: 6, key: 'corporation_name' },
          { mobile: 7, key: 'partner_type' },
          { mobile: 8, key: 'customer_type' },
          { mobile: 9, key: 'telephone_number', label: 'Telephone' },
          { mobile: 10, key: 'mobile_number', label: 'Mobile' },
          { mobile: 11, key: 'email' },
          { mobile: 12, key: 'status', class: 'text-center' },
          { mobile: 13, key: 'updated_at', formatter: this.dateTimeShortFormatter, sortable: true }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  computed: {
    ModalBusinessPartnerTitle () {
      return this.state.editing ? 'Edit Business Partner' : 'Add Business Partner'
    }
  },

  watch: {

    'businessPartnerDraft.partner_type' (partnerType) {
      if (partnerType) {
        if (partnerType === 'Vendor') {
          this.businessPartnerDraft.customer_type = null
          this.businessPartnerDraft.partner_name = null
        }

        if (partnerType === 'Customer') {
          this.businessPartnerDraft.customer_type = 'Cash-Advance'
          this.businessPartnerDraft.business_tax = 'Not-Applicable'
          this.businessPartnerDraft.business_type = null
          this.businessPartnerDraft.trade_name = null
          this.businessPartnerDraft.vendor_type = null
        }
      } else {
        this.businessPartnerDraft.customer_type = null
        this.businessPartnerDraft.business_tax = null
        this.businessPartnerDraft.business_type = null
        this.businessPartnerDraft.partner_name = null
        this.businessPartnerDraft.trade_name = null
        this.businessPartnerDraft.corporation_name = null
        this.businessPartnerDraft.owner_first_name = null
        this.businessPartnerDraft.owner_middle_name = null
        this.businessPartnerDraft.owner_last_name = null
      }
    },

    'businessPartnerDraft.business_type' (businessType) {
      if (businessType !== 'Sole-Proprietorship') {
        this.businessPartnerDraft.owner_first_name = null
        this.businessPartnerDraft.owner_middle_name = null
        this.businessPartnerDraft.owner_last_name = null
      }
    },

    'businessPartnerDrafts.selected' (newValues) {
      if (newValues.length === 0) {
        this.selected.indeterminate = false
        this.selected.allSelected = false
      } else if (newValues.length === this.$refs.tableBusinessPartnerDraft?.localItems.length) {
        this.selected.indeterminate = false
        this.selected.allSelected = true
      } else {
        this.selected.indeterminate = true
        this.selected.allSelected = false
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    vTabBusinessPartner () {
      return this.$refs.formBusinessPartner && Object.keys(this.$refs.formBusinessPartner.errors).find(
        key => this.$refs.formBusinessPartner.errors[key]?.length > 0
      )
    },

    vTabAttachment () {
      if (this.$refs.bpAttachments) {
        return this.$refs.bpAttachments.$refs.formBPAttachments && Object.keys(this.$refs.bpAttachments.$refs.formBPAttachments.errors).find(
          key => this.$refs.bpAttachments.$refs.formBPAttachments.errors[key]?.length > 0
        )
      }

      return true
    },

    onPreventEnterOnKeyboard (event) {
      if (event.keyCode === 13) {
        event.preventDefault()
      }
    },
    // BUSINESS PARTNER
    async tableBusinessPartnerDraftProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableBusinessPartnerDraft.busy = true
      this.businessPartnerDrafts.selected = []

      const filters = this.objectToUrl({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_text: filter.search,
        status: filter.status
      })

      return await SUserBusinessPartnerDraft.get(filters).then(
        ({ data }) => {
          this.tableBusinessPartnerDraft.totalRows = data.total_rows
          return data.items
        }
      ).catch(() => []).finally(() => {
        this.tableBusinessPartnerDraft.busy = false
      })
    },

    // MULTIPLE
    onSelectAllTableRows (selected) {
      this.businessPartnerDrafts.selected = selected ? this.$refs.tableBusinessPartnerDraft.localItems.map(item => item.id) : []
    },

    async getOptions () {
      this.fetching.options = true
      return new Promise(resolve => {
        const payload = this.toArrayListParam([
          'business_taxes',
          'partner_types',
          'customer_types',
          'vendor_types',
          'business_types'
        ])

        SSharedList.getCustomList(payload).then(({ data }) => {
          this.lists.businessTaxes = data.business_taxes
          this.lists.partnerTypes = data.partner_types
          this.lists.customerTypes = data.customer_types
          this.lists.vendorTypes = data.vendor_types
          this.lists.businessTypes = data.business_types
        }).catch(() => {
          this.lists.businessTaxes = []
          this.lists.partnerTypes = []
          this.lists.customerTypes = []
          this.lists.vendorTypes = []
          this.lists.businessTypes = []
        }).finally(resolve)
      }).finally(() => {
        this.fetching.options = false
      })
    },

    onCreateBusinessPartnerDraft () {
      this.state.editing = false
      this.businessPartnerDraft.id = null
      this.businessPartnerDraft.tin = null
      this.businessPartnerDraft.partner_type = null
      this.businessPartnerDraft.business_type = null
      this.businessPartnerDraft.partner_name = null
      this.businessPartnerDraft.trade_name = null
      this.businessPartnerDraft.corporation_name = null
      this.businessPartnerDraft.owner_first_name = null
      this.businessPartnerDraft.owner_middle_name = null
      this.businessPartnerDraft.owner_last_name = null
      this.businessPartnerDraft.customer_type = null
      this.businessPartnerDraft.telephone_number = null
      this.businessPartnerDraft.mobile_number = null
      this.businessPartnerDraft.email = null
      this.businessPartnerDraft.contact_person = null
      this.businessPartnerDraft.attachment_path = null
      this.businessPartnerDraft.attachments = []
      this.businessPartnerDraft.address = null
      this.businessPartnerDraft.business_tax = null
      this.businessPartnerDraft.status = 'Pending'
      this.businessPartnerDraft.active = 1

      this.getOptions()
      this.$bvModal.show('modal-business-partner')
    },

    onEditBusinessPartnerDraft (businessPartnerDraft) {
      this.businessPartnerDraft.id = null
      this.businessPartnerDraft.tin = businessPartnerDraft.tin
      this.businessPartnerDraft.partner_name = businessPartnerDraft.partner_name
      this.businessPartnerDraft.trade_name = businessPartnerDraft.trade_name
      this.businessPartnerDraft.corporation_name = businessPartnerDraft.corporation_name
      this.businessPartnerDraft.owner_first_name = businessPartnerDraft.owner_first_name
      this.businessPartnerDraft.owner_middle_name = businessPartnerDraft.owner_middle_name
      this.businessPartnerDraft.owner_last_name = businessPartnerDraft.owner_last_name
      this.businessPartnerDraft.telephone_number = businessPartnerDraft.telephone_number
      this.businessPartnerDraft.mobile_number = businessPartnerDraft.mobile_number
      this.businessPartnerDraft.email = businessPartnerDraft.email
      this.businessPartnerDraft.contact_person = businessPartnerDraft.contact_person
      this.businessPartnerDraft.address = businessPartnerDraft.address
      this.businessPartnerDraft.status = businessPartnerDraft.status
      this.businessPartnerDraft.attachment_path = null
      this.businessPartnerDraft.attachments = []
      this.businessPartnerDraft.active = businessPartnerDraft.active

      this.getOptions().finally(() => {
        this.businessPartnerDraft.partner_type = businessPartnerDraft.partner_type
        this.businessPartnerDraft.business_tax = businessPartnerDraft.business_tax
        this.businessPartnerDraft.customer_type = businessPartnerDraft.customer_type
        this.businessPartnerDraft.business_type = businessPartnerDraft.business_type
      })

      this.$bvModal.show('modal-business-partner')
    },

    onViewBusinessPartner (businessPartnerDraft) {
      this.viewBusinessPartnerDraft.id = businessPartnerDraft.id
      this.viewBusinessPartnerDraft.tin = businessPartnerDraft.tin
      this.viewBusinessPartnerDraft.partner_name = businessPartnerDraft.partner_name
      this.viewBusinessPartnerDraft.trade_name = businessPartnerDraft.trade_name
      this.viewBusinessPartnerDraft.corporation_name = businessPartnerDraft.corporation_name
      this.viewBusinessPartnerDraft.owner_first_name = businessPartnerDraft.owner_first_name
      this.viewBusinessPartnerDraft.owner_middle_name = businessPartnerDraft.owner_middle_name
      this.viewBusinessPartnerDraft.owner_last_name = businessPartnerDraft.owner_last_name
      this.viewBusinessPartnerDraft.telephone_number = businessPartnerDraft.telephone_number
      this.viewBusinessPartnerDraft.mobile_number = businessPartnerDraft.mobile_number
      this.viewBusinessPartnerDraft.email = businessPartnerDraft.email
      this.viewBusinessPartnerDraft.contact_person = businessPartnerDraft.contact_person
      this.viewBusinessPartnerDraft.address = businessPartnerDraft.address
      this.viewBusinessPartnerDraft.status = businessPartnerDraft.status
      this.viewBusinessPartnerDraft.attachment_path = businessPartnerDraft.attachment_path
      this.viewBusinessPartnerDraft.attachments = businessPartnerDraft.attachments
      this.viewBusinessPartnerDraft.active = businessPartnerDraft.active
      this.viewBusinessPartnerDraft.partner_type = businessPartnerDraft.partner_type
      this.viewBusinessPartnerDraft.business_tax = businessPartnerDraft.business_tax
      this.viewBusinessPartnerDraft.customer_type = businessPartnerDraft.customer_type
      this.viewBusinessPartnerDraft.business_type = businessPartnerDraft.business_type
      this.viewBusinessPartnerDraft.status = businessPartnerDraft.status
      this.viewBusinessPartnerDraft.approver_remarks = businessPartnerDraft.approver_remarks

      if (MOMENT(businessPartnerDraft.created_at).isSame(businessPartnerDraft.updated_at)) {
        this.viewBusinessPartnerDraft.updated_at = null
        this.viewBusinessPartnerDraft.time_lapsed = MOMENT(businessPartnerDraft.created_at).fromNow()
      } else {
        this.viewBusinessPartnerDraft.updated_at = this.dateTimeShortFormatter(businessPartnerDraft.updated_at)
        this.viewBusinessPartnerDraft.time_lapsed = MOMENT(businessPartnerDraft.created_at).from(
          MOMENT(businessPartnerDraft.updated_at),
          true
        )
      }

      this.$bvModal.show('modal-view-business-partner')
    },

    async onValidateBusinessPartnerDraft (event) {
      event.preventDefault()

      await this.$refs.formBusinessPartner.validate().then(
        async allFieldsAreValid => {
          const isAttachmentRequired = this.businessPartnerDraft.partner_type === 'Vendor' && (
            this.businessPartnerDraft.attachments.length <= 0
          )

          if (!allFieldsAreValid || isAttachmentRequired) {
            if (isAttachmentRequired) {
              this.$refs.bpAttachments.setErrors('The attachments field is required')
            }

            return this.swalInvalid()
          }

          this.swalConfirm({
            confirmButtonText: 'Submit Registration',
            html: () => {
              if (this.state.editing) {
                return '<h6>Save your changes on this Business Partner?</h6>'
              }
              return '<h6>Submit this Business Partner Registration?</h6>'
            },
            preConfirm: () => {
              this.state.busy = true
              if (this.state.editing) {
                return this.onPutBusinessPartnerDraft()
              }
              return this.onPostBusinessPartnerDraft()
            }
          })
        }
      )
    },

    onDiscard (businessPartnerDraft) {
      this.swalConfirm({
        html: '<h6>Discard Registration of Business Partner?</h6>',
        confirmButtonText: 'Discard',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-sm btn-danger'
        },
        preConfirm: () => new Promise(resolve => {
          const payload = {
            id: 0,
            selected: [businessPartnerDraft.id]
          }

          SUserBusinessPartnerDraft.delete(payload).then(
            ({ data: { message } }) => {
              this.businessPartnerDrafts.selected = []
              this.$refs.tableBusinessPartnerDraft.refresh()
              this.swalSuccess(message)
              resolve(message)
            }
          ).catch(
            error => resolve(error.message)
          )
        })
      })
    },

    onDiscardSelected () {
      this.swalConfirm({
        html: '<h6>Discard Registration of Business Partners?</h6>',
        confirmButtonText: 'Discard',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-sm btn-danger'
        },
        preConfirm: () => new Promise(resolve => {
          SUserBusinessPartnerDraft.delete(this.businessPartnerDrafts).then(
            ({ data: { message } }) => {
              this.businessPartnerDrafts.selected = []
              this.$refs.tableBusinessPartnerDraft.refresh()
              this.swalSuccess(message)
              resolve(message)
            }
          ).catch(
            error => resolve(error.message)
          )
        })
      })
    },

    async onPostBusinessPartnerDraft () {
      return new Promise(resolve => {
        SUserBusinessPartnerDraft.post(this.businessPartnerDraft).then(
          ({ data: { message } }) => {
            this.$refs.tableBusinessPartnerDraft.refresh()
            this.swalSuccess(message).then(() => {
              this.$bvModal.hide('modal-business-partner')
            })
          }
        ).catch(({ message }) => {
          this.$refs.formBusinessPartner.setErrors(message)
        }).finally(resolve)
      }).finally(() => {
        this.state.busy = false
      })
    },

    async onPutBusinessPartnerDraft () {
      return new Promise(resolve => {
        SUserBusinessPartnerDraft.put(this.businessPartnerDraft).then(
          ({ data }) => {
            const tblBPDraftRow = QUERY.find(this.$refs.tableBusinessPartnerDraft.localItems, {
              id: data.business_partner.id
            })

            if (tblBPDraftRow) {
              tblBPDraftRow.status = data.business_partner.status
              tblBPDraftRow.tin = data.business_partner.tin
              tblBPDraftRow.business_tax = data.business_partner.business_tax
              tblBPDraftRow.partner_type = data.business_partner.partner_type
              tblBPDraftRow.customer_type = data.business_partner.customer_type
              tblBPDraftRow.business_type = data.business_partner.business_type
              tblBPDraftRow.partner_name = data.business_partner.partner_name
              tblBPDraftRow.trade_name = data.business_partner.trade_name
              tblBPDraftRow.corporation_name = data.business_partner.corporation_name
              tblBPDraftRow.owner_first_name = data.business_partner.owner_first_name
              tblBPDraftRow.owner_middle_name = data.business_partner.owner_middle_name
              tblBPDraftRow.owner_last_name = data.business_partner.owner_last_name
              tblBPDraftRow.telephone_number = data.business_partner.telephone_number
              tblBPDraftRow.mobile_number = data.business_partner.mobile_number
              tblBPDraftRow.email = data.business_partner.email
              tblBPDraftRow.contact_person = data.business_partner.contact_person
              tblBPDraftRow.address = data.business_partner.address
              tblBPDraftRow.active = data.business_partner.active
              tblBPDraftRow.attachment_path = data.business_partner.attachment_path
              tblBPDraftRow.attachments = data.business_partner.attachments
              tblBPDraftRow.status = data.business_partner.status
              tblBPDraftRow.updated_at = data.business_partner.updated_at
            }
            this.swalSuccess(data.message).then(() => {
              this.$bvModal.hide('modal-business-partner')
            })
          }
        ).catch(({ message }) => {
          this.$refs.formBusinessPartner.setErrors(message)
        }).finally(resolve)
      }).finally(() => {
        this.state.busy = false
      })
    },

    // UPLOAD UDPATES
    onUploadedAttachments (attachment) {
      this.businessPartnerDraft.attachment_path = attachment.attachment_path
      this.businessPartnerDraft.attachments = attachment.attachments
    }
  }
}
</script>
