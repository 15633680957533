function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/ta/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const TARoutes = [
  {
    path: '/ta/dashboard',
    component: view('Dashboard'),
    name: 'ta.dashboard',
    authorizedRole: 'ta'
  },
  {
    path: '/ta/maintenances/tax-codes',
    component: view('maintenances/TaxCodes'),
    name: 'ta.maintenances.tax-codes',
    authorizedRole: 'ta',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Maintenances'
        },
        {
          text: 'Tax Codes',
          active: true
        }
      ]
    }
  },
  {
    path: '/ta/maintenances/document-types',
    component: view('maintenances/DocumentTypes'),
    name: 'ta.maintenances.document-types',
    authorizedRole: 'ta',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Maintenances'
        },
        {
          text: 'Document Types',
          active: true
        }
      ]
    }
  },
  {
    path: '/ta/maintenances/payment-natures',
    component: view('maintenances/PaymentNatures'),
    name: 'ta.maintenances.payment-natures',
    authorizedRole: 'ta',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Maintenances'
        },
        {
          text: 'Payment Natures',
          active: true
        }
      ]
    }
  },
  {
    path: '/ta/maintenances/business-partners',
    component: view('maintenances/business-partners/Base'),
    name: 'ta.maintenances.business-partners',
    authorizedRole: 'ta',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Maintenances'
        },
        {
          text: 'Business Partners',
          active: true
        }
      ]
    }
  },
  {
    path: '/ta/reports/qap',
    component: view('reports/QAP'),
    name: 'ta.reports.qap',
    authorizedRole: 'ta',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Reports'
        },
        {
          text: 'Quarterly Alphalist of Payees',
          active: true
        }
      ]
    }
  },
  {
    path: '/ta/reports/vat-details',
    component: view('reports/VatDetails'),
    name: 'ta.reports.vat-details',
    authorizedRole: 'ta',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Reports'
        },
        {
          text: 'Vat Details',
          active: true
        }
      ]
    }
  }
]

export default TARoutes
