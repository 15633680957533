import ApiService from '@/services/core/api.service'

const DraftService = {
  async get (query = '') {
    return ApiService.get(`user/payment-drafts?${query}`)
  },

  async post (payload) {
    return ApiService.post('user/payment-drafts', payload)
  },

  async put (payload) {
    return ApiService.put(`user/payment-drafts/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`user/payment-drafts/${payload.id}`, {
      data: payload
    })
  }
}

export default DraftService
