<template>
  <b-container
    fluid
  >
    <div class="d-flex justify-content-end mb-0 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-tabs
      v-model="state.currentTabIndex"
      active-nav-item-class="font-weight-bold text-uppercase text-success"
    >
      <b-tab>
        <template #title>
          <i :class="iconState('ri-funds', state.currentTabIndex === 0)" /> Replenishments
        </template>
        <Replenishments
          ref="prPending"
          :busy="state.busy"
          :current-tab-index="state.currentTabIndex"
        />
      </b-tab>
    </b-tabs>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Replenishments from '@/views/da/payments/reports/cash/Replenishments'

import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'

export default {

  name: 'DAReportsCashBase',

  middleware: ['auth', 'da'],

  metaInfo: () => ({
    title: 'Payment Request'
  }),

  components: {
    Replenishments
  },

  mixins: [formatter, misc],

  data () {
    return {
      state: {
        busy: false,
        currentTabIndex: this.getCurrentTabIndexByURL()
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    getCurrentTabIndexByURL () {
      switch (this.$route.params.da) {
        case 'histories' : {
          return 1
        }
        default: {
          return 0
        }
      }
    }

  }

}
</script>
