<template>
  <b-container
    v-if="currentTabIndex === 1"
    class="mt-2"
    fluid
  >
    <b-row>

      <b-col
        cols="12"
        md="8"
        class="px-1"
        order="2"
        order-md="1"
      >
        <b-form-group
          label="Search"
          label-for="filter_business_partner_search"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filter_business_partner_search"
            v-model="tableBusinessPartner.filter.search"
            type="text"
            debounce="1000"
            autocomplete="off"
            placeholder="search here"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="6"
        md="2"
        class="px-1"
        order="1"
        order-md="2"
      >
        <b-form-group
          label="Partner Type"
          label-for="filter_partner_type"
          label-class="font-weight-bolder"
        >
          <b-select
            id="filter_partner_type"
            v-model="tableBusinessPartner.filter.partner_type"
            debounce="1000"
            :options="filterPartnerTypes"
            :disabled="state.busy || fetching.filterOptions"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="6"
        md="2"
        class="px-1"
        order="1"
        order-md="2"
      >
        <b-form-group
          label="Tax Status"
          label-for="filter_tax_status"
          label-class="font-weight-bolder"
        >
          <b-select
            id="filter_tax_status"
            v-model="tableBusinessPartner.filter.tax_status"
            debounce="1000"
            :options="filterTaxStatuses"
            :disabled="state.busy || fetching.filterOptions"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        order="3"
        class="px-1 mb-2"
      >
        <b-button
          size="sm"
          type="button"
          variant="success"
          @click="onCreateBusinessPartner"
        >
          Add Business Partner
        </b-button>
      </b-col>

    </b-row>
    <b-row class="mt-1">
      <b-col
        cols="12"
        class="px-1"
      >
        <b-table
          ref="tableBusinessPartner"
          hover
          small
          striped
          bordered
          responsive
          show-empty
          :stacked="isMobile()"
          :per-page="tableBusinessPartner.perPage"
          :current-page="tableBusinessPartner.currentPage"
          :items="tableBusinessPartnerProvider"
          :fields="tableBusinessPartner.fields"
          :sort-by.sync="tableBusinessPartner.sortBy"
          :sort-desc.sync="tableBusinessPartner.sortDesc"
          :sort-direction="tableBusinessPartner.sortDirection"
          :filter="tableBusinessPartner.filter"
          :filter-included-fields="tableBusinessPartner.filterOn"
          :busy="tableBusinessPartner.busy"
        >
          <template #cell(index)="row">
            {{ tableBusinessPartner.currentPage * tableBusinessPartner.perPage - tableBusinessPartner.perPage + (row.index + 1) }}
          </template>

          <template #cell(active)="row">
            {{ Number(row.value) === 1 ? 'Yes' : 'No' }}
          </template>

          <template #cell()="row">
            <div class="text-md-nowrap">
              {{ row.value }}
            </div>
          </template>

          <template #cell(actions)="row">
            <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-md-start justify-content-center">
              <b-button
                size="sm"
                variant="success"
                class="mr-0 mr-md-1 mb-1 mb-md-0"
                @click="onEditBusinessPartner(row.item)"
              >
                Edit
              </b-button>

              <b-button
                size="sm"
                @click="onShowBankAccounts(row.item)"
              >
                Bank Accounts
              </b-button>
            </div>
          </template>

          <template #table-busy>
            <div class="text-center py-5">
              <b-icon
                icon="stopwatch"
                font-scale="5"
                animation="cylon"
              />
              <p class="h3 py-2">
                <strong>Loading . . .</strong>
              </p>
            </div>
          </template>

        </b-table>
      </b-col>
      <b-col
        cols="12"
        md="6"
        class="mt-1 mb-2 px-1"
      >
        <b-select
          v-model="tableBusinessPartner.perPage"
          :options="tableBusinessPartner.pageOptions"
          class="w-100 w-md-25"
        />
      </b-col>
      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
      >
        <b-pagination
          v-model="tableBusinessPartner.currentPage"
          pills
          last-number
          first-number
          prev-text="Prev"
          next-text="Next"
          aria-controls="table"
          :total-rows="tableBusinessPartner.totalRows"
          :per-page="tableBusinessPartner.perPage"
        />
      </b-col>
    </b-row>

    <b-modal
      id="modal-business-partner"
      size="xl"
      scrollable
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      hide-header-close
      modal-class="fullscreen"
      :title="ModalBusinessPartnerTitle"
    >
      <b-container
        class="h-100 py-1"
        style="overflow-y: scroll"
        fluid
      >
        <ValidationObserver
          ref="formBusinessPartner"
        >
          <form
            role="form"
            novalidate
            @submit.prevent
          >
            <b-row>
              <b-col
                cols="12"
                md="4"
              >
                <b-row>

                  <b-col
                    cols="12"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="partner code"
                      vid="partner_code"
                      rules="max:30"
                    >
                      <b-form-group
                        label="Partner Code"
                        label-for="partner_code"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="partner_code"
                          v-model="businessPartner.partner_code"
                          type="text"
                          maxlength="30"
                          autocomplete="off"
                          placeholder="enter partner code here"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy"
                        />
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="partner name"
                      vid="partner_name"
                      rules="required|max:255"
                    >
                      <b-form-group
                        label="Partner Name"
                        label-for="partner_name"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-textarea
                          id="partner_name"
                          v-model="businessPartner.partner_name"
                          rows="3"
                          max-rows="6"
                          maxlength="255"
                          autocomplete="off"
                          placeholder="enter partner name here"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy"
                          @keydown="onPreventEnterOnKeyboard"
                        />
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="tin"
                      vid="tin"
                      rules="required|min:18|max:18"
                    >
                      <b-form-group
                        label="TIN"
                        label-for="partner_tin"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="partner_tin"
                          v-model="businessPartner.tin"
                          v-mask="'###-###-###-###NNN'"
                          type="text"
                          maxlength="18"
                          autocomplete="off"
                          placeholder="enter tin here"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy"
                        />
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="partner type"
                      vid="partner_type"
                      rules="required"
                    >
                      <b-form-group
                        label="Partner Type"
                        label-for="partner_type"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-form-select
                          id="partner_type"
                          v-model="businessPartner.partner_type"
                          :options="lists.partnerTypes"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy || fetching.options"
                        >
                          <template #first>
                            <b-form-select-option
                              :value="null"
                              disabled
                            >
                              -- select partner type here --
                            </b-form-select-option>
                          </template>
                        </b-form-select>
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    v-if="businessPartner.partner_type == 'Customer'"
                    cols="12"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="customer type"
                      vid="customer_type"
                      rules="required_if:partner_type,Customer"
                    >
                      <b-form-group
                        label="Customer Type"
                        label-for="customer_type"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-form-select
                          id="customer_type"
                          v-model="businessPartner.customer_type"
                          :options="lists.customerTypes"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy || fetching.options"
                        >
                          <template #first>
                            <b-form-select-option
                              :value="null"
                              disabled
                            >
                              -- select customer type here --
                            </b-form-select-option>
                          </template>
                        </b-form-select>
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    v-else
                    cols="12"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="vendor type"
                      vid="vendor_type"
                      rules="required_if:partner_type,Vendor"
                    >
                      <b-form-group
                        label="Vendor Type"
                        label-for="vendor_type"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-form-select
                          id="vendor_type"
                          v-model="businessPartner.vendor_type"
                          :options="lists.vendorTypes"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy || fetching.options"
                        >
                          <template #first>
                            <b-form-select-option
                              :value="null"
                              disabled
                            >
                              -- select vendor type here --
                            </b-form-select-option>
                          </template>
                        </b-form-select>
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <b-row>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="business type"
                          vid="business_type"
                          rules="required_if:partner_type,Vendor"
                        >
                          <b-form-group
                            label="Business Type"
                            label-for="business_type"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-form-select
                              id="business_type"
                              v-model="businessPartner.business_type"
                              :options="lists.businessTypes"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy || fetching.options || businessPartner.partner_type !== 'Vendor'"
                            >
                              <template #first>
                                <b-form-select-option
                                  :value="null"
                                  disabled
                                >
                                  -- select business type here --
                                </b-form-select-option>
                              </template>
                            </b-form-select>
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="trade name"
                          vid="trade_name"
                          rules="required_if:partner_type,Vendor|max:255"
                        >
                          <b-form-group
                            label="Trade Name"
                            label-for="trade_name"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-textarea
                              id="trade_name"
                              v-model="businessPartner.trade_name"
                              rows="3"
                              max-rows="6"
                              maxlength="255"
                              autocomplete="off"
                              placeholder="enter trade name here"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy || businessPartner.partner_type !== 'Vendor'"
                              @keydown="onPreventEnterOnKeyboard"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="corporation name"
                          vid="corporation_name"
                          rules="required_if:business_type,Corporation,Cooperative,Government|max:255"
                        >
                          <b-form-group
                            :label="(`${businessPartner.business_type || 'Business Type'} Name`)"
                            label-for="corporation_name"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-textarea
                              id="corporation_name"
                              v-model="businessPartner.corporation_name"
                              rows="3"
                              max-rows="6"
                              maxlength="255"
                              autocomplete="off"
                              :placeholder="(`enter ${businessPartner.business_type?.toLowerCase() || 'business type'} name here`)"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy || businessPartner.partner_type !== 'Vendor' || businessPartner.business_type === null"
                              @keydown="onPreventEnterOnKeyboard"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                    </b-row>
                  </b-col>

                </b-row>
              </b-col>

              <b-col
                cols="12"
                md="4"
              >
                <b-row>

                  <b-col
                    cols="12"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          label="Owner / Authorized Representative"
                          label-cols-md="12"
                          label-class="font-weight-bolder"
                          class="mb-2"
                        />
                      </b-col>
                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="first name"
                          vid="owner_first_name"
                          rules="required_if:business_type,Sole-Proprietorship|max:50"
                        >
                          <b-form-group
                            label="First Name"
                            label-for="owner_first_name"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="owner_first_name"
                              v-model="businessPartner.owner_first_name"
                              type="text"
                              maxlength="50"
                              autocomplete="off"
                              placeholder="enter first name here"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy || businessPartner.partner_type !== 'Vendor' || businessPartner.business_type !== 'Sole-Proprietorship'"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="contact person"
                          vid="owner_middle_name"
                          rules="max:100"
                        >
                          <b-form-group
                            label="Middle Name"
                            label-for="owner_middle_name"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="owner_middle_name"
                              v-model="businessPartner.owner_middle_name"
                              type="text"
                              maxlength="100"
                              autocomplete="off"
                              placeholder="enter middle name here"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy || businessPartner.partner_type !== 'Vendor' || businessPartner.business_type !== 'Sole-Proprietorship'"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="surname"
                          vid="owner_last_name"
                          rules="required_if:business_type,Sole-Proprietorship|max:100"
                        >
                          <b-form-group
                            label="Surname"
                            label-for="owner_last_name"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="owner_last_name"
                              v-model="businessPartner.owner_last_name"
                              type="text"
                              maxlength="100"
                              autocomplete="off"
                              placeholder="enter surname here"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy || businessPartner.partner_type !== 'Vendor' || businessPartner.business_type !== 'Sole-Proprietorship'"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                    </b-row>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="telephone number"
                      vid="telephone_number"
                      rules="max:30"
                    >
                      <b-form-group
                        label="Tel. Number"
                        label-for="telephone_number"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="telephone_number"
                          v-model="businessPartner.telephone_number"
                          type="text"
                          maxlength="30"
                          autocomplete="off"
                          placeholder="enter telephone number here"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy"
                        />
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="mobile number"
                      vid="mobile_number"
                      rules="min:11|max:11"
                    >
                      <b-form-group
                        label="Mobile Number"
                        label-for="mobile_number"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="mobile_number"
                          v-model="businessPartner.mobile_number"
                          type="text"
                          maxlength="11"
                          autocomplete="off"
                          placeholder="enter mobile number here"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy"
                        />
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="email"
                      vid="email"
                      rules="email|max:50"
                    >
                      <b-form-group
                        label="Email"
                        label-for="email"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="email"
                          v-model="businessPartner.email"
                          type="text"
                          maxlength="50"
                          autocomplete="off"
                          placeholder="enter email here"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy"
                        />
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="contact person"
                      vid="contact_person"
                      rules="max:100"
                    >
                      <b-form-group
                        label="Contact Person"
                        label-for="contact_person"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="contact_person"
                          v-model="businessPartner.contact_person"
                          type="text"
                          maxlength="100"
                          autocomplete="off"
                          placeholder="enter contact person here"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy"
                        />
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="address"
                      vid="address"
                      rules="max:255"
                    >
                      <b-form-group
                        label="Address"
                        label-for="address"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-textarea
                          id="address"
                          v-model="businessPartner.address"
                          rows="3"
                          max-rows="6"
                          maxlength="255"
                          autocomplete="off"
                          placeholder="enter address here"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy"
                        />
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                </b-row>
              </b-col>

              <b-col
                cols="12"
                md="4"
              >
                <b-row>

                  <b-col
                    cols="12"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="tax status"
                      vid="tax_status"
                      rules="required"
                    >
                      <b-form-group
                        label="Tax Status"
                        label-for="tax_status"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-form-select
                          id="tax_status"
                          v-model="businessPartner.tax_status"
                          :options="lists.taxStatuses"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy || fetching.options"
                        >
                          <template #first>
                            <b-form-select-option
                              :value="null"
                              disabled
                            >
                              -- select tax status here --
                            </b-form-select-option>
                          </template>
                        </b-form-select>
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="business tax"
                      vid="business_tax"
                      rules="required"
                    >
                      <b-form-group
                        label="Business Tax"
                        label-for="business_tax"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-form-select
                          id="business_tax"
                          v-model="businessPartner.business_tax"
                          :options="lists.businessTaxes"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy || fetching.options"
                        >
                          <template #first>
                            <b-form-select-option
                              :value="null"
                              disabled
                            >
                              -- select business tax here --
                            </b-form-select-option>
                          </template>
                        </b-form-select>
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="tax code"
                      vid="tax_code"
                      rules=""
                    >
                      <b-form-group
                        label="Tax Code"
                        label-for="tax_code"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-form-select
                          id="tax_code"
                          v-model="businessPartner.tax_code"
                          :options="taxCodes"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy || fetching.options"
                        >
                          <template #first>
                            <b-form-select-option
                              :value="null"
                              disabled
                            >
                              -- select tax code here --
                            </b-form-select-option>
                          </template>
                        </b-form-select>
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="document type"
                      vid="document_type"
                      rules=""
                    >
                      <b-form-group
                        label="Document Type"
                        label-for="document_type"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-form-select
                          id="document_type"
                          v-model="businessPartner.document_type"
                          :options="documentTypes"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy || fetching.options"
                        >
                          <template #first>
                            <b-form-select-option
                              :value="null"
                              disabled
                            >
                              -- select document type here --
                            </b-form-select-option>
                          </template>
                        </b-form-select>
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="transaction type"
                      vid="transaction_type"
                      rules="required"
                    >
                      <b-form-group
                        label="Transaction Type"
                        label-for="transaction_type"
                        label-cols-md="4"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-form-select
                          id="transaction_type"
                          v-model="businessPartner.transaction_type"
                          :options="lists.transactionTypes"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy || fetching.options"
                        >
                          <template #first>
                            <b-form-select-option
                              :value="null"
                              disabled
                            >
                              -- select transaction type here --
                            </b-form-select-option>
                          </template>
                        </b-form-select>
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="payment nature"
                      vid="payment_nature"
                    >
                      <b-form-group
                        label="Payment Nature"
                        label-for="payment_nature"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <v-select
                          v-model="selected.paymentNature"
                          input-id="payment_nature"
                          type="text"
                          append-to-body
                          label="nature_name"
                          placeholder="search payment nature here"
                          :options="lists.paymentNatures"
                          :calculate-position="calculateDropPosition"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                          :disabled="state.busy || fetching.options"
                          :loading="fetching.options"
                        >
                          <template #no-options="">
                            no available payment nature
                          </template>
                        </v-select>
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    v-if="state.editing"
                    cols="12"
                  >
                    <b-form-group>
                      <b-form-checkbox
                        id="is_active"
                        v-model="businessPartner.active"
                        switch
                        value="1"
                        name="is_active"
                        unchecked-value="0"
                        :disabled="state.busy"
                      >
                        Is Active?
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

          </form>
        </ValidationObserver>

      </b-container>
      <template #modal-footer="{cancel}">
        <div class="col-12 d-flex justify-content-between px-0 pb-0 mb-0">
          <div
            v-if="state.editing"
          >
            <b-button
              variant="secondary"
              :disabled="state.busy"
              @click="$event => onShowBankAccounts(businessPartner)"
            >
              Bank Accounts
            </b-button>
          </div>
          <div />
          <div>
            <b-button
              class="mr-1"
              variant="success"
              :disabled="state.busy"
              @click="onValidateBusinessPartner"
            >
              {{ state.editing ? 'Update Record' : 'Save Record' }}
            </b-button>
            <b-button
              variant="outline-dark"
              :disabled="state.busy"
              @click="cancel()"
            >
              Close <span class="d-none d-md-inline-block">Window</span>
            </b-button>
          </div>
        </div>
      </template>

    </b-modal>

    <b-modal
      id="modal-bank-accounts"
      title="Bank Accounts"
      size="xl"
      scrollable
      no-close-on-esc
      no-enforce-focus
      hide-header-close
      no-close-on-backdrop
      modal-class="fullscreen"
      aria-hidden="false"
    >
      <b-container
        class="scrollable-150px px-4"
        fluid
      >
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="px-1"
            order="2"
            order-md="1"
          >
            <b-form-group
              label="Search"
              label-for="filter_bank_account_searc"
              label-class="font-weight-bolder"
            >
              <b-input
                id="filter_bank_account_searc"
                v-model="tableBankAccount.filter.search"
                type="text"
                placeholder="search here"
                debounce="1000"
                autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="px-1"
            order="1"
            order-md="2"
          >
            <b-form-group
              label="Partner Code"
              label-for="filter_partner_code"
              label-class="font-weight-bolder"
              class="mb-2"
            >
              <b-input
                id="filter_partner_code"
                v-model="businessPartner.partner_code"
                type="text"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="px-1"
            order="1"
            order-md="2"
          >
            <b-form-group
              label="Partner Name"
              label-for="filter_partner_name"
              label-class="font-weight-bolder"
              class="mb-2"
            >
              <b-input
                id="filter_partner_code"
                v-model="businessPartner.partner_name"
                type="text"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="px-1"
            order="1"
            order-md="2"
          >
            <b-form-group
              label="TIN"
              label-for="filter_partner_code"
              label-class="font-weight-bolder"
              class="mb-2"
            >
              <b-input
                id="filter_partner_code"
                v-model="businessPartner.tin"
                type="text"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="px-1 mb-2"
            order="3"
          >
            <b-button
              size="sm"
              variant="success"
              @click="onCreateBankAccount"
            >
              Add Bank Account
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col
            cols="12"
            class="px-1"
          >

            <b-table
              ref="tableBankAccount"
              hover
              small
              striped
              bordered
              responsive
              show-empty
              :stacked="isMobile()"
              :per-page="tableBankAccount.perPage"
              :current-page="tableBankAccount.currentPage"
              :fields="tableBankAccount.fields"
              :items="tableBankAccountProvider"
              :sort-by.sync="tableBankAccount.sortBy"
              :sort-desc.sync="tableBankAccount.sortDesc"
              :sort-direction="tableBankAccount.sortDirection"
              :filter="tableBankAccount.filter"
              :filter-included-fields="tableBankAccount.filterOn"
              :busy="tableBankAccount.busy"
            >
              <template #cell(index)="row">
                {{ tableBankAccount.currentPage * tableBankAccount.perPage - tableBankAccount.perPage + (row.index + 1) }}
              </template>

              <template #cell(active)="row">
                {{ Number(row.value) === 1 ? 'Yes' : 'No' }}
              </template>

              <template #cell()="row">
                <div class="text-md-nowrap">
                  {{ row.value }}
                </div>
              </template>

              <template #cell(action)="row">
                <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
                  <b-button
                    size="sm"
                    variant="success"
                    @click="onEditBankAccount(row.item)"
                  >
                    Edit
                  </b-button>
                </div>
              </template>

              <template #table-busy>
                <div class="text-center py-5">
                  <b-icon
                    icon="stopwatch"
                    font-scale="5"
                    animation="cylon"
                  />
                  <p class="h3 py-2">
                    <strong>Loading . . .</strong>
                  </p>
                </div>
              </template>

            </b-table>

          </b-col>
          <b-col
            cols="12"
            md="6"
            class="mt-1 mb-2 px-1"
          >
            <b-select
              v-model="tableBankAccount.perPage"
              :options="tableBankAccount.pageOptions"
              class="w-100 w-md-25"
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
          >
            <b-pagination
              v-model="tableBankAccount.currentPage"
              pills
              last-number
              first-number
              prev-text="Prev"
              next-text="Next"
              aria-controls="table"
              :total-rows="tableBankAccount.totalRows"
              :per-page="tableBankAccount.perPage"
            />
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-bank-account"
      scrollable
      no-close-on-esc
      no-enforce-focus
      hide-header-close
      no-close-on-backdrop
      :title="ModalBankAccountTitle"
    >
      <ValidationObserver
        ref="formBankAccount"
      >
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <b-row>
            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="bank"
                vid="bank"
                rules="required"
              >
                <b-form-group
                  label="Bank"
                  label-for="bank_name"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <v-select
                    v-model="selected.bank"
                    input-id="bank_name"
                    type="text"
                    label="bank_name"
                    placeholder="search bank here"
                    :options="lists.banks"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                    :disabled="state.busy || fetching.banks"
                    :loading="fetching.banks"
                  >
                    <template #no-options="">
                      no available bank
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="account number"
                vid="bank_account_number"
                rules="required|max:100"
              >
                <b-form-group
                  label="Account Number"
                  label-for="bank_account_number"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-input
                    id="bank_account_number"
                    v-model="bankAccount.bank_account_number"
                    type="text"
                    maxlength="100"
                    autocomplete="off"
                    placeholder="enter account number here"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                    @keypress="isNumber($event)"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="account name"
                vid="bank_account_name"
                rules="required|max:100"
              >
                <b-form-group
                  label="Account Name"
                  label-for="bank_account_name"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-input
                    id="bank_account_name"
                    v-model="bankAccount.bank_account_name"
                    type="text"
                    maxlength="100"
                    autocomplete="off"
                    placeholder="enter account name"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              v-if="state.bankEditing"
              cols="12"
            >
              <b-form-group>
                <b-form-checkbox
                  id="is_active"
                  v-model="bankAccount.active"
                  switch
                  value="1"
                  name="is_active"
                  unchecked-value="0"
                  :disabled="state.busy"
                >
                  Is Active?
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{cancel}">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="onBankAccountConfirmAction"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close <span class="d-none d-md-inline-block">Window</span>
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>

<script>
import QUERY from 'lodash'
import { core } from '@/config/pluginInit'
import { STABusinessPartner, STABankAccount, SSharedList } from '@/services'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'

export default {
  name: 'TaMaintenancesBusinessPartners',

  middleware: ['auth', 'ta'],

  mixins: [formatter, misc],

  metaInfo: () => ({
    title: 'Business Partners'
  }),

  props: {
    currentTabIndex: {
      type: Number,
      default: () => {
        return 1
      }
    }
  },

  data () {
    return {
      state: {
        busy: false,
        editing: false,
        bankEditing: false
      },
      fetching: {
        partnerTypes: false,
        taxStatuses: false,
        options: false,
        filterOptions: false,
        banks: false
      },
      lists: {
        banks: [],
        budgetCenters: [],
        businessTaxes: [],
        businessTypes: [
          // 'Corporation',
          // 'Sole-Proprietorship',
          // 'Government',
          // 'Cooperative'
        ],
        customerTypes: [],
        paymentNatures: [],
        documentTypes: [],
        fiscalYears: [],
        partnerTypes: [],
        taxCodes: [],
        taxStatuses: [],
        vendorTypes: [],
        transactionTypes: []
      },
      selected: {
        paymentNature: null,
        bank: null
      },
      businessPartner: {
        id: null,
        partner_code: null,
        partner_name: null,
        tin: null,
        partner_type: null,
        vendor_type: null,
        business_type: null,
        trade_name: null,
        corporation_name: null,
        owner_first_name: null,
        owner_middle_name: null,
        owner_last_name: null,
        customer_type: null,
        telephone_number: null,
        mobile_number: null,
        email: null,
        address: null,
        contact_person: null,
        tax_status: null,
        business_tax: null,
        tax_code: null,
        document_type: null,
        transaction_type: null,
        payment_nature: null,
        bank_account_details: [],
        active: 1
      },
      tableBusinessPartner: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null,
          partner_type: 'All',
          tax_status: 'All'
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 13, key: 'actions', class: 'text-center' },
          { mobile: 1, key: 'created_at', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'partner_code' },
          { mobile: 3, key: 'partner_name' },
          { mobile: 4, key: 'tin' },
          { mobile: 5, key: 'partner_type' },
          { mobile: 6, key: 'vendor_type' },
          { mobile: 7, key: 'customer_type' },
          { mobile: 8, key: 'telephone_number', label: 'Telephone' },
          { mobile: 9, key: 'mobile_number', label: 'Mobile' },
          { mobile: 10, key: 'email' },
          { mobile: 11, key: 'active', class: 'text-center' },
          { mobile: 12, key: 'updated_at', formatter: this.dateTimeShortFormatter, sortable: true }
        ].sort(this.scaleOnMobile)
      },
      bankAccount: {
        id: null,
        business_partner: null,
        bank: null,
        bank_account_number: null,
        bank_account_name: null,
        active: 1
      },
      tableBankAccount: {
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: true,
        sortDirection: 'desc',
        filter: {
          search: null,
          business_partner: null
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 7, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'created_at', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'bank.bank_name', label: 'Bank' },
          { mobile: 3, key: 'bank_account_number' },
          { mobile: 4, key: 'bank_account_name' },
          { mobile: 5, key: 'active', class: 'text-center' },
          { mobile: 6, key: 'updated_at', formatter: this.dateTimeShortFormatter }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  computed: {
    ModalBusinessPartnerTitle () {
      return this.state.editing ? 'Edit Business Partner' : 'Add Business Partner'
    },

    ModalBankAccountTitle () {
      return this.state.bankEditing ? 'Edit Bank Account' : 'Add Bank Account'
    },

    filterPartnerTypes () {
      return [{ text: 'All', value: 'All' }].concat(this.lists.partnerTypes)
    },

    filterTaxStatuses () {
      return [{ text: 'All', value: 'All' }].concat(this.lists.taxStatuses)
    },

    documentTypes () {
      return [{ text: 'NONE', value: null }].concat(this.lists.documentTypes)
    },

    taxCodes () {
      return [{ text: 'NONE', value: null }].concat(this.lists.taxCodes)
    },

    paymentNatures () {
      return [{ text: 'NONE', value: null }].concat(this.lists.paymentNatures)
    }
  },

  watch: {

    'businessPartner.partner_name' (partnerName) {
      if (partnerName?.search(/\n/g)) {
        this.businessPartner.partner_name = partnerName.replace(/\n/g, '')
      }
    },

    'businessPartner.partner_type' (partnerType) {
      if (partnerType) {
        if (partnerType === 'Vendor') {
          this.businessPartner.customer_type = null
        }

        if (partnerType === 'Customer') {
          this.businessPartner.business_type = null
          this.businessPartner.trade_name = null
          this.businessPartner.corporation_name = null
          this.businessPartner.owner_first_name = null
          this.businessPartner.owner_middle_name = null
          this.businessPartner.owner_last_name = null
          this.businessPartner.vendor_type = null
        }
      } else {
        this.businessPartner.business_type = null
        this.businessPartner.customer_type = null
        this.businessPartner.vendor_type = null
        this.businessPartner.trade_name = null
        this.businessPartner.corporation_name = null
        this.businessPartner.owner_first_name = null
        this.businessPartner.owner_middle_name = null
        this.businessPartner.owner_last_name = null
      }
    },

    'selected.paymentNature' (paymentNature) {
      this.businessPartner.payment_nature = paymentNature?.id || null
    },

    'selected.bank' (bank) {
      this.bankAccount.bank = bank?.id || null
    }
  },

  mounted () {
    core.index()
    this.getFilterOptions()
  },

  methods: {

    onPreventEnterOnKeyboard (event) {
      if (event.keyCode === 13) {
        event.preventDefault()
      }
    },

    isNumber (keyboardEvent) {
      const charCode = keyboardEvent.which ? keyboardEvent.which : keyboardEvent.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        keyboardEvent.preventDefault()
      }
      if (charCode === 46) {
        keyboardEvent.preventDefault()
      }
    },

    // BUSINESS PARTNER
    async tableBusinessPartnerProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableBusinessPartner.busy = true
      return await STABusinessPartner.get(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search,
          partner_type: filter.partner_type,
          tax_status: filter.tax_status
        })
      ).then(({ data }) => {
        this.tableBusinessPartner.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableBusinessPartner.busy = false
      })
    },

    async getFilterOptions () {
      this.fetching.filterOptions = true
      return new Promise(resolve => {
        SSharedList.getCustomList(
          this.toArrayListParam([
            'partner_types',
            'tax_statuses'
          ])
        ).then(({ data }) => {
          this.lists.partnerTypes = data.partner_types
          this.lists.taxStatuses = data.tax_statuses
          resolve(data)
        })
      }).finally(() => {
        this.fetching.filterOptions = false
      })
    },

    async getOptions () {
      this.fetching.options = true
      return new Promise(resolve => {
        SSharedList.getCustomList(
          this.toArrayListParam([
            'business_taxes',
            'business_types',
            'customer_types',
            'document_types',
            'payment_natures',
            'tax_codes',
            'vendor_types',
            'transaction_types'
          ])
        ).then(({ data }) => {
          this.lists.businessTypes = data.business_types
          this.lists.vendorTypes = data.vendor_types
          this.lists.customerTypes = data.customer_types
          this.lists.paymentNatures = data.payment_natures
          this.lists.businessTaxes = data.business_taxes
          this.lists.transactionTypes = data.transaction_types
          this.lists.taxCodes = data.tax_codes.map(
            taxCode => ({
              text: `${taxCode.code} - ${taxCode.description}`,
              value: taxCode.id
            })
          )
          this.lists.documentTypes = data.document_types.map(
            documentType => ({
              text: documentType.document_type_name,
              value: documentType.id
            })
          )
          resolve(data)
        })
      }).finally(() => {
        this.fetching.options = false
      })
    },

    onCreateBusinessPartner () {
      this.state.editing = false
      this.businessPartner.id = null
      this.businessPartner.partner_code = null
      this.businessPartner.partner_name = null
      this.businessPartner.tin = null
      this.businessPartner.partner_type = null
      this.businessPartner.vendor_type = null
      this.businessPartner.business_type = null
      this.businessPartner.trade_name = null
      this.businessPartner.corporation_name = null
      this.businessPartner.owner_first_name = null
      this.businessPartner.owner_middle_name = null
      this.businessPartner.owner_last_name = null
      this.businessPartner.customer_type = null
      this.businessPartner.telephone_number = null
      this.businessPartner.mobile_number = null
      this.businessPartner.email = null
      this.businessPartner.address = null
      this.businessPartner.contact_person = null
      this.businessPartner.tax_status = null
      this.businessPartner.business_tax = null
      this.businessPartner.tax_code = null
      this.businessPartner.document_type = null
      this.businessPartner.transaction_type = null
      this.businessPartner.payment_nature = null
      this.businessPartner.active = 1

      this.getOptions()
      this.$bvModal.show('modal-business-partner')
    },

    onEditBusinessPartner (businessPartner) {
      this.state.editing = true
      this.businessPartner.id = businessPartner.id
      this.businessPartner.partner_code = businessPartner.partner_code
      this.businessPartner.partner_name = businessPartner.partner_name
      this.businessPartner.tin = businessPartner.tin
      this.businessPartner.partner_type = businessPartner.partner_type
      this.businessPartner.vendor_type = businessPartner.vendor_type
      this.businessPartner.business_type = businessPartner.business_type
      this.businessPartner.trade_name = businessPartner.trade_name
      this.businessPartner.corporation_name = businessPartner.corporation_name
      this.businessPartner.owner_first_name = businessPartner.owner_first_name
      this.businessPartner.owner_middle_name = businessPartner.owner_middle_name
      this.businessPartner.owner_last_name = businessPartner.owner_last_name
      this.businessPartner.customer_type = businessPartner.customer_type
      this.businessPartner.telephone_number = businessPartner.telephone_number
      this.businessPartner.mobile_number = businessPartner.mobile_number
      this.businessPartner.email = businessPartner.email
      this.businessPartner.address = businessPartner.address
      this.businessPartner.contact_person = businessPartner.contact_person
      this.businessPartner.tax_status = businessPartner.tax_status
      this.businessPartner.business_tax = businessPartner.business_tax
      this.businessPartner.tax_code = businessPartner.tax_code_id
      this.businessPartner.document_type = businessPartner.document_type_id
      this.businessPartner.transaction_type = businessPartner.transaction_type
      this.businessPartner.active = businessPartner.active

      this.getOptions().then(() => {
        this.selected.paymentNature = QUERY.find(this.lists.paymentNatures, { id: businessPartner.payment_nature_id })
      })
      this.$bvModal.show('modal-business-partner')
    },

    async onValidateBusinessPartner (event) {
      event.preventDefault()
      await this.$refs.formBusinessPartner.validate().then(
        async allFieldsAreValid => {
          if (allFieldsAreValid) {
            this.swalConfirm({
              html: () => {
                if (this.state.editing) {
                  return '<h6>Save your changes on this Business Partner?</h6>'
                }
                return '<h6>Create this Business Partner?</h6>'
              },
              preConfirm: () => {
                this.state.busy = true
                if (this.state.editing) {
                  return this.onPutBusinessPartner()
                }
                return this.onPostBusinessPartner()
              }
            })
          } else {
            this.swalInvalid()
          }
        }
      )
    },

    async onPostBusinessPartner () {
      return new Promise(resolve => {
        STABusinessPartner.post(this.businessPartner).then(
          ({ data: { message } }) => {
            this.$refs.tableBusinessPartner.refresh()
            this.swalSuccess(message).then(() => {
              this.$bvModal.hide('modal-business-partner')
            })
            resolve(message)
          }
        ).catch(({ message }) => {
          this.$refs.formBusinessPartner.setErrors(message)
          resolve(message)
        }).finally(() => {
          this.state.busy = false
        })
      })
    },

    async onPutBusinessPartner () {
      return new Promise(resolve => {
        STABusinessPartner.put(this.businessPartner).then(
          ({ data }) => {
            const tableBusinessPartnerRow = QUERY.find(this.$refs.tableBusinessPartner.localItems, { id: data.business_partner.id })
            if (tableBusinessPartnerRow) {
              tableBusinessPartnerRow.partner_code = data.business_partner.partner_code
              tableBusinessPartnerRow.partner_name = data.business_partner.partner_name
              tableBusinessPartnerRow.tin = data.business_partner.tin
              tableBusinessPartnerRow.partner_type = data.business_partner.partner_type
              tableBusinessPartnerRow.vendor_type = data.business_partner.vendor_type
              tableBusinessPartnerRow.customer_type = data.business_partner.customer_type
              tableBusinessPartnerRow.business_type = data.business_partner.business_type
              tableBusinessPartnerRow.trade_name = data.business_partner.trade_name
              tableBusinessPartnerRow.corporation_name = data.business_partner.corporation_name
              tableBusinessPartnerRow.owner_first_name = data.business_partner.owner_first_name
              tableBusinessPartnerRow.owner_middle_name = data.business_partner.owner_middle_name
              tableBusinessPartnerRow.owner_last_name = data.business_partner.owner_last_name
              tableBusinessPartnerRow.telephone_number = data.business_partner.telephone_number
              tableBusinessPartnerRow.mobile_number = data.business_partner.mobile_number
              tableBusinessPartnerRow.email = data.business_partner.email
              tableBusinessPartnerRow.contact_person = data.business_partner.contact_person
              tableBusinessPartnerRow.address = data.business_partner.address
              tableBusinessPartnerRow.tax_status = data.business_partner.tax_status
              tableBusinessPartnerRow.business_tax = data.business_partner.business_tax
              tableBusinessPartnerRow.tax_code_id = data.business_partner.tax_code_id
              tableBusinessPartnerRow.tax_code = data.business_partner.tax_code
              tableBusinessPartnerRow.document_type_id = data.business_partner.document_type_id
              tableBusinessPartnerRow.document_type = data.business_partner.document_type
              tableBusinessPartnerRow.payment_nature_id = data.business_partner.payment_nature_id
              tableBusinessPartnerRow.active = data.business_partner.active
              tableBusinessPartnerRow.updated_at = data.business_partner.updated_at
            }
            this.swalSuccess(data.message).then(() => {
              this.$bvModal.hide('modal-business-partner')
            })
            resolve(data)
          }
        ).catch(({ message }) => {
          this.$refs.formBusinessPartner.setErrors(message)
          resolve(message)
        }).finally(() => {
          this.state.busy = false
        })
      })
    },

    // BANKS
    async tableBankAccountProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableBankAccount.busy = true
      return await STABankAccount.get(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search,
          bank_accountable: filter.business_partner
        })
      ).then(({ data }) => {
        this.tableBankAccount.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableBankAccount.busy = false
      })
    },

    async getBanks () {
      this.state.busy = this.fetching.banks = true
      return new Promise(resolve => {
        SSharedList.getBanks().then(
          ({ data }) => {
            this.lists.banks = data
            resolve(data)
          }
        )
      }).finally(() => {
        this.state.busy = this.fetching.banks = false
      })
    },

    onShowBankAccounts (businessPartner) {
      this.state.bankEditing = false
      this.businessPartner.partner_code = businessPartner.partner_code
      this.businessPartner.partner_name = businessPartner.partner_name
      this.businessPartner.tin = businessPartner.tin
      this.bankAccount.business_partner = this.tableBankAccount.filter.business_partner = businessPartner.id
      this.tableBankAccount.filter.search = null
      this.$bvModal.show('modal-bank-accounts')
    },

    onCreateBankAccount () {
      this.state.bankEditing = false
      this.bankAccount.id = null
      this.bankAccount.bank_account_name = null
      this.bankAccount.bank_account_number = null
      this.bankAccount.active = 1

      this.selected.bank = null

      this.getBanks().then(() => {
        this.$bvModal.show('modal-bank-account')
      })
    },

    onEditBankAccount (bankAccount) {
      this.state.bankEditing = true
      this.bankAccount.id = bankAccount.id
      this.bankAccount.bank_account_name = bankAccount.bank_account_name
      this.bankAccount.bank_account_number = bankAccount.bank_account_number
      this.bankAccount.active = bankAccount.active

      this.getBanks().then(() => {
        this.selected.bank = QUERY.find(this.lists.banks, { id: bankAccount.bank_id })
      })

      this.$bvModal.show('modal-bank-account')
    },

    async onBankAccountConfirmAction (event) {
      event.preventDefault()
      await this.$refs.formBankAccount.validate().then(
        async allFieldsAreValid => {
          if (allFieldsAreValid) {
            this.swalConfirm({
              html: () => {
                if (this.state.bankEditing) {
                  return '<h6>Save your Changes on this Bank Account?</h6>'
                }
                return '<h6>Create this Bank Account?</h6>'
              },
              preConfirm: () => {
                this.state.busy = true
                if (this.state.bankEditing) {
                  return this.onPutBankAccount()
                }
                return this.onPostBankAccount()
              }
            })
          } else {
            this.swalInvalid()
          }
        }
      )
    },

    async onPostBankAccount () {
      return new Promise(resolve => {
        STABankAccount.post(this.bankAccount).then(
          ({ data: { message } }) => {
            this.$refs.tableBankAccount.refresh()
            this.swalSuccess(message).then(() => {
              this.$bvModal.hide('modal-bank-account')
            })
            resolve(message)
          }
        ).catch(({ message }) => {
          this.$refs.formBankAccount.setErrors(message)
          resolve(message)
        }).finally(() => {
          this.state.busy = false
        })
      })
    },

    async onPutBankAccount () {
      return new Promise(resolve => {
        STABankAccount.put(this.bankAccount).then(
          ({ data: { bankAccount, message } }) => {
            const tableBankAccountRow = QUERY.find(this.$refs.tableBankAccount.localItems, { id: bankAccount.id })
            if (tableBankAccountRow) {
              tableBankAccountRow.bank = bankAccount.bank
              tableBankAccountRow.bank_accountable_id = bankAccount.bank_accountable_id
              tableBankAccountRow.bank_accountable_type = bankAccount.bank_accountable_type
              tableBankAccountRow.bank_id = bankAccount.bank_id
              tableBankAccountRow.bank_account_name = bankAccount.bank_account_name
              tableBankAccountRow.bank_account_number = bankAccount.bank_account_number
              tableBankAccountRow.active = bankAccount.active
              tableBankAccountRow.bankAccount = bankAccount.updated_at
            }
            this.swalSuccess(message).then(() => {
              this.$bvModal.hide('modal-bank-account')
            })
            resolve(message)
          }
        ).catch(({ message }) => {
          this.$refs.formBankAccount.setErrors(message)
          resolve(message)
        }).finally(() => {
          this.state.busy = false
        })
      })
    }
  }
}
</script>
