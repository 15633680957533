function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/ram/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const RAMRoutes = [
  {
    path: '/ram/dashboard',
    component: view('Dashboard'),
    name: 'ram.dashboard',
    authorizedRole: 'ram'
  },
  // PENDING APPROVALS
  {
    path: '/ram/payments/approvals/:ram?/:status?',
    component: view('payments/approvals/Base'),
    name: 'ram.payments.approvals',
    authorizedRole: 'ram',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Payments'
        },
        {
          text: 'Approvals',
          active: true
        }
      ]
    }
  }
]

export default RAMRoutes
