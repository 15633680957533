function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/da/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const DAssociateRoutes = [
  {
    path: '/da/dashboard',
    component: view('Dashboard'),
    name: 'da.dashboard',
    authorizedRole: 'da'
  },
  {
    path: '/da/payments/vouchers',
    component: view('payments/Vouchers'),
    name: 'da.payments.vouchers',
    authorizedRole: 'da',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Payment Vouchers',
          active: true
        }
      ]
    }
  },
  // PENDING APPROVALS
  {
    path: '/da/payments/document-printing/:da?',
    component: view('payments/document-printing/Base'),
    name: 'da.payments.document-printing',
    authorizedRole: 'da',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Payment Requests'
        },
        {
          text: 'Document Printing',
          active: true
        }
      ]
    }
  },
  {
    path: '/da/payments/check-signing/:da?',
    component: view('payments/check-signing/Base'),
    name: 'da.payments.check-signing',
    authorizedRole: 'da',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Payment Requests'
        },
        {
          text: 'Check Signing',
          active: true
        }
      ]
    }
  },
  {
    path: '/da/payments/releasing/:da?',
    component: view('payments/releasing/Base'),
    name: 'da.payments.releasing',
    authorizedRole: 'da',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Payment Requests'
        },
        {
          text: 'Releasing',
          active: true
        }
      ]
    }
  },
  {
    path: '/da/payments/reports/cash/:da?',
    component: view('payments/reports/cash/Base'),
    name: 'da.reports.cash',
    authorizedRole: 'da',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Reports'
        },
        {
          text: 'Cash Advances',
          active: true
        }
      ]
    }
  },
  {
    path: '/da/payments/reports/checks/:da?',
    component: view('payments/reports/checks/Base'),
    name: 'da.reports.checks',
    authorizedRole: 'da',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Reports'
        },
        {
          text: 'Checks',
          active: true
        }
      ]
    }
  },
  {
    path: '/da/payments/reports/credit-cards/:da?',
    component: view('payments/reports/credit-cards/Base'),
    name: 'da.reports.credit-cards',
    authorizedRole: 'da',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Reports'
        },
        {
          text: 'Credit Cards',
          active: true
        }
      ]
    }
  }
]

export default DAssociateRoutes
