<template>
  <b-container
    class="mt-2"
    fluid
  >
    <!-- Filters -->
    <b-row>
      <b-col
        cols="12"
        md="9"
        class="px-1"
        order="3"
        order-md="1"
      >
        <b-form-group
          label="Search"
          label-for="filter_history_search"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filter_history_search"
            v-model="tableHistories.filter.search"
            type="text"
            placeholder="search here"
            debounce="1000"
            autocomplete="off"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="3"
        class="px-1"
        order="2"
      >
        <b-form-group
          label="Status"
          label-for="filter_status"
          label-class="font-weight-bolder"
        >
          <b-form-select
            id="filter_status"
            v-model="tableHistories.filter.status"
            debounce="1000"
            :options="lists.statuses"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                -- select status here --
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        class="px-1"
        order="3"
      >
        <b-collapse
          id="advance-search"
          v-model="tableHistories.filter.advance"
          class="mb-1"
        >
          <b-container
            class="px-2"
            fluid
          >
            <b-row>

              <b-col
                cols="6"
                md="3"
                class="px-1"
                order="2"
              >
                <b-form-group
                  label="Requested From"
                  label-for="filter_date_from"
                  label-class="font-weight-bolder"
                >
                  <b-input
                    id="filter_date_from"
                    v-model="tableHistories.filter.date_from"
                    type="date"
                    debounce="1000"
                    :max="tableHistories.filter.date_to"
                  />
                </b-form-group>
              </b-col>

              <b-col
                cols="6"
                md="3"
                class="px-1"
                order="2"
              >
                <b-form-group
                  label="Requested To"
                  label-for="filter_date_to"
                  label-class="font-weight-bolder"
                >
                  <b-input
                    id="filter_date_to"
                    v-model="tableHistories.filter.date_to"
                    type="date"
                    debounce="1000"
                    :min="tableHistories.filter.date_from"
                  />
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="3"
                class="px-1"
                order="2"
              >
                <b-form-group
                  label="Transaction Type"
                  label-for="filter_transaction_type"
                  label-class="font-weight-bolder"
                >
                  <b-form-select
                    id="filter_transaction_type"
                    v-model="tableHistories.filter.transaction_type"
                    debounce="1000"
                    :options="filterTransactionTypes"
                    :disabled="tableHistories.fetching.options"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        -- select transaction type here --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="3"
                class="px-1"
                order="2"
              >
                <b-form-group
                  label="Mode Of Payment"
                  label-for="filter_mop"
                  label-class="font-weight-bolder"
                >
                  <b-form-select
                    id="filter_mop"
                    v-model="tableHistories.filter.mop"
                    debounce="1000"
                    :options="filterModeOfPayments"
                    :disabled="tableHistories.fetching.options"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        -- select mop here --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>

            </b-row>
          </b-container>
        </b-collapse>
        <b-button
          v-b-toggle="['advance-search']"
          size="sm"
          :variant="tableHistories.filter.advance ? 'danger': 'primary'"
          class="mb-2"
        >
          {{ tableHistories.filter.advance ? 'Hide Search' : 'Advance Search' }}
        </b-button>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-row
      v-if="currentTabIndex === 1"
      class="mt-1"
    >
      <b-col
        cols="12"
        class="px-1"
      >
        <b-table
          ref="tableHistories"
          small
          hover
          striped
          bordered
          responsive
          show-empty
          :stacked="isMobile()"
          :per-page="tableHistories.perPage"
          :current-page="tableHistories.currentPage"
          :items="tableHistoriesProvider"
          :fields="tableHistories.fields"
          :sort-by.sync="tableHistories.sortBy"
          :sort-desc.sync="tableHistories.sortDesc"
          :sort-direction="tableHistories.sortDirection"
          :filter="tableHistories.filter"
          :filter-included-fields="tableHistories.filterOn"
          :busy="tableHistories.busy"
        >
          <template #cell(index)="row">
            {{ tableHistories.currentPage * tableHistories.perPage - tableHistories.perPage + (row.index + 1) }}
          </template>

          <template #cell()="row">
            <div class="text-md-nowrap">
              {{ row.value }}
            </div>
          </template>

          <template #cell(approvable.voucher.voucher_number)="row">
            <div class="text-md-nowrap">
              <b>{{ row.value }}</b>
            </div>
          </template>

          <template #cell(approvable.amount)="row">
            <div class="text-md-nowrap text-md-right">
              {{ row.value }}
            </div>
          </template>

          <template #cell(action)="row">
            <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
              <b-button
                size="sm"
                :disabled="busy"
                @click="onView(row.item)"
              >
                View
              </b-button>
            </div>
          </template>

          <template #table-busy>
            <div class="text-center py-5">
              <b-icon
                icon="stopwatch"
                font-scale="5"
                animation="cylon"
              />
              <p class="h3 py-2">
                <strong>Loading . . .</strong>
              </p>
            </div>
          </template>

        </b-table>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="mt-1 mb-2 px-1"
      >
        <b-select
          v-model="tableHistories.perPage"
          :options="tableHistories.pageOptions"
          class="w-100 w-md-25"
        />
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
      >
        <b-pagination
          v-model="tableHistories.currentPage"
          pills
          last-number
          first-number
          prev-text="Prev"
          next-text="Next"
          aria-controls="table"
          :total-rows="tableHistories.totalRows"
          :per-page="tableHistories.perPage"
        />
      </b-col>

    </b-row>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { SUserPaymentApprovalHistory, SSharedList } from '@/services'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'

export default {
  name: 'UserRequestApprovalsHistories',

  mixins: [formatter, misc],

  props: {
    busy: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    currentTabIndex: {
      type: Number,
      default: () => {
        return 1
      }
    }
  },

  data () {
    const status = String(this.$route.params?.status || 'All')
    return {
      lists: {
        statuses: ['All', 'Approved', 'Disapproved']
      },
      tableHistories: {
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        fetching: {
          options: false
        },
        options: {
          modeOfPayments: [],
          transactionTypes: []
        },
        filter: {
          advance: false,
          search: null,
          status: [status.charAt(0).toUpperCase(), status.substring(1, status.length)].join(''),
          date_from: null,
          date_to: null,
          transaction_type: 'All',
          mop: 'All'
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 14, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'approvable.created_at', label: 'Requested At', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'approvable.voucher.voucher_number', label: 'Voucher Number', class: 'text-center', sortable: true },
          { mobile: 3, key: 'approvable.document_number', label: 'Document Number', class: 'text-center' },
          { mobile: 4, key: 'approvable.bp_name', label: 'Business Partner' },
          { mobile: 5, key: 'approvable.customer_name', label: 'Cash Advance' },
          { mobile: 6, key: 'approvable.mop', label: 'Mode of Payment' },
          { mobile: 7, key: 'approvable.bp_transaction_type', label: 'Transaction Type' },
          { mobile: 8, key: 'approvable.amount', label: 'Amount', formatter: this.numberFormatter },
          { mobile: 9, key: 'approvable.requestor', label: 'Requested By', formatter: this.userCurrentRequestor },
          { mobile: 10, key: 'approvable.status', label: 'Request Status' },
          { mobile: 11, key: 'status_at', label: 'Processed At', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 12, key: 'approver_name', label: 'Processed By' },
          { mobile: 13, key: 'status', label: 'Process Status' }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  computed: {
    filterModeOfPayments () {
      return [{ value: 'All', text: 'All' }].concat(this.tableHistories.options.modeOfPayments)
    },

    filterTransactionTypes () {
      return [{ value: 'All', text: 'All' }].concat(this.tableHistories.options.transactionTypes)
    }
  },

  mounted () {
    core.index()
    this.getOptions()
  },

  methods: {

    async tableHistoriesProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      const defaultFilters = {
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_text: filter.search,
        filter_status: filter.status
      }

      const filters = this.objectToUrl(
        filter.advance ? Object.assign(defaultFilters, {
          filter_date_from: filter.date_from,
          filter_date_to: filter.date_to,
          filter_mop: filter.mop,
          filter_transaction_type: filter.transaction_type
        }) : defaultFilters
      )

      this.tableHistories.busy = true

      return await SUserPaymentApprovalHistory.get(filters).then(
        ({ data }) => {
          this.tableHistories.totalRows = data.total_rows
          return data.items
        }
      ).catch(() => {
        this.tableHistories.totalRows = 0
        return []
      }).finally(() => {
        this.tableHistories.busy = false
      })
    },

    // LISTS
    async getOptions () {
      const filters = this.toArrayListParam([
        'mode_of_payments',
        'transaction_types'
      ])

      this.tableHistories.fetching.options = true

      return await SSharedList.getCustomList(filters).then(
        ({ data }) => {
          this.tableHistories.options.modeOfPayments = data.mode_of_payments
          this.tableHistories.options.transactionTypes = data.transaction_types
        }
      ).catch(() => {
        this.tableHistories.options.modeOfPayments = []
        this.tableHistories.options.transactionTypes = []
      }).finally(() => {
        this.tableHistories.fetching.options = false
      })
    },

    onView (paymentRequest) {
      this.$emit('onView', paymentRequest.approvable, paymentRequest, false)
    },

    tableHistoriesRefresh () {
      if (this.currentTabIndex === 1) {
        this.$refs.tableHistories.refresh()
      }
    }
  }
}
</script>
