import { PageSizes, PDFDocument, StandardFonts } from 'pdf-lib'
import formatter from '@/mixins/formatter'
import pdf from '@/mixins/pdf'
import FormatDate from 'moment'

export default {

  mixins: [pdf, formatter],

  data: () => ({
    PDF_BIR_2307: {
      urls: {
        bir: `${window.location.origin}/2307%20Jan%202018%20ENCS%20v3.pdf`,
        signature: `${window.location.origin}/img/signature.png?v=${Math.round(Math.floor(Math.random() * 100))}`
      },
      width: PageSizes.Folio[0],
      height: PageSizes.Folio[1],
      fontSize: 8,
      font: null,
      dates: {
        '1st-Q': {
          from: date => FormatDate(date).format('0101YYYY'),
          to: date => FormatDate(date).format('0331YYYY')
        },
        '2nd-Q': {
          from: date => FormatDate(date).format('0401YYYY'),
          to: date => FormatDate(date).format('0630YYYY')
        },
        '3rd-Q': {
          from: date => FormatDate(date).format('0701YYYY'),
          to: date => FormatDate(date).format('0930YYYY')
        },
        '4th-Q': {
          from: date => FormatDate(date).format('1001YYYY'),
          to: date => FormatDate(date).format('1231YYYY')
        }
      },
      incomePayments: {
        rows: [
          375, 389, 404, 418, 431, 445, 460, 473, 487, 500, 513
        ],
        columns: [
          20, 180, 223, 295, 369, 441, 513
        ]
      },
      moneyPayments: {
        rows: [
          546, 559, 573, 586, 600, 615, 628, 642, 655, 668, 683
        ],
        columns: [
          20, 180, 223, 295, 369, 441, 513
        ]
      }
    }
  }),

  methods: {

    async getBIRForm2307ByURL () {
      return await fetch(this.PDF_BIR_2307.urls.bir).then(file => file.arrayBuffer())
    },

    async getBIRSignatureByURL () {
      return await fetch(this.PDF_BIR_2307.urls.signature).then(file => file.arrayBuffer())
    },

    async previewBIRForm2307 (options = {
      company: {
        name: null,
        address: null,
        zip_code: null,
        tin: null
      },
      payee: {
        name: null,
        address: null,
        foreign_address: null,
        zip_code: null,
        tin: null
      },
      requester: {
        name: null,
        tin: null,
        position: null
      },
      date: {
        from: null,
        to: null
      },
      incomePayments: [],
      moneyPayments: []
    }) {
      // bir link: https://www.bir.gov.ph/images/bir_files/taxpayers_service_programs_and_monitoring_1/2307%20Jan%202018%20ENCS%20v3.pdf

      return this.getBIRSignatureByURL().then(
        async signature => {
          return await this.getBIRForm2307ByURL().then(async pdfBIR => {
            return await PDFDocument.load(pdfBIR).then(
              async BIRPages => {
                this.PDF_BIR_2307.font = await BIRPages.embedFont(StandardFonts.TimesRoman)
                return await this.pdfLib({
                  title: 'BIR FORM NO. 2307',
                  author: process.env.VUE_APP_TITLE,
                  creator: process.env.VUE_APP_TITLE,
                  subject: options.payee.name
                }).then(
                  async pdfDocument => {
                    await pdfDocument.copyPages(BIRPages, [0]).then(
                      async copiedPages => {
                        const page = copiedPages[0]
                        page.setSize(
                          this.PDF_BIR_2307.width,
                          this.PDF_BIR_2307.height
                        )
                        this.BIRForm2307Payee(page, options.payee.name, options.payee.address, options.payee.zip_code, options.payee.foreign_address)
                        this.BIRForm2307TIN(page, options.payee.tin, 150)
                        this.BIRForm2307Company(page, options.company.name, options.company.address, options.company.zip_code)
                        this.BIRForm2307TIN(page, options.company.tin, 265)

                        const totalOfIncomePayments = {
                          firstQuarter: 0,
                          secondQuarter: 0,
                          thirdQuarter: 0,
                          total: 0,
                          taxWithHeld: 0
                        }

                        options.incomePayments.forEach((rowIncomePayment, indexIncomePayment) => {
                          totalOfIncomePayments.taxWithHeld = parseFloat(
                            totalOfIncomePayments.taxWithHeld + rowIncomePayment.tax_withheld
                          ).toFixed(2)

                          totalOfIncomePayments.total = parseFloat(
                            totalOfIncomePayments.total + rowIncomePayment.amount_due
                          ).toFixed(2)

                          this.BIRForm2307FromDate(page, this.PDF_BIR_2307.dates[rowIncomePayment.quarter].from(rowIncomePayment?.date))
                          this.BIRForm2307ToDate(page, this.PDF_BIR_2307.dates[rowIncomePayment.quarter].to(rowIncomePayment?.date))

                          switch (rowIncomePayment.quarter) {
                            case '2nd-Q': {
                              totalOfIncomePayments.secondQuarter = parseFloat(
                                totalOfIncomePayments.secondQuarter + rowIncomePayment.amount_due
                              ).toFixed(2)

                              this.BIRForm2307IncomePayments(
                                page,
                                rowIncomePayment.tax_description,
                                rowIncomePayment.tax_code,
                                '',
                                '',
                                '',
                                this.numberFormatter(rowIncomePayment.amount_due),
                                this.numberFormatter(rowIncomePayment.tax_withheld),
                                this.PDF_BIR_2307.incomePayments.rows[indexIncomePayment]
                              )
                              break
                            }
                            case '3rd-Q': {
                              totalOfIncomePayments.thirdQuarter = parseFloat(
                                totalOfIncomePayments.thirdQuarter + rowIncomePayment.amount_due
                              ).toFixed(2)

                              this.BIRForm2307IncomePayments(
                                page,
                                rowIncomePayment.tax_description,
                                rowIncomePayment.tax_code,
                                '',
                                '',
                                '',
                                this.numberFormatter(rowIncomePayment.amount_due),
                                this.numberFormatter(rowIncomePayment.tax_withheld),
                                this.PDF_BIR_2307.incomePayments.rows[indexIncomePayment]
                              )
                              break
                            }
                            default: {
                              totalOfIncomePayments.firstQuarter = parseFloat(
                                totalOfIncomePayments.firstQuarter + rowIncomePayment.amount_due
                              ).toFixed(2)

                              this.BIRForm2307IncomePayments(
                                page,
                                rowIncomePayment.tax_description,
                                rowIncomePayment.tax_code,
                                '', // this.numberFormatter(rowIncomePayment.amount_due),
                                '',
                                '',
                                this.numberFormatter(rowIncomePayment.amount_due),
                                this.numberFormatter(rowIncomePayment.tax_withheld),
                                this.PDF_BIR_2307.incomePayments.rows[indexIncomePayment]
                              )
                              break
                            }
                          }
                        })

                        this.BIRForm2307IncomePayments(
                          page,
                          '',
                          '',
                          '', // String(totalOfIncomePayments.firstQuarter > 0 ? this.numberFormatter(totalOfIncomePayments.firstQuarter) : ''),
                          '', // String(totalOfIncomePayments.secondQuarter > 0 ? this.numberFormatter(totalOfIncomePayments.secondQuarter) : ''),
                          '', // String(totalOfIncomePayments.thirdQuarter > 0 ? this.numberFormatter(totalOfIncomePayments.thirdQuarter) : ''),
                          String(totalOfIncomePayments.total > 0 ? this.numberFormatter(totalOfIncomePayments.total) : ''),
                          String(totalOfIncomePayments.taxWithHeld > 0 ? this.numberFormatter(totalOfIncomePayments.taxWithHeld) : ''),
                          this.PDF_BIR_2307.incomePayments.rows[10]
                        )

                        const totalOfMoneyPayments = {
                          firstQuarter: 0,
                          secondQuarter: 0,
                          thirdQuarter: 0,
                          total: 0,
                          taxWithHeld: 0
                        }

                        options.moneyPayments.forEach((rowMoneyPayment, indexMoneyPayment) => {
                          this.BIRForm2307FromDate(page, this.PDF_BIR_2307.dates[rowMoneyPayment.quarter].from)
                          this.BIRForm2307ToDate(page, this.PDF_BIR_2307.dates[rowMoneyPayment.quarter].to)

                          switch (rowMoneyPayment.quarter) {
                            case '2nd-Q': {
                              totalOfMoneyPayments.secondQuarter = parseFloat(
                                totalOfMoneyPayments.secondQuarter + rowMoneyPayment.amount_due
                              ).toFixed(2)

                              this.BIRForm2307MoneyPayments(
                                page,
                                rowMoneyPayment.tax_description,
                                rowMoneyPayment.tax_code,
                                '',
                                '', // this.numberFormatter(rowMoneyPayment.amount_due),
                                '',
                                this.numberFormatter(rowMoneyPayment.amount_due),
                                this.numberFormatter(rowMoneyPayment.tax_withheld),
                                this.PDF_BIR_2307.moneyPayments.rows[indexMoneyPayment]
                              )
                              break
                            }
                            case '3rd-Q': {
                              totalOfMoneyPayments.thirdQuarter = parseFloat(
                                totalOfMoneyPayments.thirdQuarter + rowMoneyPayment.amount_due
                              ).toFixed(2)

                              this.BIRForm2307MoneyPayments(
                                page,
                                rowMoneyPayment.tax_description,
                                rowMoneyPayment.tax_code,
                                '',
                                '',
                                '', // this.numberFormatter(rowMoneyPayment.amount_due),
                                this.numberFormatter(rowMoneyPayment.amount_due),
                                this.numberFormatter(rowMoneyPayment.tax_withheld),
                                this.PDF_BIR_2307.moneyPayments.rows[indexMoneyPayment]
                              )
                              break
                            }
                            default: {
                              totalOfMoneyPayments.firstQuarter = parseFloat(
                                totalOfMoneyPayments.firstQuarter + rowMoneyPayment.amount_due
                              ).toFixed(2)

                              this.BIRForm2307MoneyPayments(
                                page,
                                rowMoneyPayment.tax_description,
                                rowMoneyPayment.tax_code,
                                '', // this.numberFormatter(rowMoneyPayment.amount_due),
                                '',
                                '',
                                this.numberFormatter(rowMoneyPayment.amount_due),
                                this.numberFormatter(rowMoneyPayment.tax_withheld),
                                this.PDF_BIR_2307.moneyPayments.rows[indexMoneyPayment]
                              )
                              break
                            }
                          }
                        })

                        this.BIRForm2307IncomePayments(
                          page,
                          '',
                          '',
                          '', // String(totalOfMoneyPayments.firstQuarter > 0 ? this.numberFormatter(totalOfMoneyPayments.firstQuarter) : ''),
                          '', // String(totalOfMoneyPayments.secondQuarter > 0 ? this.numberFormatter(totalOfMoneyPayments.secondQuarter) : ''),
                          '', // String(totalOfMoneyPayments.thirdQuarter > 0 ? this.numberFormatter(totalOfMoneyPayments.thirdQuarter) : ''),
                          String(totalOfMoneyPayments.total > 0 ? this.numberFormatter(totalOfMoneyPayments.total) : ''),
                          String(totalOfMoneyPayments.taxWithHeld > 0 ? this.numberFormatter(totalOfMoneyPayments.taxWithHeld) : ''),
                          this.PDF_BIR_2307.moneyPayments.rows[10]
                        )

                        this.BIRForm2307User(page, options.requester.name, options.requester.tin, options.requester.position)
                        this.BIRForm2307Conforme(page, options.payee.name)

                        const embededImage = await pdfDocument.embedPng(signature)
                        page.drawImage(embededImage, {
                          x: (page.getWidth() / 2) - (120 / 2),
                          y: Number(165),
                          width: 136,
                          height: 125
                        })

                        pdfDocument.addPage(page)
                        pdfDocument.save()
                      }
                    )
                    return pdfDocument
                  }
                )
              }
            )
          })
        }
      )
    },

    async BIRForm2307FromDate (page, date) {
      const options = {
        x: this.PDF_BIR_2307.width - 456,
        y: this.PDF_BIR_2307.height - 119,
        size: this.PDF_BIR_2307.fontSize
      }
      // FROM MONTH
      page.drawText(date[0], options)
      page.drawText(date[1], Object.assign(options, { x: this.PDF_BIR_2307.width - 443 }))
      // FROM DAY
      page.drawText(date[2], Object.assign(options, { x: this.PDF_BIR_2307.width - 430 }))
      page.drawText(date[3], Object.assign(options, { x: this.PDF_BIR_2307.width - 416 }))
      // FROM YEAR
      page.drawText(date[4], Object.assign(options, { x: this.PDF_BIR_2307.width - 404 }))
      page.drawText(date[5], Object.assign(options, { x: this.PDF_BIR_2307.width - 391 }))
      page.drawText(date[6], Object.assign(options, { x: this.PDF_BIR_2307.width - 377 }))
      page.drawText(date[7], Object.assign(options, { x: this.PDF_BIR_2307.width - 364 }))
    },

    async BIRForm2307ToDate (page, date) {
      const options = {
        x: this.PDF_BIR_2307.width - 208,
        y: this.PDF_BIR_2307.height - 119,
        size: this.PDF_BIR_2307.fontSize
      }
      // TO MONTH
      page.drawText(date[0], options)
      page.drawText(date[1], Object.assign(options, { x: this.PDF_BIR_2307.width - 195 }))
      // TO DAY
      page.drawText(date[2], Object.assign(options, { x: this.PDF_BIR_2307.width - 182 }))
      page.drawText(date[3], Object.assign(options, { x: this.PDF_BIR_2307.width - 168 }))
      // TO YEAR
      page.drawText(date[4], Object.assign(options, { x: this.PDF_BIR_2307.width - 156 }))
      page.drawText(date[5], Object.assign(options, { x: this.PDF_BIR_2307.width - 143 }))
      page.drawText(date[6], Object.assign(options, { x: this.PDF_BIR_2307.width - 129 }))
      page.drawText(date[7], Object.assign(options, { x: this.PDF_BIR_2307.width - 116 }))
    },

    async BIRForm2307TIN (page, tin = '000-000-000-0000X', rowPosition = 150) { // 150, 265
      const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
      if (tin) {
        const values = tin.split('-')
        const options = {
          x: this.PDF_BIR_2307.width - 402,
          y: this.PDF_BIR_2307.height - rowPosition,
          size: this.PDF_BIR_2307.fontSize
        }
        page.drawText(values[0][0], options)
        page.drawText(values[0][1], Object.assign(options, { x: this.PDF_BIR_2307.width - 390 }))
        page.drawText(values[0][2], Object.assign(options, { x: this.PDF_BIR_2307.width - 375 }))

        page.drawText(values[1][0], Object.assign(options, { x: this.PDF_BIR_2307.width - 350 }))
        page.drawText(values[1][1], Object.assign(options, { x: this.PDF_BIR_2307.width - 337 }))
        page.drawText(values[1][2], Object.assign(options, { x: this.PDF_BIR_2307.width - 324 }))

        page.drawText(values[2][0], Object.assign(options, { x: this.PDF_BIR_2307.width - 299 }))
        page.drawText(values[2][1], Object.assign(options, { x: this.PDF_BIR_2307.width - 285 }))
        page.drawText(values[2][2], Object.assign(options, { x: this.PDF_BIR_2307.width - 272 }))

        page.drawText(values[3][0], Object.assign(options, { x: this.PDF_BIR_2307.width - 247 }))
        page.drawText(values[3][1], Object.assign(options, { x: this.PDF_BIR_2307.width - 231 }))
        page.drawText(values[3][2], Object.assign(options, { x: this.PDF_BIR_2307.width - 216 }))
        page.drawText(
          numbers.includes(Number(values[3][3])) ? values[3][3] : '0',
          Object.assign(options, { x: this.PDF_BIR_2307.width - 202 })
        )
        page.drawText(
          numbers.includes(Number(values[3][4])) ? values[3][4] : '0',
          Object.assign(options, { x: this.PDF_BIR_2307.width - 187 })
        )
      }
    },

    async BIRForm2307Payee (page, name, registeredAddress, zipCode = null, foreignAddress = null) {
      const options = {
        x: 40,
        y: this.PDF_BIR_2307.height - 176,
        size: this.PDF_BIR_2307.fontSize
      }
      // Payee
      page.drawText(name, options)
      page.drawText(registeredAddress, Object.assign(options, { y: this.PDF_BIR_2307.height - 205 }))

      if (zipCode) {
        Object.assign(options, { y: this.PDF_BIR_2307.height - 205 })
        page.drawText(zipCode[0], Object.assign(options, { x: this.PDF_BIR_2307.width - 67 }))
        page.drawText(zipCode[1], Object.assign(options, { x: this.PDF_BIR_2307.width - 54 }))
        page.drawText(zipCode[2], Object.assign(options, { x: this.PDF_BIR_2307.width - 42 }))
        page.drawText(zipCode[3], Object.assign(options, { x: this.PDF_BIR_2307.width - 28 }))
      }

      if (foreignAddress) {
        page.drawText(foreignAddress, Object.assign(options, { y: this.PDF_BIR_2307.height - 233 }))
      }
    },

    async BIRForm2307Company (page, name, registeredAddress, zipCode = null) {
      const options = {
        x: 40,
        y: this.PDF_BIR_2307.height - 291,
        size: this.PDF_BIR_2307.fontSize
      }
      // Company
      page.drawText(name, options)
      page.drawText(registeredAddress, Object.assign(options, { y: this.PDF_BIR_2307.height - 320 }))

      if (zipCode) {
        Object.assign(options, { y: this.PDF_BIR_2307.height - 320 })
        page.drawText(zipCode[0], Object.assign(options, { x: this.PDF_BIR_2307.width - 67 }))
        page.drawText(zipCode[1], Object.assign(options, { x: this.PDF_BIR_2307.width - 54 }))
        page.drawText(zipCode[2], Object.assign(options, { x: this.PDF_BIR_2307.width - 42 }))
        page.drawText(zipCode[3], Object.assign(options, { x: this.PDF_BIR_2307.width - 28 }))
      }
    },

    async BIRForm2307IncomePayments (page, taxDescription, taxCode, firstQuarter, secondQuarter, thirdQuarter, total, taxWithHeld, rowPosition = 375) {
      const options = {
        x: this.PDF_BIR_2307.incomePayments.columns[0],
        y: this.PDF_BIR_2307.height - rowPosition,
        size: this.PDF_BIR_2307.fontSize
      }
      page.drawText(taxDescription, options)
      page.drawText(taxCode, Object.assign(options, { x: this.PDF_BIR_2307.incomePayments.columns[1] }))
      page.drawText(firstQuarter, Object.assign(options, { x: this.PDF_BIR_2307.incomePayments.columns[2] }))
      page.drawText(secondQuarter, Object.assign(options, { x: this.PDF_BIR_2307.incomePayments.columns[3] }))
      page.drawText(thirdQuarter, Object.assign(options, { x: this.PDF_BIR_2307.incomePayments.columns[4] }))
      page.drawText(total, Object.assign(options, { x: this.PDF_BIR_2307.incomePayments.columns[5] }))
      page.drawText(taxWithHeld, Object.assign(options, { x: this.PDF_BIR_2307.incomePayments.columns[6] }))
    },

    async BIRForm2307MoneyPayments (page, taxDescription, taxCode, firstQuarter, secondQuarter, thirdQuarter, total, taxWithHeld, rowPosition = 546) {
      const options = {
        x: this.PDF_BIR_2307.moneyPayments.columns[0],
        y: this.PDF_BIR_2307.height - rowPosition,
        size: this.PDF_BIR_2307.fontSize
      }
      page.drawText(taxDescription, options)
      page.drawText(taxCode, Object.assign(options, { x: this.PDF_BIR_2307.moneyPayments.columns[1] }))
      page.drawText(firstQuarter, Object.assign(options, { x: this.PDF_BIR_2307.moneyPayments.columns[2] }))
      page.drawText(secondQuarter, Object.assign(options, { x: this.PDF_BIR_2307.moneyPayments.columns[3] }))
      page.drawText(thirdQuarter, Object.assign(options, { x: this.PDF_BIR_2307.moneyPayments.columns[4] }))
      page.drawText(total, Object.assign(options, { x: this.PDF_BIR_2307.moneyPayments.columns[5] }))
      page.drawText(taxWithHeld, Object.assign(options, { x: this.PDF_BIR_2307.moneyPayments.columns[6] }))
    },

    async BIRForm2307User (page, name, tin, position) {
      page.drawText(tin, {
        x: ((page.getWidth() / 2) - (this.PDF_BIR_2307.font.widthOfTextAtSize(tin, this.PDF_BIR_2307.fontSize) / 2)),
        y: 192,
        size: this.PDF_BIR_2307.fontSize
      })
      page.drawText(name, {
        x: ((page.getWidth() / 2) - (this.PDF_BIR_2307.font.widthOfTextAtSize(name, this.PDF_BIR_2307.fontSize) / 2)),
        y: 200,
        size: this.PDF_BIR_2307.fontSize
      })
      page.drawText(position, {
        x: (page.getWidth() - 50) - this.PDF_BIR_2307.font.widthOfTextAtSize(position, this.PDF_BIR_2307.fontSize),
        y: 195,
        size: this.PDF_BIR_2307.fontSize
      })
    },

    async BIRForm2307Conforme (page, name) {
      page.drawText(name, {
        x: ((page.getWidth() / 2) - (this.PDF_BIR_2307.font.widthOfTextAtSize(name, this.PDF_BIR_2307.fontSize) / 2)),
        y: 118,
        size: this.PDF_BIR_2307.fontSize
      })
    }

  }
}
