import ApiService from '@/services/core/api.service'

const VoucherService = {
  async get (query = '') {
    return ApiService.get(`auditor/payment-requests?${query}`)
  },

  async post (payload) {
    return ApiService.post('auditor/payment-requests', payload)
  },

  async put (payload) {
    return ApiService.put(`auditor/payment-requests/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`auditor/payment-requests/${payload.id}`)
  }
}

export default VoucherService
