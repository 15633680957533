const RAMMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'ram.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-objects-column',
    active_icon: 'fad fa-objects-column'
  },
  {
    title: 'Payments',
    is_heading: true,
    is_active: false,
    link: {
      name: 'ram.payments'
    },
    class_name: 'mt-3 font-weight-bolder'
  },
  {
    title: 'Approvals',
    is_heading: false,
    is_active: false,
    link: {
      name: 'ram.payments.approvals'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-file-signature',
    active_icon: 'fad fa-file-signature'
  }
]

export default RAMMenu
