<template>
  <b-container
    class="mt-2"
    fluid
  >
    <!-- Filters -->
    <b-row>
      <b-col
        cols="12"
        class="px-1"
      >
        <b-form-group
          label="Search"
          label-for="filter_pending_search"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filter_pending_search"
            v-model="tablePending.filter.search"
            type="text"
            placeholder="search here"
            debounce="1000"
            autocomplete="off"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="px-1"
      >
        <b-button
          size="sm"
          :variant="`${tablePending.busy || paymentRequests.selected.length <= 0 || state.busy ? 'outline-success' : 'success'}`"
          :disabled="tablePending.busy || paymentRequests.selected.length <= 0 || state.busy"
          @click="onReleaseCheckVouchers"
        >
          Release Selected
        </b-button>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-row
      v-if="currentTabIndex === 0"
      class="mt-1"
    >
      <b-col
        cols="12"
        class="px-1"
      >
        <b-table
          ref="tablePending"
          small
          hover
          striped
          bordered
          responsive
          show-empty
          :stacked="isMobile()"
          :per-page="tablePending.perPage"
          :current-page="tablePending.currentPage"
          :items="tablePendingProvider"
          :fields="tablePending.fields"
          :sort-by.sync="tablePending.sortBy"
          :sort-desc.sync="tablePending.sortDesc"
          :sort-direction="tablePending.sortDirection"
          :filter="tablePending.filter"
          :filter-included-fields="tablePending.filterOn"
          :busy="tablePending.busy"
        >
          <template #cell(index)="row">
            {{ tablePending.currentPage * tablePending.perPage - tablePending.perPage + (row.index + 1) }}
          </template>

          <template #cell()="row">
            <div class="text-md-nowrap">
              {{ row.value }}
            </div>
          </template>

          <template #head(selected)>
            <b-form-checkbox
              v-model="selected.allSelected"
              :indeterminate="selected.indeterminate"
              :disabled="Number(tablePending.totalRows) === 0 || state.busy"
              size="lg"
              @change="onSelectAllPaymentRequests"
            />
          </template>

          <template #cell(selected)="row">
            <b-form-checkbox
              v-model="paymentRequests.selected"
              :value="row.item.id"
              :disabled="state.busy"
              size="lg"
            />
          </template>

          <template #cell(approvable.voucher.voucher_number)="row">
            <div class="text-md-nowrap">
              <b>{{ row.value }}</b>
            </div>
          </template>

          <template #cell(approvable.amount)="row">
            <div class="text-md-nowrap text-md-right">
              {{ row.value }}
            </div>
          </template>

          <template #cell(action)="row">
            <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
              <b-button
                size="sm"
                variant="success"
                :disabled="busy || paymentRequests.selected.length > 0"
                @click="onReleaseCheckVoucher(row.item)"
              >
                Release
              </b-button>
            </div>
          </template>

          <template #table-busy>
            <div class="text-center py-5">
              <b-icon
                icon="stopwatch"
                font-scale="5"
                animation="cylon"
              />
              <p class="h3 py-2">
                <strong>Loading . . .</strong>
              </p>
            </div>
          </template>

        </b-table>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="mt-1 mb-2 px-1"
      >
        <b-select
          v-model="tablePending.perPage"
          :options="tablePending.pageOptions"
          class="w-100 w-md-25"
        />
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
      >
        <b-pagination
          v-model="tablePending.currentPage"
          pills
          last-number
          first-number
          prev-text="Prev"
          next-text="Next"
          aria-controls="table"
          :total-rows="tablePending.totalRows"
          :per-page="tablePending.perPage"
        />
      </b-col>

    </b-row>

    <b-modal
      id="modal-payment-requests"
      size="sm"
      scrollable
      centered
      title="Confirmation"
      title-class="col-12 h4 p-2 text-warning text-center"
      header-class="p-0"
      hide-header-close
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
    >
      <b-container class="px-1 py-2 m-0">
        <h6 class="text-center">
          Mark Vouchers as Released?
        </h6>
      </b-container>
      <ValidationObserver
        ref="formPaymentRequests"
      >
        <form
          role="form"
          novalidate
          @submit.prevent
        >

          <b-row>
            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                vid="remarks"
                name="remarks"
                rules="max:5000"
              >
                <b-form-group
                  label="Remarks"
                  label-for="pr_remarks"
                  label-class="font-weight-bolder"
                  class="mb-1"
                >
                  <b-textarea
                    id="pr_remarks"
                    v-model="paymentRequests.remarks"
                    rows="6"
                    max-rows="8"
                    placeholder="enter remarks here ( optional )"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        </form>
        <b-table
          small
          :items="tableVoucherProvider"
          :fields="tableVouchers.fields"
        />
      </ValidationObserver>
      <template #modal-footer="{ cancel }">
        <b-overlay
          rounded
          opacity="0.1"
          spinner-variant="warning"
          spinner-small
          :show="state.busy"
        >
          <b-button
            variant="success"
            size="sm"
            :disabled="state.busy"
            @click="onValidatePaymentRequests"
          >
            Mark as Released
          </b-button>
        </b-overlay>
        <b-button
          variant="outline-dark"
          size="sm"
          :disabled="state.busy"
          @click="cancel()"
        >
          Discard
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-payment-request"
      size="sm"
      scrollable
      centered
      title="Confirmation"
      title-class="col-12 h4 p-2 text-warning text-center"
      header-class="p-0"
      hide-header-close
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
    >
      <b-container class="px-1 py-2 m-0">
        <h6 class="text-center">
          Mark Voucher as Released?
        </h6>
        <h6 class="text-center">
          <strong class="text-success">
            {{ paymentRequest.voucher_number }}
          </strong>
        </h6>
      </b-container>
      <ValidationObserver
        ref="formPaymentRequest"
      >
        <b-row>
          <b-col
            cols="12"
          >
            <ValidationProvider
              #default="{ errors }"
              vid="remarks"
              name="remarks"
              rules="max:5000"
            >
              <b-form-group
                label="Remarks"
                label-for="pr_remarks"
                label-class="font-weight-bolder"
                class="mb-1"
              >
                <b-textarea
                  id="pr_remarks"
                  v-model="paymentRequests.remarks"
                  rows="6"
                  max-rows="8"
                  placeholder="enter remarks here ( optional )"
                  :state="errors.length > 0 ? false : null"
                  :disabled="state.busy"
                />
                <div
                  v-if="errors.length > 0"
                  class="invalid-feedback"
                >
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
      </ValidationObserver>
      <template #modal-footer="{ cancel }">
        <b-overlay
          rounded
          opacity="0.1"
          spinner-variant="warning"
          spinner-small
          :show="state.busy"
        >
          <b-button
            variant="success"
            size="sm"
            :disabled="state.busy"
            @click="onValidatePaymentRequest"
          >
            Mark as Released
          </b-button>
        </b-overlay>
        <b-button
          variant="outline-dark"
          size="sm"
          :disabled="state.busy"
          @click="cancel()"
        >
          Discard
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SESCheckReleasingPending } from '@/services'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'
export default {

  name: 'EsPaymentRequestsCheckReleasingPending',

  mixins: [formatter, misc],

  props: {
    busy: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    currentTabIndex: {
      type: Number,
      default: () => {
        return 0
      }
    }
  },

  data () {
    return {
      state: {
        busy: false
      },
      selected: {
        allSelected: false,
        indeterminate: false
      },
      paymentRequests: {
        id: 0,
        selected: [],
        status: 'Processed',
        remarks: null
      },
      paymentRequest: {
        id: 0,
        selected: [],
        voucher_number: null,
        status: 'Processed',
        remarks: null
      },
      tablePending: {
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 1, key: 'selected', class: 'text-center' },
          { mobile: 10, key: 'action', class: 'text-center' },
          { mobile: 2, key: 'approvable.voucher.voucher_number', label: 'Voucher Number', class: 'text-center', sortable: true },
          { mobile: 3, key: 'approvable.document_number', label: 'Document Number', class: 'text-center' },
          { mobile: 4, key: 'approvable.bp_name', label: 'Business Partner' },
          { mobile: 5, key: 'approvable.customer_name', label: 'Cash Advance' },
          { mobile: 6, key: 'approvable.mop', label: 'Mode of Payment' },
          { mobile: 7, key: 'approvable.amount', label: 'Amount', formatter: this.numberFormatter },
          { mobile: 8, key: 'approvable.approvals', label: 'Time Lapsed', formatter: this.getTimeDifferenceFromLastApprover },
          { mobile: 9, key: 'approvable.requestor', label: 'Requested By', formatter: this.userCurrentRequestor }
        ].sort(this.scaleOnMobile)
      },
      tableVouchers: {
        fields: [
          { mobile: 0, key: 'approvable.voucher.voucher_number', label: 'Voucher Numbers', class: 'font-weight-bolder' }
        ]
      }
    }
  },

  watch: {
    'paymentRequests.selected' (newValues) {
      if (newValues.length === 0) {
        this.selected.indeterminate = false
        this.selected.allSelected = false
      } else if (newValues.length === this.$refs.tablePending?.localItems.length) {
        this.selected.indeterminate = false
        this.selected.allSelected = true
      } else {
        this.selected.indeterminate = true
        this.selected.allSelected = false
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async tablePendingProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tablePending.busy = true
      return await SESCheckReleasingPending.get(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search
        })
      ).then(({ data }) => {
        this.tablePending.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tablePending.busy = false
      })
    },

    tableVoucherProvider () {
      return this.$refs.tablePending.localItems.filter(
        approvable => this.paymentRequests.selected.includes(approvable.id)
      )
    },

    // MULTIPLE
    onSelectAllPaymentRequests (selected) {
      this.paymentRequests.selected = selected ? this.$refs.tablePending.localItems.map(item => item.id) : []
    },

    onReleaseCheckVouchers (event) {
      event.preventDefault()
      this.paymentRequests.remarks = null
      this.$bvModal.show('modal-payment-requests')
    },

    async onValidatePaymentRequests (event) {
      event.preventDefault()
      this.$refs.formPaymentRequests.validate().then(
        allFieldsAreValid => {
          if (allFieldsAreValid) {
            this.state.busy = true
            return this.onPut(this.paymentRequests).then(
              ({ message }) => {
                this.tablePendingRefresh()
                this.$bvModal.hide('modal-payment-requests')
                this.swalSuccess(message).then(() => {
                  this.paymentRequests.selected = []
                  this.paymentRequests.remarks = null
                })
              }
            ).finally(() => {
              this.state.busy = false
            })
          } else {
            this.swalInvalid()
          }
        }
      )
    },

    // SINGLE
    onReleaseCheckVoucher ({ id, approvable: { voucher } }) {
      this.paymentRequest.selected = [id]
      this.paymentRequest.voucher_number = voucher.voucher_number
      this.paymentRequest.remarks = null
      this.$bvModal.show('modal-payment-request')
    },

    async onValidatePaymentRequest (event) {
      event.preventDefault()
      this.$refs.formPaymentRequest.validate().then(
        allFieldsAreValid => {
          if (allFieldsAreValid) {
            this.state.busy = true
            return this.onPut(this.paymentRequest).then(
              ({ message }) => {
                this.tablePendingRefresh()
                this.$bvModal.hide('modal-payment-request')
                this.swalSuccess(message).then(() => {
                  this.paymentRequest.selected = []
                  this.paymentRequest.voucher_number = null
                  this.paymentRequest.remarks = null
                })
              }
            ).finally(() => {
              this.state.busy = false
            })
          } else {
            this.swalInvalid()
          }
        }
      )
    },

    onPut (paymentRequests) {
      return new Promise((resolve, reject) => {
        SESCheckReleasingPending.put(paymentRequests).then(
          ({ data }) => resolve(data)
        ).catch(
          error => reject(error.message)
        )
      })
    },

    tablePendingRefresh () {
      if (this.currentTabIndex === 0) {
        this.$refs.tablePending.refresh()
      }
    }
  }
}
</script>
