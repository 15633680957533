<template>
  <b-container
    fluid
  >
    <div class="d-flex justify-content-end mb-3 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <!-- Filters -->
    <b-row
      class="px-2"
    >

      <b-col
        cols="12"
        md="10"
        class="px-1"
        order="2"
        order-md="1"
      >
        <b-form-group
          label="Search"
          label-for="filter_pending_search"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filter_pending_search"
            v-model="tablePaymentVoucher.filter.search"
            type="text"
            placeholder="search here"
            debounce="1000"
            autocomplete="off"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="2"
        class="px-1"
        order="1"
        order-md="2"
      >
        <b-form-group
          label="Status"
          label-for="filter_status"
          label-class="font-weight-bolder"
        >
          <b-form-select
            id="filter_status"
            v-model="tablePaymentVoucher.filter.status"
            :options="lists.statuses"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                -- select status here --
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

    </b-row>
    <!-- Table -->
    <b-row
      class="mt-1 px-2"
    >
      <b-col
        cols="12"
        class="px-1"
      >
        <b-table
          ref="tablePaymentVoucher"
          small
          hover
          bordered
          responsive
          show-empty
          :stacked="isMobile()"
          :per-page="tablePaymentVoucher.perPage"
          :current-page="tablePaymentVoucher.currentPage"
          :items="tablePaymentVoucherProvider"
          :fields="tablePaymentVoucher.fields"
          :sort-by.sync="tablePaymentVoucher.sortBy"
          :sort-desc.sync="tablePaymentVoucher.sortDesc"
          :sort-direction="tablePaymentVoucher.sortDirection"
          :filter="tablePaymentVoucher.filter"
          :filter-included-fields="tablePaymentVoucher.filterOn"
          :busy="tablePaymentVoucher.busy"
        >
          <template #cell(index)="row">
            {{ tablePaymentVoucher.currentPage * tablePaymentVoucher.perPage - tablePaymentVoucher.perPage + (row.index + 1) }}
          </template>

          <template #cell()="row">
            <div class="text-md-nowrap">
              {{ row.value }}
            </div>
          </template>

          <template #cell(voucher_number)="row">
            <div class="text-md-nowrap">
              <b>{{ row.value }}</b>
            </div>
          </template>

          <template #cell(payment_request.amount)="row">
            <div
              class="text-md-nowrap text-md-right"
              style="min-width: 100px;"
            >
              {{ row.value }}
            </div>
          </template>

          <template #cell(action)="{ item }">
            <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
              <b-button
                size="sm"
                @click="onView(item.payment_request, item)"
              >
                View
              </b-button>
            </div>
          </template>

          <template #table-busy>
            <div class="text-center py-5">
              <b-icon
                icon="stopwatch"
                font-scale="5"
                animation="cylon"
              />
              <p class="h3 py-2">
                <strong>Loading . . .</strong>
              </p>
            </div>
          </template>

        </b-table>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="mt-1 px-1"
      >
        <b-select
          v-model="tablePaymentVoucher.perPage"
          :options="tablePaymentVoucher.pageOptions"
          class="w-100 w-md-25"
        />
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
      >
        <b-pagination
          v-model="tablePaymentVoucher.currentPage"
          pills
          last-number
          first-number
          prev-text="Prev"
          next-text="Next"
          aria-controls="table"
          :total-rows="tablePaymentVoucher.totalRows"
          :per-page="tablePaymentVoucher.perPage"
        />
      </b-col>

    </b-row>

    <b-modal
      id="modal-payment-request"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      modal-class="fullscreen"
    >
      <ValidationObserver
        ref="formPaymentRequest"
      >
        <b-form
          role="form"
          novalidate
          @submit.prevent
        >
          <b-tabs
            active-nav-item-class="font-weight-bold text-uppercase text-success"
            content-class="scrollable"
          >
            <b-tab class="p-2">
              <template #title>
                <div class="text-md-nowrap">
                  <i class="ri-file-text-line h4" />
                  <b class="is-tab-active">Details</b>
                </div>
              </template>
              <b-row>
                <!-- CENTERS -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-row>

                    <b-col
                      cols="12"
                    >
                      <b-row>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Transaction Type"
                            label-for="pr_bp_transaction_type"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_bp_transaction_type"
                              v-model="paymentRequest.bp_transaction_type"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          v-if="paymentRequest.bp_transaction_type === 'With-2307'"
                          cols="12"
                        >
                          <b-form-group
                            label="2307 Period"
                            label-for="pr_report_period"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_report_period"
                              v-model="paymentRequest.report_details"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                      </b-row>

                    </b-col>

                    <b-col
                      cols="12"
                    >

                      <b-row>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Budget Center"
                            label-for="pr_budget_center"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_budget_center"
                              v-model="paymentRequest.budget_name"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Item Description"
                            label-for="pr_item_description"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-textarea
                              id="pr_item_description"
                              v-model="paymentRequest.item_name"
                              rows="2"
                              max-rows="4"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                      </b-row>
                    </b-col>

                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="Particulars"
                        label-for="pr_particulars"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-textarea
                          id="pr_particulars"
                          v-model="paymentRequest.particulars"
                          rows="7"
                          max-rows="12"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                  </b-row>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-row>
                    <!-- BUSINESS PARTNER -->
                    <b-col
                      cols="12"
                    >
                      <b-row>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Business Partner"
                            label-for="pr_business_partner"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-textarea
                              id="pr_business_partner"
                              v-model="paymentRequest.bp_name"
                              rows="2"
                              max-rows="4"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="TIN"
                            label-for="pr_bp_tin"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_bp_tin"
                              v-model="paymentRequest.bp_tin"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="ATC"
                            label-for="pr_bp_tax_code"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_bp_tax_code"
                              v-model="paymentRequest.bp_tax_description"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Type"
                            label-for="pr_bp_partner_type"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_bp_partner_type"
                              v-model="paymentRequest.bp_partner_type"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Business Tax"
                            label-for="pr_bp_business_tax"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_bp_business_tax"
                              v-model="paymentRequest.bp_business_tax"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Tax Status"
                            label-for="pr_bp_tax_status"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_bp_tax_status"
                              v-model="paymentRequest.bp_tax_status"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                      </b-row>
                    </b-col>

                    <b-col
                      cols="12"
                      class="pb-2"
                    >
                      <b-row>

                        <b-col
                          cols="12"
                        >
                          <b-form-group class="mb-2">
                            <b-form-checkbox
                              id="is_cash_advance"
                              v-model="paymentRequest.is_cash_advance"
                              name="is_cash_advance"
                              unchecked-value="0"
                              value="1"
                              switch
                              disabled
                            >
                              <strong>Is Cash Advance?</strong>
                            </b-form-checkbox>
                          </b-form-group>
                          <b-form-group
                            label="Cash Adv. Account"
                            label-for="pr_cash_advance"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_amount"
                              v-model="paymentRequest.customer_name"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                      </b-row>
                    </b-col>

                  </b-row>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-row>
                    <!-- PAYMENTS -->
                    <b-col
                      cols="12"
                    >
                      <b-row>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Mode Of Payment"
                            label-for="pr_mode_of_payment"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_mode_of_payment"
                              v-model="paymentRequest.mop"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Branch"
                            label-for="pr_branch"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_branch"
                              v-model="paymentRequest.branch"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Amount"
                            label-for="pr_amount"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_amount"
                              v-model="paymentRequest.amount"
                              type="text"
                              class="text-right"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Invoice Amount"
                            label-for="pr_invoice_amount"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_invoice_amount"
                              v-model="paymentRequest.invoice_amount"
                              type="text"
                              class="text-right"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Taxable Amount"
                            label-for="pr_taxable_amount"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_taxable_amount"
                              v-model="paymentRequest.taxable_amount"
                              type="text"
                              class="text-right"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Withholding Tax"
                            label-for="pr_with_holding_tax"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_with_holding_tax"
                              v-model="paymentRequest.withholding_tax"
                              type="text"
                              class="text-right"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Amount Due"
                            label-for="pr_amount_due"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_amount_due"
                              v-model="paymentRequest.amount_due"
                              type="text"
                              class="text-right"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>

                    <!-- BANK ACCOUNT -->
                    <b-col
                      cols="12"
                    >
                      <b-row>

                        <b-col
                          cols="12"
                        >
                          <b-form-group class="mb-2">
                            <b-form-checkbox
                              id="for_deposit"
                              v-model="paymentRequest.for_deposit"
                              switch
                              value="1"
                              name="for_deposit"
                              unchecked-value="0"
                              disabled
                            >
                              <strong>For Deposit?</strong>
                            </b-form-checkbox>
                          </b-form-group>
                          <b-form-group
                            label="Bank"
                            label-for="pr_deposit_bank"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_deposit_bank"
                              v-model="paymentRequest.deposit_bank"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Account Number"
                            label-for="pr_deposit_account_number"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_deposit_account_number"
                              v-model="paymentRequest.deposit_account_number"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Account Name"
                            label-for="pr_deposit_account_name"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_deposit_account_name"
                              v-model="paymentRequest.deposit_account_name"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                      </b-row>
                    </b-col>

                  </b-row>
                </b-col>

              </b-row>
            </b-tab>

            <b-tab class="p-2">
              <template #title>
                <div class="text-md-nowrap">
                  <i class="ri-attachment-line h4" />
                  <b class="is-tab-active">Attachments</b>
                </div>
              </template>
              <Attachment
                :file-path="paymentRequest.attachment_path"
                :file-list="paymentRequest.attachments"
                :show-uploader="false"
              />
            </b-tab>

            <b-tab class="p-2">
              <template #title>
                <div class="text-md-nowrap">
                  <i class="ri-file-edit-line h4" />
                  <b class="is-tab-active">Approvals</b>
                </div>
              </template>
              <b-row>
                <b-col
                  class="mb-3 px-2"
                  cols="12"
                  md="3"
                >
                  <Signatory
                    :approver="requesterSignatory"
                    :payment-request="paymentRequest"
                  />
                </b-col>
                <b-col
                  v-for="(approver, key) in paymentRequest.approvals"
                  :key="key"
                  class="mb-3 px-2"
                  cols="12"
                  md="3"
                >
                  <Signatory
                    :approver="approver"
                    :payment-request="paymentRequest"
                  />
                </b-col>
                <b-col
                  v-if="paymentRequest.status === 'Canceled' && Number(paymentRequest.stage) >= 3"
                  class="mb-3 px-2"
                  cols="12"
                  md="3"
                >
                  <Signatory
                    :approver="cancellationSignatory"
                    :payment-request="paymentRequest"
                  />
                </b-col>
              </b-row>
            </b-tab>

          </b-tabs>
        </b-form>
      </ValidationObserver>
      <template #modal-footer="{ cancel }">
        <div class="col-12 d-flex justify-content-between px-0 pb-0 mb-0">
          <div />
          <div>
            <b-button
              variant="outline-dark"
              @click="cancel()"
            >
              Close <span class="d-none d-md-inline-block">Window</span>
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { SDAPaymentVoucher } from '@/services'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'

import Signatory from '@/components/custom/Signatory'
import Attachment from '@/components/custom/Attachment'

export default {

  name: 'DaPaymentVouchers',

  middleware: ['auth', 'da'],

  components: {
    Signatory,
    Attachment
  },

  mixins: [formatter, misc],

  data () {
    return {
      lists: {
        statuses: ['All', 'Pending', 'Released', 'Canceled']
      },
      paymentRequest: {
        id: null,
        stage: 0,
        bp_code: null,
        bp_name: null,
        bp_tin: null,
        bp_partner_type: null,
        bp_vendor_type: null,
        bp_customer_type: null,
        bp_tax_status: null,
        bp_business_tax: null,
        bp_document_type: null,
        bp_tax_code: null,
        bp_tax_description: null,
        is_cash_advance: 0,
        customer_code: null,
        customer_name: null,
        customer_tin: null,
        amount: 0.00,
        budget_code: null,
        budget_name: null,
        item_code: null,
        item_name: null,
        budget_amount: null,
        particulars: null,
        bp_transaction_type: null,
        mop: null,
        branch: null,
        report_year: null,
        report_period: null,
        report_details: null,
        for_deposit: 1,
        deposit_bank: null,
        deposit_account_number: null,
        deposit_account_name: null,
        invoice_amount: 0.00,
        taxable_amount: 0.00,
        withholding_tax: 0.00,
        amount_due: 0.00,
        approvals: [],
        attachment_path: null,
        attachments: [],
        status: 'Printed',
        remarks: null,
        created_at: null
      },
      requesterSignatory: {
        stage: 0,
        approver_label: 'Requester',
        approver_action: 'Requisition',
        approver_name: null,
        last_seen: null,
        status: 'Requested',
        status_at: null,
        remarks: null
      },
      cancellationSignatory: {
        stage: 0,
        approver_label: 'Disbursement Head',
        approver_action: 'Cancellation of Request',
        approver_name: null,
        last_seen: null,
        status: 'Canceled',
        status_at: null,
        remarks: null
      },
      tablePaymentVoucher: {
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null,
          status: 'All'
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 9, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'created_at', label: 'Transaction Date', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'status', label: 'Transaction Status', class: 'text-md-center' },
          { mobile: 3, key: 'voucher_number', label: 'Voucher Number', class: 'text-center', sortable: true },
          { mobile: 4, key: 'payment_request.document_number', label: 'Document Number', class: 'text-center' },
          { mobile: 5, key: 'payment_request.bp_name', label: 'Business Partner' },
          { mobile: 6, key: 'payment_request.customer_name', label: 'Cash Advance' },
          { mobile: 7, key: 'payment_request.mop', label: 'Mode of Payment' },
          { mobile: 8, key: 'payment_request.amount', label: 'Amount', formatter: this.numberFormatter }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async tablePaymentVoucherProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tablePaymentVoucher.busy = true
      return await SDAPaymentVoucher.get(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search,
          filter_status: filter.status
        })
      ).then(({ data }) => {
        this.tablePaymentVoucher.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tablePaymentVoucher.busy = false
      })
    },

    onView (paymentRequest, approval) {
      this.paymentRequest.id = approval.id
      this.paymentRequest.stage = paymentRequest.stage
      // BUSINESS PARTNER
      this.paymentRequest.bp_code = paymentRequest.bp_code
      this.paymentRequest.bp_name = paymentRequest.bp_name
      this.paymentRequest.bp_tin = paymentRequest.bp_tin
      this.paymentRequest.bp_partner_type = paymentRequest.bp_partner_type
      this.paymentRequest.bp_vendor_type = paymentRequest.bp_vendor_type
      this.paymentRequest.bp_customer_type = paymentRequest.bp_customer_type
      this.paymentRequest.bp_tax_status = paymentRequest.bp_tax_status
      this.paymentRequest.bp_business_tax = paymentRequest.bp_business_tax
      this.paymentRequest.bp_tax_code = paymentRequest.bp_tax_code
      if (paymentRequest.tax_code) {
        this.paymentRequest.bp_tax_description = `${paymentRequest.bp_tax_code} - ${paymentRequest.tax_code.description}`
      }
      this.paymentRequest.bp_document_type = paymentRequest.bp_document_type
      // CASH ADVANCE ACCOUNT
      this.paymentRequest.is_cash_advance = paymentRequest.is_cash_advance
      this.paymentRequest.customer_code = paymentRequest.customer_code
      this.paymentRequest.customer_name = paymentRequest.customer_name
      this.paymentRequest.customer_tin = paymentRequest.customer_tin
      // BANK DETAILS
      this.paymentRequest.for_deposit = paymentRequest.for_deposit
      this.paymentRequest.deposit_bank = paymentRequest.deposit_bank
      this.paymentRequest.deposit_account_number = paymentRequest.deposit_account_number
      this.paymentRequest.deposit_account_name = paymentRequest.deposit_account_name
      // REQUEST DETAILS
      this.paymentRequest.amount = this.numberFormatter(paymentRequest.amount)
      this.paymentRequest.particulars = paymentRequest.particulars
      this.paymentRequest.bp_transaction_type = paymentRequest.bp_transaction_type
      this.paymentRequest.mop = paymentRequest.mop
      this.paymentRequest.branch = paymentRequest.branch
      this.paymentRequest.report_year = paymentRequest.report_year
      this.paymentRequest.report_period = paymentRequest.report_period
      this.paymentRequest.report_details = `${paymentRequest.report_year} - ${paymentRequest.report_period}`
      this.paymentRequest.invoice_amount = this.numberFormatter(paymentRequest.invoice_amount)
      this.paymentRequest.taxable_amount = this.numberFormatter(paymentRequest.taxable_amount)
      this.paymentRequest.withholding_tax = this.numberFormatter(paymentRequest.withholding_tax)
      this.paymentRequest.amount_due = this.numberFormatter(paymentRequest.amount_due)
      this.paymentRequest.approvals = paymentRequest.approvals
      this.paymentRequest.attachment_path = paymentRequest.attachment_path
      this.paymentRequest.attachments = paymentRequest.attachments

      this.paymentRequest.status = paymentRequest.status
      this.paymentRequest.remarks = approval.remarks
      this.paymentRequest.created_at = paymentRequest.created_at

      // REQUESTER
      if (paymentRequest.requestor) {
        this.requesterSignatory.approver_name = `${paymentRequest.requestor.first_name} ${paymentRequest.requestor.last_name}`
        this.requesterSignatory.status_at = paymentRequest.created_at
        this.requesterSignatory.status = 'Requested'
        this.requesterSignatory.remarks = null

        if (paymentRequest.status === 'Canceled') {
          if (paymentRequest.canceled_at && paymentRequest.canceled_by) {
            if (Number(paymentRequest.stage) >= 3) {
              this.cancellationSignatory.approver_name = paymentRequest.canceled_by
              this.cancellationSignatory.status_at = paymentRequest.canceled_at
              this.cancellationSignatory.status = 'Canceled'
              this.cancellationSignatory.remarks = paymentRequest.canceled_remarks
            }
          }
        }
      }

      if (paymentRequest.budget_center) {
        this.paymentRequest.budget_code = paymentRequest.budget_center.budget_code
        this.paymentRequest.budget_name = paymentRequest.budget_center.budget_name
      }

      if (paymentRequest.item_center) {
        this.paymentRequest.budget_amount = paymentRequest.item_center.budget_amount
        if (paymentRequest.item_center.item) {
          this.paymentRequest.item_code = paymentRequest.item_center.item.item_code
          this.paymentRequest.item_name = paymentRequest.item_center.item.item_name
        }
      }

      this.$bvModal.show('modal-payment-request')
    }

  }

}
</script>
