<template>
  <b-container
    class="vh-100 d-flex justify-content-center align-items-center flex-column"
  >
    <b-img
      :src="require('@/assets/images/error/error-01.png')"
      class="mb-5"
      style="width: 50vw;"
    />
    <p class="text-center h3">
      Oops! Page not Found.
    </p>
    <p class="text-center">
      The requested page does not exist.
    </p>
    <router-link
      class="btn btn-success mt-3"
      :to="{ name: 'shared.login' }"
    >
      <i class="ri-home-4-line" />Back to Home
    </router-link>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'

export default {
  name: 'SharedPageNotFound',
  mounted () {
    core.index()
  }
}
</script>
