<template>
  <b-container
    fluid
  >
    <div class="d-flex justify-content-end mb-0 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-tabs
      v-model="state.currentTabIndex"
      active-nav-item-class="font-weight-bold text-uppercase text-success"
    >
      <b-tab>
        <template #title>
          <i :class="iconState('ri-file-edit', state.currentTabIndex === 0)" /> Pending
        </template>
        <CheckReceivingPending
          ref="prPending"
          :busy="state.busy"
          :current-tab-index="state.currentTabIndex"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <i :class="iconState('ri-archive', state.currentTabIndex === 1)" /> Histories
        </template>
        <CheckReceivingHistories
          ref="prHistories"
          :busy="state.busy"
          :current-tab-index="state.currentTabIndex"
        />
      </b-tab>
    </b-tabs>

  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import CheckReceivingPending from '@/views/es/payments/checks/receiving/Pending'
import CheckReceivingHistories from '@/views/es/payments/checks/receiving/Histories'
import misc from '@/mixins/misc'

export default {

  name: 'EsPaymentRequestsCheckReceiving',

  middleware: ['auth', 'es'],

  metaInfo: () => ({
    title: 'Check Receiving'
  }),

  components: {
    CheckReceivingPending,
    CheckReceivingHistories
  },

  mixins: [misc],

  data () {
    return {
      state: {
        busy: false,
        currentTabIndex: this.getCurrentTabIndexByURL()
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    getCurrentTabIndexByURL () {
      switch (this.$route.params.es) {
        case 'histories' : {
          return 1
        }
        default: {
          return 0
        }
      }
    }

  }

}
</script>
