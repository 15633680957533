function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/fom/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const FOMRoutes = [
  {
    path: '/fom/dashboard',
    component: view('Dashboard'),
    name: 'fom.dashboard',
    authorizedRole: 'fom'
  },
  // PENDING APPROVALS
  {
    path: '/fom/payments/approvals/:fom?/:status?',
    component: view('payments/approvals/Base'),
    name: 'fom.payments.approvals',
    authorizedRole: 'fom',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Payments'
        },
        {
          text: 'Approvals',
          active: true
        }
      ]
    }
  }
]

export default FOMRoutes
