import ApiService from '@/services/core/api.service'

const ItemCenterService = {
  async get (query = '') {
    return ApiService.get(`user/item-centers?${query}`)
  },

  async show (itemCenter, query = '') {
    return ApiService.get(`user/item-centers/${itemCenter}?${query}`)
  },

  async export (query = '') {
    return ApiService.get(`user/exports/item-centers?${query}`)
  },

  async exportRequests (itemCenter, query = '') {
    return ApiService.get(`user/exports/payment-requests/${itemCenter}?${query}`)
  }
}

export default ItemCenterService
