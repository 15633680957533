<template>
  <b-container
    class="mt-2"
    fluid
  >
    <!-- Filters -->
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="px-1"
        order="3"
        order-md="1"
      >
        <b-form-group
          label="Search"
          label-for="filterReplenishmentSearch"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filterReplenishmentSearch"
            v-model="tableReplenishments.filter.search"
            type="text"
            placeholder="search here"
            debounce="1000"
            autocomplete="off"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="6"
        md="3"
        class="px-1"
        order="2"
      >
        <b-form-group
          label="Released From"
          label-for="filter_released_from"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filter_released_from"
            v-model="tableReplenishments.filter.date_from"
            debounce="1000"
            type="datetime-local"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="6"
        md="3"
        class="px-1"
        order="2"
      >
        <b-form-group
          label="Released To"
          label-for="filter_released_to"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filter_released_to"
            v-model="tableReplenishments.filter.date_to"
            debounce="1000"
            type="datetime-local"
          />
        </b-form-group>
      </b-col>

      <b-col
        v-if="isValidForExport"
        class="px-1 mb-2"
        order="3"
      >
        <b-overlay
          rounded
          opacity="0.1"
          spinner-small
          class="d-inline"
          spinner-variant="warning"
          :show="exporter.excel.exporting"
        >
          <json-excel
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :class="[
              'btn btn-success btn-sm', {
                'btn-outline-success disabled': exporter.excel.exporting
              }
            ]"
            :fetch="excelProvider"
            :before-generate="onStartExcelExport"
            :before-finish="onFinishExcelExport"
            :fields="exporter.excel.fields"
            :escape-csv="exporter.excel.escape"
            :type="exporter.excel.type"
            :header="exporter.excel.header"
            :footer="exporter.excel.footer"
            :name="excelFileName"
            worksheet="Replenishment for Revolving Fund"
          >
            {{ exporter.excel.exporting ? 'Exporting' : 'Export As Excel' }}
          </json-excel>
        </b-overlay>
      </b-col>

    </b-row>
    <!-- Table -->
    <b-row
      class="mt-1"
    >
      <b-col
        cols="12"
        class="px-1"
      >
        <b-table
          ref="tableReplenishments"
          small
          hover
          striped
          bordered
          responsive
          show-empty
          :stacked="isMobile()"
          :per-page="tableReplenishments.perPage"
          :current-page="tableReplenishments.currentPage"
          :items="tableReplenishmentsProvider"
          :fields="tableReplenishments.fields"
          :sort-by.sync="tableReplenishments.sortBy"
          :sort-desc.sync="tableReplenishments.sortDesc"
          :sort-direction="tableReplenishments.sortDirection"
          :filter="tableReplenishments.filter"
          :filter-included-fields="tableReplenishments.filterOn"
          :busy="tableReplenishments.busy"
        >
          <template #cell(index)="row">
            {{ tableReplenishments.currentPage * tableReplenishments.perPage - tableReplenishments.perPage + (row.index + 1) }}
          </template>

          <template #cell()="row">
            <div class="text-md-nowrap">
              {{ row.value }}
            </div>
          </template>

          <template #cell(approvable.voucher.voucher_number)="row">
            <div class="text-md-nowrap">
              <b>{{ row.value }}</b>
            </div>
          </template>

          <template #cell(approvable.amount_due)="row">
            <div
              class="text-md-nowrap text-md-right"
              style="min-width: 150px;"
            >
              {{ row.value }}
            </div>
          </template>

          <template #table-busy>
            <div class="text-center py-5">
              <b-icon
                icon="stopwatch"
                font-scale="5"
                animation="cylon"
              />
              <p class="h3 py-2">
                <strong>Loading . . .</strong>
              </p>
            </div>
          </template>

        </b-table>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="mt-1 mb-2 px-1"
      >
        <b-select
          v-model="tableReplenishments.perPage"
          :options="tableReplenishments.pageOptions"
          class="w-100 w-md-25"
        />
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
      >
        <b-pagination
          v-model="tableReplenishments.currentPage"
          pills
          last-number
          first-number
          prev-text="Prev"
          next-text="Next"
          aria-controls="table"
          :total-rows="tableReplenishments.totalRows"
          :per-page="tableReplenishments.perPage"
        />
      </b-col>

    </b-row>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { SDAReportCAReplenishments } from '@/services'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'
import RevolvingFunds from '@/mixins/printing/revolving-funds'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'

export default {

  name: 'DAReportsCAReplenishments',

  middleware: ['auth', 'da'],

  directives: {
    Ripple
  },

  mixins: [formatter, misc, RevolvingFunds],

  data () {
    return {
      busy: false,
      exporter: {
        busy: false,
        pdf: {
          exporting: false,
          data: null
        },
        excel: {
          exporting: false,
          header: [],
          escape: false,
          type: 'xls',
          fields: {
            Account: {
              field: 'approvable',
              callback: () => 'Cash Advance'
            },
            Particulars: {
              field: 'approvable.particulars'
            },
            'Received By': {
              field: 'approvable.voucher.payee'
            },
            'Voucher Number': {
              field: 'approvable.voucher.voucher_number'
            },
            Amount: {
              field: 'approvable.amount_due',
              callback: amount => this.numberFormatter(amount)
            },
            Remarks: {
              field: 'remarks'
            }
          },
          footer: []
        }
      },
      tableReplenishments: {
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null,
          date_from: moment().format('YYYY-MM-DDT08:00'),
          date_to: moment().format('YYYY-MM-DDT17:00')
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 1, key: 'approvable.released_at', label: 'Released At', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'approvable.voucher.voucher_number', label: 'Voucher Number', class: 'text-center', sortable: true },
          { mobile: 3, key: 'approvable.bp_name', label: 'Business Partner' },
          { mobile: 4, key: 'approvable.customer_name', label: 'Cash Advance' },
          { mobile: 5, key: 'approvable.mop', label: 'Mode of Payment', class: 'text-center' },
          { mobile: 6, key: 'approvable.amount_due', label: 'Amount Due', formatter: this.numberFormatter },
          { mobile: 7, key: 'approvable.requestor', label: 'Requested By', formatter: this.userCurrentRequestor }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  computed: {
    excelFileName () {
      const dateFrom = this.dateTimeShortFormatter(this.tableReplenishments.filter.date_from)
      const dateTo = this.dateTimeShortFormatter(this.tableReplenishments.filter.date_to)
      return `Replenishments for Revolving Funds ( ${dateFrom} to ${dateTo} )`
    },

    isValidForExport () {
      return (
        this.tableReplenishments.filter.date_from !== null && this.tableReplenishments.filter.date_to !== null
      )
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async tableReplenishmentsProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableReplenishments.busy = true
      return await SDAReportCAReplenishments.get(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search,
          filter_time_from: filter.date_from,
          filter_time_to: filter.date_to
        })
      ).then(({ data }) => {
        this.tableReplenishments.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableReplenishments.busy = false
      })
    },

    async excelProvider () {
      if (this.exporter.busy) {
        return null
      }
      this.exporter.busy = this.exporter.excel.exporting = true
      return await SDAReportCAReplenishments.export(
        this.objectToUrl({
          filter_text: this.tableReplenishments.filter.search,
          filter_time_from: this.tableReplenishments.filter.date_from,
          filter_time_to: this.tableReplenishments.filter.date_to
        })
      ).then(({ data }) => {
        if (data.items.length <= 0) {
          this.swalInvalid(
            'No available data to be Exported'
          )
        }
        this.setExcelFooter(data.items)
        return data.items
      }).catch(() => {
        this.tableReplenishments.totalRows = 0
        return []
      }).finally(() => {
        this.exporter.busy = this.exporter.excel.exporting = false
      })
    },

    onStartExcelExport () {
      if (this.exporter.busy) {
        return null
      }
      const dateFrom = this.dateTimeLongFormatter(this.tableReplenishments.filter.date_from)
      const dateTo = this.dateTimeLongFormatter(this.tableReplenishments.filter.date_to)
      this.exporter.excel.header = [
        'REPLENISHMENT OF REVOLVING FUND',
        'ALJAY AGRO-INDUSTRIAL SOLUTIONS, INCORPORATED',
        `${dateFrom} - ${dateTo}`,
        '&nbsp;'
      ]
    },

    setExcelFooter (cashVouchers = []) {
      if (cashVouchers && cashVouchers.length > 0) {
        // const approvers = [
        //     `<table>
        //       <tbody>
        //         <tr>
        //           <td colspan="6" style="text-align: left;">
        //             &nbsp;
        //           </td>
        //         </tr>
        //         <tr>
        //           <td colspan="1">
        //             Prepared By:
        //           </td>
        //           <td colspan="1">
        //             Checked By:
        //           </td>
        //           <td colspan="1">
        //             Approved By:
        //           </td>
        //         </tr>
        //         <tr>
        //           <td colspan="1">
        //             ${this.$store.getters['auth/user'].name}
        //           </td>
        //           <td colspan="1">
        //             Joni A. Yusongco
        //           </td>
        //           <td colspan="1">
        //             Nikko R. Bayang, CPA
        //           </td>
        //         </tr>
        //         <tr>
        //           <td colspan="1">
        //             Disbursement Associate
        //           </td>
        //           <td colspan="1">
        //             Disbursement Head
        //           </td>
        //           <td colspan="1">
        //             Financial Operation Manager
        //           </td>
        //         </tr>
        //       </tbody>
        //     </table>`
        // ]
        this.exporter.excel.footer = [
            `<table>
              <thead>
                <tr>
                  <th colspan="6" style="text-align: left;">
                    &nbsp;
                  </th>
                </tr>
                <tr>
                  <th colspan="5" style="text-align: left;">
                    TOTAL
                  </th>
                  <th>
                    ${
                      this.numberFormatter(
                        cashVouchers.map(
                          ({ approvable }) => approvable.amount_due
                        ).reduce(
                          (total, amount) => Number(total) + Number(amount), 0
                        )
                      )
                    }
                  </th>
                </tr>
                <tr>
                  <th colspan="5" style="text-align: left;">
                    TOTAL ENDING BALANCE
                  </th>
                  <th>
                    0.00
                  </th>
                </tr>
                <tr>
                  <th colspan="5" style="text-align: left;">
                    UNREPLENISHED
                  </th>
                  <th>
                    0.00
                  </th>
                </tr>
                <tr>
                  <th colspan="5" style="text-align: left;">
                    CASH ON HAND
                  </th>
                  <th>
                    0.00
                  </th>
                </tr>
                <tr>
                  <th colspan="5" style="text-align: left;">
                    OVER / SHORT
                  </th>
                  <th>
                    0.00
                  </th>
                </tr>
                <tr>
                  <th colspan="5" style="text-align: left;">
                    REVOLVING FUND
                  </th>
                  <th>
                    0.00
                  </th>
                </tr>
              </thead>
            </table>`
        ].concat([])
      }
    },

    onFinishExcelExport () {
    },

    tableReplenishmentsRefresh () {
      this.$refs.tableReplenishments.refresh()
    }
  }

}
</script>
