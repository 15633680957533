function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/dh/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const DHeadRoutes = [
  {
    path: '/dh/dashboard',
    component: view('Dashboard'),
    name: 'dh.dashboard',
    authorizedRole: 'dh'
  },
  {
    path: '/dh/payments/vouchers/:dh?',
    component: view('payments/vouchers/Base'),
    name: 'dh.payments.vouchers',
    authorizedRole: 'dh',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Payment Vouchers',
          active: true
        }
      ]
    }
  }
]

export default DHeadRoutes
