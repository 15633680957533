const CAMMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'cmo.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-objects-column',
    active_icon: 'fad fa-objects-column'
  },
  {
    title: 'Check Approvals',
    is_heading: false,
    is_active: false,
    link: {
      name: 'cmo.payments.check-approvals'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-pen-field',
    active_icon: 'fad fa-pen-field'
  }
]

export default CAMMenu
