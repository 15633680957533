import AdminMenu from './admin-menu'
import AuditMenu from './audit.menu'
import BOMenu from './bo-menu'
import CMOMenu from './cmo-menu'
import DAssociateMenu from './d-associate.menu'
import DHeadMenu from './d-head.menu'
import ESMenu from './es-menu'
import FOMMenu from './fom-menu'
import RAMMenu from './ram-menu'
import TAMenu from './ta-menu'
import UserMenu from './user-menu'

export const getSidebarMenus = user => {
  switch (user.role) {
    case 'admin': {
      return AdminMenu
    }
    case 'audit': {
      return AuditMenu
    }
    case 'bo': {
      return BOMenu
    }
    case 'cmo': {
      return CMOMenu
    }
    case 'da': {
      return DAssociateMenu
    }
    case 'dh': {
      return DHeadMenu
    }
    case 'es': {
      return ESMenu
    }
    case 'fom': {
      return FOMMenu
    }
    case 'ram': {
      return RAMMenu
    }
    case 'ta': {
      return TAMenu
    }
    case 'user': {
      const isNotBudgetOwner = !(user.budget && Object.hasOwn(user.budget, 'id'))
      const isNotApprover = user.is_approver ? Number(user.is_approver) === 0 : true
      const hideToMenu = []

      return UserMenu.filter(
        ({ link }) => {
          // check if next route name if not in lists, not in lists will be visible to user menu

          if (isNotBudgetOwner) {
            hideToMenu.push('user.budgets')
          }

          if (isNotApprover) {
            hideToMenu.push('user.payments.approvals')
          }

          return link && !hideToMenu.includes(
            link.name
          )
        }
      )
    }
    default: {
      return []
    }
  }
}
