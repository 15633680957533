<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-0 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
          class="px-1"
        >
          <b-form-group
            label="Search"
            label-for="filter_employee_search"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filter_employee_search"
              v-model="tableEmployee.filter.search"
              type="text"
              placeholder="search here"
              debounce="1000"
              autocomplete="off"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col
          cols="12"
          class="px-1"
        >
          <b-table
            ref="tableEmployee"
            hover
            small
            striped
            bordered
            responsive
            show-empty
            :stacked="isMobile()"
            :per-page="tableEmployee.perPage"
            :current-page="tableEmployee.currentPage"
            :items="tableEmployeeProvider"
            :fields="tableEmployee.fields"
            :sort-by.sync="tableEmployee.sortBy"
            :sort-desc.sync="tableEmployee.sortDesc"
            :sort-direction="tableEmployee.sortDirection"
            :filter="tableEmployee.filter"
            :filter-included-fields="tableEmployee.filterOn"
            :busy="tableEmployee.busy"
          >
            <template #cell(index)="row">
              {{ tableEmployee.currentPage * tableEmployee.perPage - tableEmployee.perPage + (row.index + 1) }}
            </template>

            <template #cell(is_approver)="row">
              {{ Number(row.value) === 1 ? 'Yes' : 'No' }}
            </template>

            <template #cell(login_sso)="row">
              {{ Number(row.value) === 1 ? 'Yes' : 'No' }}
            </template>

            <template #cell(active)="row">
              {{ Number(row.value) === 1 ? 'Yes' : 'No' }}
            </template>

            <template #cell()="row">
              <div class="text-md-nowrap">
                {{ row.value }}
              </div>
            </template>

            <template #cell(action)="row">
              <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
                <b-button
                  size="sm"
                  variant="success"
                  @click="onEditEmployee(row.item)"
                >
                  Edit
                </b-button>
              </div>
            </template>

            <template #table-busy>
              <div class="text-center py-5">
                <b-icon
                  icon="stopwatch"
                  font-scale="5"
                  animation="cylon"
                />
                <p class="h3 py-2">
                  <strong>Loading . . .</strong>
                </p>
              </div>
            </template>

          </b-table>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mt-1 mb-2 px-1"
        >
          <b-select
            v-model="tableEmployee.perPage"
            :options="tableEmployee.pageOptions"
            class="w-100 w-md-25"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
        >
          <b-pagination
            v-model="tableEmployee.currentPage"
            pills
            last-number
            first-number
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
            :total-rows="tableEmployee.totalRows"
            :per-page="tableEmployee.perPage"
          />
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="modal-employee"
      scrollable
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      :title="ModalEmployeeTitle"
    >
      <ValidationObserver
        ref="formEmployee"
      >
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <b-row>

            <b-col
              cols="12"
            >
              <b-form-group
                label="Email"
                label-for="email"
                label-class="font-weight-bolder"
                class="mb-2"
              >
                <b-input
                  id="email"
                  v-model="employee.email"
                  type="email"
                  autocomplete="off"
                  maxlength="50"
                  placeholder="enter email here"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group
                label="Name"
                label-for="full_name"
                label-class="font-weight-bolder"
                class="mb-2"
              >
                <b-input
                  id="full_name"
                  v-model="employee.full_name"
                  type="text"
                  autocomplete="off"
                  maxlength="50"
                  placeholder="enter name here"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group
                label="Position"
                label-for="position_name"
                label-class="font-weight-bolder"
                class="mb-2"
              >
                <b-input
                  id="position_name"
                  v-model="employee.position_name"
                  type="text"
                  autocomplete="off"
                  maxlength="50"
                  placeholder="enter position here"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group
                class="mb-2"
              >
                <b-form-checkbox
                  id="is_approver"
                  v-model="employee.is_approver"
                  switch
                  value="1"
                  name="is_approver"
                  unchecked-value="0"
                  :disabled="state.busy"
                >
                  Is Approver?
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <!-- <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="approver type"
                vid="approver_type"
                rules="required_if:is_approver,1"
              >
                <b-form-group
                  label="Approver Type"
                  label-for="approver_type"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-form-select
                    id="approver_type"
                    v-model="employee.approver_type"
                    :options="lists.approverTypes"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy || Number(employee.is_approver) === 0"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        -- select approver type here --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col> -->

            <b-col
              v-if="state.editing"
              cols="12"
            >
              <b-form-group
                class="mb-2"
              >
                <b-form-checkbox
                  id="is_active"
                  v-model="employee.active"
                  switch
                  value="1"
                  name="is_active"
                  unchecked-value="0"
                  :disabled="state.busy"
                >
                  Is Active?
                </b-form-checkbox>
              </b-form-group>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{cancel}">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="onValidateEmployee"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>

<script>
import QUERY from 'lodash'
import { core } from '@/config/pluginInit'
import { SAdminEmployee } from '@/services'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'

export default {
  name: 'AdminEmployees',

  middleware: ['auth', 'admin'],

  mixins: [misc, formatter],

  metaInfo: () => ({
    title: 'Employees'
  }),

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      lists: {
        approverTypes: [
          { value: 'Both', text: 'Both' },
          { value: 'Noted-By', text: 'Noted By Only' },
          { value: 'Approved-By', text: 'Approved By Only' }
        ]
      },
      employee: {
        id: null,
        email: null,
        full_name: null,
        position_name: null,
        // approver_type: null,
        is_approver: 0,
        login_sso: 0,
        login_local: 0,
        active: 1
      },
      tableEmployee: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 8, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'created_at', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'email' },
          { mobile: 3, key: 'first_name' },
          { mobile: 4, key: 'last_name' },
          { mobile: 5, key: 'is_approver', label: 'Approver', class: 'text-center' },
          { mobile: 6, key: 'position_name', label: 'Position', class: 'text-center' },
          { mobile: 6, key: 'active', class: 'text-center' },
          { mobile: 7, key: 'updated_at', formatter: this.dateTimeShortFormatter, sortable: true }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  computed: {
    ModalEmployeeTitle () {
      return this.state.editing ? 'Edit Employee' : 'Add Employee'
    }
  },

  watch: {
    'employee.is_approver' (isApprover) {
      if (Number(isApprover) === 0) {
        this.employee.approver_type = null
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async tableEmployeeProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableEmployee.busy = true
      return await SAdminEmployee.get(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search
        })
      ).then(({ data }) => {
        this.tableEmployee.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableEmployee.busy = false
      })
    },

    onCreateEmployee () {
      this.state.editing = false
      this.employee.id = null
      this.employee.email = null
      this.employee.full_name = null
      this.employee.position_name = null
      this.employee.is_approver = 0
      // this.employee.approver_type = null
      this.employee.active = 1
      this.$bvModal.show('modal-employee')
    },

    onEditEmployee (employee) {
      this.state.editing = true
      this.employee.id = employee.id
      this.employee.email = employee.email
      this.employee.full_name = `${employee.first_name} ${employee.last_name}`
      this.employee.position_name = employee.position_name
      this.employee.is_approver = employee.is_approver
      // this.employee.approver_type = employee.approver_type
      this.employee.active = employee.active
      this.$bvModal.show('modal-employee')
    },

    async onValidateEmployee (event) {
      event.preventDefault()
      await this.$refs.formEmployee.validate().then(
        async allFieldsAreValid => {
          if (allFieldsAreValid) {
            this.swalConfirm({
              html: () => {
                if (this.state.editing) {
                  return '<h6>Save your Changes on this Employee?</h6>'
                }
                return '<h6>Create this Employee?</h6>'
              },
              preConfirm: () => {
                this.state.busy = true
                if (this.state.editing) {
                  return this.onPutEmployee()
                }
                return this.onPostEmployee()
              }
            })
          } else {
            this.swalInvalid()
          }
        }
      )
    },

    async onPostEmployee () {
      return new Promise(resolve => {
        SAdminEmployee.post(this.employee).then(
          ({ data: { message } }) => {
            this.$refs.tableEmployee.refresh()
            this.swalSuccess(message).then(() => {
              this.$bvModal.hide('modal-employee')
            })
            resolve(message)
          }
        ).catch(
          ({ message }) => {
            this.$refs.formEmployee.setErrors(message)
            resolve(message)
          }
        ).finally(() => {
          this.state.busy = false
        })
      })
    },

    async onPutEmployee () {
      return new Promise(resolve => {
        SAdminEmployee.put(this.employee).then(
          ({ data }) => {
            const tableEmployeeRow = QUERY.find(this.$refs.tableEmployee.localItems, { id: data.employee.id })
            if (tableEmployeeRow) {
              tableEmployeeRow.email = data.employee.email
              tableEmployeeRow.first_name = data.employee.first_name
              tableEmployeeRow.last_name = data.employee.last_name
              tableEmployeeRow.position_name = data.employee.position_name
              tableEmployeeRow.is_approver = data.employee.is_approver
              // tableEmployeeRow.approver_type = data.employee.approver_type
              tableEmployeeRow.active = data.employee.active
              tableEmployeeRow.updated_at = data.employee.updated_at
            }
            this.swalSuccess(data.message).then(() => {
              this.$bvModal.hide('modal-employee')
            })
            resolve(data)
          }
        ).catch(
          ({ message }) => {
            this.$refs.formEmployee.setErrors(message)
            resolve(message)
          }
        ).finally(() => {
          this.state.busy = false
        })
      })
    }
  }
}
</script>
