<template>
  <b-container
    fluid
  />
</template>
<script>
import { core } from '@/config/pluginInit'
// import { SSharedList } from '@/services'

export default {
  name: 'AdminDashboard',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Dashboard'
    }
  },

  mounted () {
    core.index()
    // SSharedList.getBudgetCenters().then(() => {
    //   // (data)
    // })
  }
}
</script>
