<template>
  <b-container
    fluid
  >

    <b-row class="mb-4">
      <b-col
        cols="12"
      >
        <b-card
          title="Payment Requests"
          sub-title="My Check Releasing"
          class="shadow-sm"
        >
          <b-row>

            <!-- <b-col
              cols="6"
              md="2"
              class="p-2"
            >
              <PaleCard
                label="Not Received Checks"
                variant="warning"
                :count="approvals.pending_check_receiving"
                :link="{ name: 'es.payments.check-receiving', params: { es: 'pending' }}"
                :loading="fetching"
              />
            </b-col> -->
            <!-- <b-col
              cols="6"
              md="2"
              class="p-2"
            >
              <PaleCard
                label="Received Checks"
                variant="success"
                :count="approvals.processed_check_receiving"
                :link="{ name: 'es.payments.check-receiving', params: { es: 'histories' }}"
                :loading="fetching"
              />
            </b-col> -->
            <b-col
              cols="12"
              md="3"
              class="p-2"
            >
              <PaleCard
                label="Not Released Checks"
                variant="warning"
                :count="approvals.pending_check_approval"
                :link="{ name: 'es.payments.check-releasing', params: { es: 'pending' }}"
                :loading="fetching"
              />
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="p-2"
            >
              <PaleCard
                label="Released Checks"
                variant="success"
                :count="approvals.processed_check_approval"
                :link="{ name: 'es.payments.check-releasing', params: { es: 'histories' }}"
                :loading="fetching"
              />
            </b-col>

          </b-row>
        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { SESDashboard } from '@/services'
import PaleCard from '@/components/custom/PaleCard'

export default {
  name: 'EsDashboard',

  middleware: ['auth', 'es'],

  components: {
    PaleCard
  },

  metaInfo: () => ({
    title: 'Dashboard'
  }),

  data () {
    return {
      fetching: false,
      approvals: {
        pending_check_approval: 0,
        processed_check_approval: 0,
        pending_check_receiving: 0,
        processed_check_receiving: 0
      }
    }
  },

  mounted () {
    core.index()
    this.getStatistics()
  },

  methods: {

    async getStatistics () {
      this.fetching = true
      return await SESDashboard.get().then(({ data }) => {
        this.approvals.pending_check_approval = data.pending_check_approval
        this.approvals.processed_check_approval = data.processed_check_approval
        this.approvals.pending_check_receiving = data.pending_check_receiving
        this.approvals.processed_check_receiving = data.processed_check_receiving
        this.fetching = false
      })
    }

  }
}
</script>
