function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/user/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const UserRoutes = [
  {
    path: '/user/dashboard',
    component: view('Dashboard'),
    name: 'user.dashboard',
    authorizedRole: 'user'
  },
  // BUDGETS
  {
    path: '/user/budgets',
    component: view('budgets/Base'),
    name: 'user.budgets',
    authorizedRole: 'user',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Budgets',
          active: true
        }
      ]
    }
  },
  // EXPENSES
  {
    path: '/user/payments/requests/:user?/:status?',
    component: view('payments/requests/Base'),
    name: 'user.payments.requests',
    authorizedRole: 'user',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Payments'
        },
        {
          text: 'Requests',
          active: true
        }
      ]
    }
  },
  // PENDING APPROVALS
  {
    path: '/user/payments/approvals/:approver?',
    component: view('payments/approvals/Base'),
    name: 'user.payments.approvals',
    authorizedRole: 'user',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Payments'
        },
        {
          text: 'Approvals',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/registrations/business-partners',
    component: view('registrations/BusinessPartners'),
    name: 'user.registrations.business-partners',
    authorizedRole: 'ta',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Registrations'
        },
        {
          text: 'Business Partners',
          active: true
        }
      ]
    }
  }
]

export default UserRoutes
