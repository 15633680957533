<template>
  <b-container
    fluid
  >
    <b-row />
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
// import { SBODashboard } from '@/services'

export default {
  name: 'BoDashboard',

  middleware: ['auth', 'bo'],

  metaInfo () {
    return {
      title: 'Dashboard'
    }
  },

  data () {
    return {
      request_pending: 0,
      request_approval: 0
    }
  },

  mounted () {
    core.index()
    this.fetchData()
  },

  methods: {
    async fetchData () {
      // await SBODashboard.get().then(({ data }) => {
      //   this.request_pending = data.request_pending
      //   this.request_approval = data.request_approval
      // })
    }
  }
}
</script>
