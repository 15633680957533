function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/es/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const ESRoutes = [
  {
    path: '/es/dashboard',
    component: view('Dashboard'),
    name: 'es.dashboard',
    authorizedRole: 'es'
  },
  // PENDING APPROVALS
  {
    path: '/es/payments/checks/receiving/:es?',
    component: view('payments/checks/receiving/Base'),
    name: 'es.payments.check-receiving',
    authorizedRole: 'es',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Check Receiving',
          active: true
        }
      ]
    }
  },
  {
    path: '/es/payments/checks/releasing/:es?',
    component: view('payments/checks/releasing/Base'),
    name: 'es.payments.check-releasing',
    authorizedRole: 'es',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Check Releasing',
          active: true
        }
      ]
    }
  }
]

export default ESRoutes
