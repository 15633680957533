<template>
  <b-container
    class="mt-2"
    fluid
  >
    <!-- Filters -->
    <b-row>

      <b-col
        cols="12"
        md="8"
        class="px-1"
        order="3"
        order-md="1"
      >
        <b-form-group
          label="Search"
          label-for="filter_history_search"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filter_history_search"
            v-model="tableHistories.filter.search"
            type="text"
            placeholder="search here"
            debounce="1000"
            autocomplete="off"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="6"
        md="2"
        class="px-1"
        order="2"
      >
        <b-form-group
          label="Printed From"
          label-for="filter_date_from"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filter_date_from"
            v-model="tableHistories.filter.date_from"
            type="date"
            debounce="1000"
            :max="tableHistories.filter.date_to"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="6"
        md="2"
        class="px-1"
        order="2"
      >
        <b-form-group
          label="Printed To"
          label-for="filter_date_to"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filter_date_to"
            v-model="tableHistories.filter.date_to"
            type="date"
            debounce="1000"
            :min="tableHistories.filter.date_from"
          />
        </b-form-group>
      </b-col>

    </b-row>
    <!-- Table -->
    <b-row
      v-if="currentTabIndex === 1"
      class="mt-1"
    >
      <b-col
        cols="12"
        class="px-1"
      >
        <b-table
          ref="tableHistories"
          small
          hover
          striped
          bordered
          responsive
          show-empty
          :stacked="isMobile()"
          :per-page="tableHistories.perPage"
          :current-page="tableHistories.currentPage"
          :items="tableHistoriesProvider"
          :fields="tableHistories.fields"
          :sort-by.sync="tableHistories.sortBy"
          :sort-desc.sync="tableHistories.sortDesc"
          :sort-direction="tableHistories.sortDirection"
          :filter="tableHistories.filter"
          :filter-included-fields="tableHistories.filterOn"
          :busy="tableHistories.busy"
        >
          <template #cell(index)="row">
            {{ tableHistories.currentPage * tableHistories.perPage - tableHistories.perPage + (row.index + 1) }}
          </template>

          <template #cell()="row">
            <div class="text-md-nowrap">
              {{ row.value }}
            </div>
          </template>

          <template #cell(approvable.voucher.voucher_number)="row">
            <div class="text-md-nowrap">
              <b>{{ row.value }}</b>
            </div>
          </template>

          <template #cell(approvable.amount)="row">
            <div
              class="text-md-nowrap text-md-right"
              style="min-width: 100px;"
            >
              {{ row.value }}
            </div>
          </template>

          <template #cell(action)="row">
            <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
              <b-button
                size="sm"
                :disabled="busy"
                @click="onView(row.item)"
              >
                View
              </b-button>
            </div>
          </template>

          <template #table-busy>
            <div class="text-center py-5">
              <b-icon
                icon="stopwatch"
                font-scale="5"
                animation="cylon"
              />
              <p class="h3 py-2">
                <strong>Loading . . .</strong>
              </p>
            </div>
          </template>

        </b-table>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="mt-1 mb-2 px-1"
      >
        <b-select
          v-model="tableHistories.perPage"
          :options="tableHistories.pageOptions"
          class="w-100 w-md-25"
        />
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
      >
        <b-pagination
          v-model="tableHistories.currentPage"
          pills
          last-number
          first-number
          prev-text="Prev"
          next-text="Next"
          aria-controls="table"
          :total-rows="tableHistories.totalRows"
          :per-page="tableHistories.perPage"
        />
      </b-col>

    </b-row>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { SDADocumentPrintingHistory } from '@/services'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'
import RevolvingFunds from '@/mixins/printing/revolving-funds'

export default {

  name: 'DaPaymentRequestsDocumentPrintingHistories',

  mixins: [formatter, misc, RevolvingFunds],

  props: {
    busy: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    currentTabIndex: {
      type: Number,
      default: () => {
        return 1
      }
    }
  },

  data () {
    return {
      exporter: {
        busy: false,
        pdfData: null
      },
      tableHistories: {
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null,
          date_from: null,
          date_to: null
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 13, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'approvable.created_at', label: 'Requested At', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'approvable.voucher.voucher_number', label: 'Voucher Number', class: 'text-center', sortable: true },
          { mobile: 3, key: 'approvable.document_number', label: 'Document Number', class: 'text-center' },
          { mobile: 4, key: 'approvable.bp_name', label: 'Business Partner' },
          { mobile: 5, key: 'approvable.customer_name', label: 'Cash Advance' },
          { mobile: 6, key: 'approvable.mop', label: 'Mode of Payment' },
          { mobile: 7, key: 'approvable.amount', label: 'Amount', formatter: this.numberFormatter },
          { mobile: 8, key: 'approvable.bp_transaction_type', label: 'Transaction Type' },
          { mobile: 9, key: 'approvable.requestor', label: 'Requested By', formatter: this.userCurrentRequestor },
          { mobile: 10, key: 'approvable.status', label: 'Request Status' },
          { mobile: 11, key: 'status_at', label: 'Printed At', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 12, key: 'approver_name', label: 'Printed By' }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async tableHistoriesProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableHistories.busy = true
      return await SDADocumentPrintingHistory.get(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search,
          filter_date_from: filter.date_from,
          filter_date_to: filter.date_to
        })
      ).then(({ data }) => {
        this.tableHistories.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableHistories.busy = false
      })
    },

    onView (paymentRequest) {
      this.$emit('onView', paymentRequest.approvable, paymentRequest, false)
    },

    tableHistoriesRefresh () {
      if (this.currentTabIndex === 1) {
        this.$refs.tableHistories.refresh()
      }
    }
  }

}
</script>
