import axios from 'axios'
import { SError } from './error.service'
import formatter from '@/mixins/formatter'

const ApiService = {

  async get (endpoint, config = {}) {
    return new Promise((resolve, reject) => {
      axios.get(endpoint, config)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(SError.translate(error))
        })
    })
  },

  async getObject (endpoint, payload, config = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`${endpoint}?${formatter.methods.objectToUrl(payload)}`, config)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(SError.translate(error))
        })
    })
  },

  async post (endpoint, payload, config = {}) {
    return new Promise((resolve, reject) => {
      axios.post(endpoint, payload, config)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(SError.translate(error))
        })
    })
  },

  async put (endpoint, payload, config = {}) {
    return new Promise((resolve, reject) => {
      axios.put(endpoint, payload, config)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(SError.translate(error))
        })
    })
  },

  async delete (endpoint, config = {}) {
    return new Promise((resolve, reject) => {
      axios.delete(endpoint, config)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(SError.translate(error))
        })
    })
  }
}

export default ApiService
