import ApiService from '@/services/core/api.service'

const PendingService = {
  async get (query = '') {
    return ApiService.get(`reporting-analysis-manager/payment-approvals?${query}`)
  },

  async show (id) {
    return ApiService.get(`reporting-analysis-manager/payment-approvals/${id}`)
  },

  async post (payload) {
    return ApiService.post('reporting-analysis-manager/payment-approvals', payload)
  },

  async put (payload) {
    return ApiService.put(`reporting-analysis-manager/payment-approvals/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`reporting-analysis-manager/payment-approvals/${payload.id}`)
  }
}

export default PendingService
