const DHeadMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'audit.dashboard'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'fat fa-objects-column',
    active_icon: 'fad fa-objects-column'
  },
  {
    title: 'Disbursements',
    is_heading: true,
    is_active: false,
    link: {
      name: 'audit.disbursements'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'Payment Vouchers',
    is_heading: false,
    is_active: false,
    link: {
      name: 'audit.payments.vouchers'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'fat fa-money-check',
    active_icon: 'fad fa-money-check'
  },
  {
    title: 'Budgets',
    is_heading: true,
    is_active: false,
    link: {
      name: 'audit.budgets'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'Item Centers',
    is_heading: false,
    is_active: false,
    link: {
      name: 'audit.item-centers'
    },
    is_icon_class: true,
    icon: 'fat fa-briefcase-blank',
    active_icon: 'fad fa-briefcase-blank'
  }
]

export default DHeadMenu
