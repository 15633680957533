<template>
  <b-container
    v-if="currentTabIndex === 0"
    class="mt-2"
    fluid
  >
    <b-row>

      <b-col
        cols="12"
        md="10"
        class="px-1"
        order="2"
        order-md="1"
      >
        <b-form-group
          label="Search"
          label-for="filterBusinessPartnerSearch"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filterBusinessPartnerSearch"
            v-model="tableBusinessPartnerDraft.filter.search"
            type="text"
            debounce="1000"
            autocomplete="off"
            placeholder="search here"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="2"
        class="px-1"
        order="1"
        order-md="2"
      >
        <b-form-group
          label="Partner Type"
          label-for="filterPartnerType"
          label-class="font-weight-bolder"
        >
          <b-select
            id="filterPartnerType"
            v-model="tableBusinessPartnerDraft.filter.partner_type"
            debounce="1000"
            :options="filterPartnerTypes"
            :disabled="state.busy || tableBusinessPartnerDraft.fetching.options"
          />
        </b-form-group>
      </b-col>

    </b-row>

    <b-row class="mt-1">
      <b-col
        cols="12"
        class="px-1"
      >
        <b-table
          ref="tableBusinessPartnerDraft"
          hover
          small
          striped
          bordered
          responsive
          show-empty
          :items="tableBusinessPartnerDraftProvider"
          :busy="tableBusinessPartnerDraft.busy"
          :filter="tableBusinessPartnerDraft.filter"
          :fields="tableBusinessPartnerDraft.fields"
          :stacked="tableBusinessPartnerDraft.stacked"
          :sort-by.sync="tableBusinessPartnerDraft.sortBy"
          :sort-desc.sync="tableBusinessPartnerDraft.sortDesc"
          :sort-direction="tableBusinessPartnerDraft.sortDirection"
          :filter-included-fields="tableBusinessPartnerDraft.filterOn"
          :current-page="tableBusinessPartnerDraft.currentPage"
          :per-page="tableBusinessPartnerDraft.perPage"
        >
          <template #cell(index)="row">
            {{ tableBusinessPartnerDraft.currentPage * tableBusinessPartnerDraft.perPage - tableBusinessPartnerDraft.perPage + (row.index + 1) }}
          </template>

          <template #cell(active)="row">
            {{ Number(row.value) === 1 ? 'Yes' : 'No' }}
          </template>

          <template #cell(user)="{ value }">
            <div class="text-md-nowrap">
              {{ value?.first_name }} {{ value?.last_name }}
            </div>
          </template>

          <template #cell()="row">
            <div class="text-md-nowrap">
              {{ row.value }}
            </div>
          </template>

          <template #cell(action)="row">
            <div class="text-md-nowrap d-flex justify-content-center">
              <b-button
                size="sm"
                variant="success"
                @click="onProcessBusinessPartnerDraft(row.item)"
              >
                Process
              </b-button>
            </div>
          </template>

          <template #table-busy>
            <div class="text-center py-5">
              <b-icon
                icon="stopwatch"
                font-scale="5"
                animation="cylon"
              />
              <p class="h3 py-2">
                <strong>Loading . . .</strong>
              </p>
            </div>
          </template>

        </b-table>
      </b-col>
      <b-col
        cols="12"
        md="6"
        class="mt-1 mb-2 px-1"
      >
        <b-select
          v-model="tableBusinessPartnerDraft.perPage"
          :options="tableBusinessPartnerDraft.pageOptions"
          class="w-100 w-md-25"
        />
      </b-col>
      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
      >
        <b-pagination
          v-model="tableBusinessPartnerDraft.currentPage"
          pills
          last-number
          first-number
          prev-text="Prev"
          next-text="Next"
          aria-controls="table"
          :total-rows="tableBusinessPartnerDraft.totalRows"
          :per-page="tableBusinessPartnerDraft.perPage"
        />
      </b-col>
    </b-row>

    <b-modal
      id="modal-pending-business-partner"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      aria-hidden="false"
      no-close-on-backdrop
      modal-class="fullscreen"
      title="Process Business Partner"
    >

      <b-container
        class="h-100 py-1 px-1"
        fluid
      >

        <b-tabs
          active-nav-item-class="font-weight-bold text-uppercase text-success"
          content-class="scrollable"
        >
          <b-tab class="p-2">
            <template #title>
              <div class="text-md-nowrap">
                <i
                  :class="['ri-file-text-line h4', {
                    'text-danger fat fa-shake': vTabBusinessPartner()
                  }]"
                />
                <b
                  :class="['is-tab-active ml-1', {
                    'text-danger': vTabBusinessPartner()
                  }]"
                >
                  Details
                </b>
              </div>
            </template>

            <ValidationObserver
              ref="formBusinessPartner"
            >
              <form
                role="form"
                novalidate
                @submit.prevent
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-row>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="partner code"
                          vid="partner_code"
                          rules="max:30"
                        >
                          <b-form-group
                            label="Partner Code"
                            label-for="partner_code"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="partner_code"
                              v-model="businessPartnerDraft.partner_code"
                              type="text"
                              maxlength="30"
                              autocomplete="off"
                              placeholder="enter partner code here"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="partner name"
                          vid="partner_name"
                          rules="required|max:255"
                        >
                          <b-form-group
                            label="Partner Name"
                            label-for="partner_name"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-textarea
                              id="partner_name"
                              v-model="businessPartnerDraft.partner_name"
                              rows="3"
                              max-rows="6"
                              maxlength="255"
                              autocomplete="off"
                              placeholder="enter partner name here"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy"
                              @keydown="onPreventEnterOnKeyboard"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="tin"
                          vid="tin"
                          rules="required|min:18|max:18"
                        >
                          <b-form-group
                            label="TIN"
                            label-for="partner_tin"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="partner_tin"
                              v-model="businessPartnerDraft.tin"
                              v-mask="'###-###-###-###NNN'"
                              type="text"
                              maxlength="18"
                              autocomplete="off"
                              placeholder="enter tin here"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="partner type"
                          vid="partner_type"
                          rules="required"
                        >
                          <b-form-group
                            label="Partner Type"
                            label-for="partner_type"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-form-select
                              id="partner_type"
                              v-model="businessPartnerDraft.partner_type"
                              :options="options.partnerTypes"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy || fetching.options"
                            >
                              <template #first>
                                <b-form-select-option
                                  :value="null"
                                  disabled
                                >
                                  -- select partner type here --
                                </b-form-select-option>
                              </template>
                            </b-form-select>
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        v-if="businessPartnerDraft.partner_type == 'Vendor'"
                        cols="12"
                      >

                        <ValidationProvider
                          #default="{ errors }"
                          name="vendor type"
                          vid="vendor_type"
                          rules="required_if:partner_type,Vendor"
                        >
                          <b-form-group
                            label="Vendor Type"
                            label-for="vendor_type"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-form-select
                              id="vendor_type"
                              v-model="businessPartnerDraft.vendor_type"
                              :options="options.vendorTypes"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy || fetching.options"
                            >
                              <template #first>
                                <b-form-select-option
                                  :value="null"
                                  disabled
                                >
                                  -- select vendor type here --
                                </b-form-select-option>
                              </template>
                            </b-form-select>
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        v-else
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="customer type"
                          vid="customer_type"
                          rules="required_if:partner_type,Customer"
                        >
                          <b-form-group
                            label="Customer Type"
                            label-for="customer_type"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-form-select
                              id="customer_type"
                              v-model="businessPartnerDraft.customer_type"
                              :options="options.customerTypes"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy || fetching.options"
                            >
                              <template #first>
                                <b-form-select-option
                                  :value="null"
                                  disabled
                                >
                                  -- select customer type here --
                                </b-form-select-option>
                              </template>
                            </b-form-select>
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="business type"
                          vid="business_type"
                          rules="required_if:partner_type,Vendor"
                        >
                          <b-form-group
                            label="Business Type"
                            label-for="business_type"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-form-select
                              id="business_type"
                              v-model="businessPartnerDraft.business_type"
                              :options="options.businessTypes"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy || fetching.options || businessPartnerDraft.partner_type !== 'Vendor'"
                            >
                              <template #first>
                                <b-form-select-option
                                  :value="null"
                                  disabled
                                >
                                  -- select business type here --
                                </b-form-select-option>
                              </template>
                            </b-form-select>
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="trade name"
                          vid="trade_name"
                          rules="required_if:partner_type,Vendor|max:255"
                        >
                          <b-form-group
                            label="Trade Name"
                            label-for="trade_name"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-textarea
                              id="trade_name"
                              v-model="businessPartnerDraft.trade_name"
                              rows="3"
                              max-rows="6"
                              maxlength="255"
                              autocomplete="off"
                              placeholder="enter trade name here"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy || businessPartnerDraft.partner_type !== 'Vendor'"
                              @keydown="onPreventEnterOnKeyboard"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="corporation name"
                          vid="corporation_name"
                          rules="required_if:business_type,Corporation,Cooperative,Government|max:255"
                        >
                          <b-form-group
                            :label="(`${businessPartnerDraft.business_type || 'Business Type'} Name`)"
                            label-for="corporation_name"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-textarea
                              id="corporation_name"
                              v-model="businessPartnerDraft.corporation_name"
                              rows="3"
                              max-rows="6"
                              maxlength="255"
                              autocomplete="off"
                              :placeholder="(`enter ${businessPartnerDraft.business_type?.toLowerCase() || 'business type'} name here`)"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy || businessPartnerDraft.partner_type !== 'Vendor' || businessPartnerDraft.business_type === null"
                              @keydown="onPreventEnterOnKeyboard"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                    </b-row>
                  </b-col>

                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-row>

                      <b-col
                        cols="12"
                      >
                        <b-row>
                          <b-col
                            cols="12"
                          >
                            <b-form-group
                              label="Owner / Authorized Representative"
                              label-cols-md="12"
                              label-class="font-weight-bolder"
                              class="mb-2"
                            />
                          </b-col>
                          <b-col
                            cols="12"
                          >
                            <ValidationProvider
                              #default="{ errors }"
                              name="first name"
                              vid="owner_first_name"
                              rules="required_if:business_type,Sole-Proprietorship|max:50"
                            >
                              <b-form-group
                                label="First Name"
                                label-for="owner_first_name"
                                label-cols-md="4"
                                label-class="font-weight-bolder"
                                class="mb-2"
                              >
                                <b-input
                                  id="owner_first_name"
                                  v-model="businessPartnerDraft.owner_first_name"
                                  type="text"
                                  maxlength="50"
                                  autocomplete="off"
                                  placeholder="enter first name here"
                                  :state="errors.length > 0 ? false : null"
                                  :disabled="state.busy || businessPartnerDraft.partner_type !== 'Vendor' || businessPartnerDraft.business_type !== 'Sole-Proprietorship'"
                                />
                                <div
                                  v-if="errors.length > 0"
                                  class="invalid-feedback"
                                >
                                  <span>{{ errors[0] }}</span>
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>

                          <b-col
                            cols="12"
                          >
                            <ValidationProvider
                              #default="{ errors }"
                              name="contact person"
                              vid="owner_middle_name"
                              rules="max:100"
                            >
                              <b-form-group
                                label="Middle Name"
                                label-for="owner_middle_name"
                                label-cols-md="4"
                                label-class="font-weight-bolder"
                                class="mb-2"
                              >
                                <b-input
                                  id="owner_middle_name"
                                  v-model="businessPartnerDraft.owner_middle_name"
                                  type="text"
                                  maxlength="100"
                                  autocomplete="off"
                                  placeholder="enter middle name here"
                                  :state="errors.length > 0 ? false : null"
                                  :disabled="state.busy || businessPartnerDraft.partner_type !== 'Vendor' || businessPartnerDraft.business_type !== 'Sole-Proprietorship'"
                                />
                                <div
                                  v-if="errors.length > 0"
                                  class="invalid-feedback"
                                >
                                  <span>{{ errors[0] }}</span>
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>

                          <b-col
                            cols="12"
                          >
                            <ValidationProvider
                              #default="{ errors }"
                              name="surname"
                              vid="owner_last_name"
                              rules="required_if:business_type,Sole-Proprietorship|max:100"
                            >
                              <b-form-group
                                label="Surname"
                                label-for="owner_last_name"
                                label-cols-md="4"
                                label-class="font-weight-bolder"
                                class="mb-2"
                              >
                                <b-input
                                  id="owner_last_name"
                                  v-model="businessPartnerDraft.owner_last_name"
                                  type="text"
                                  maxlength="100"
                                  autocomplete="off"
                                  placeholder="enter surname here"
                                  :state="errors.length > 0 ? false : null"
                                  :disabled="state.busy || businessPartnerDraft.partner_type !== 'Vendor' || businessPartnerDraft.business_type !== 'Sole-Proprietorship'"
                                />
                                <div
                                  v-if="errors.length > 0"
                                  class="invalid-feedback"
                                >
                                  <span>{{ errors[0] }}</span>
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>

                        </b-row>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="telephone number"
                          vid="telephone_number"
                          rules="max:30"
                        >
                          <b-form-group
                            label="Tel. Number"
                            label-for="telephone_number"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="telephone_number"
                              v-model="businessPartnerDraft.telephone_number"
                              type="text"
                              maxlength="30"
                              autocomplete="off"
                              placeholder="enter telephone number here"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="mobile number"
                          vid="mobile_number"
                          rules="min:11|max:11"
                        >
                          <b-form-group
                            label="Mobile Number"
                            label-for="mobile_number"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="mobile_number"
                              v-model="businessPartnerDraft.mobile_number"
                              type="text"
                              maxlength="11"
                              autocomplete="off"
                              placeholder="enter mobile number here"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="email"
                          vid="email"
                          rules="email|max:50"
                        >
                          <b-form-group
                            label="Email"
                            label-for="email"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="email"
                              v-model="businessPartnerDraft.email"
                              type="text"
                              maxlength="50"
                              autocomplete="off"
                              placeholder="enter email here"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="contact person"
                          vid="contact_person"
                          rules="max:100"
                        >
                          <b-form-group
                            label="Contact Person"
                            label-for="contact_person"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="contact_person"
                              v-model="businessPartnerDraft.contact_person"
                              type="text"
                              maxlength="100"
                              autocomplete="off"
                              placeholder="enter contact person here"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="address"
                          vid="address"
                          rules="max:255"
                        >
                          <b-form-group
                            label="Address"
                            label-for="address"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-textarea
                              id="address"
                              v-model="businessPartnerDraft.address"
                              rows="3"
                              max-rows="6"
                              maxlength="255"
                              autocomplete="off"
                              placeholder="enter address here"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                    </b-row>
                  </b-col>

                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-row>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="tax status"
                          vid="tax_status"
                          rules="required"
                        >
                          <b-form-group
                            label="Tax Status"
                            label-for="tax_status"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-form-select
                              id="tax_status"
                              v-model="businessPartnerDraft.tax_status"
                              :options="options.taxStatuses"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy || fetching.options"
                            >
                              <template #first>
                                <b-form-select-option
                                  :value="null"
                                  disabled
                                >
                                  -- select tax status here --
                                </b-form-select-option>
                              </template>
                            </b-form-select>
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="business tax"
                          vid="business_tax"
                          rules="required"
                        >
                          <b-form-group
                            label="Business Tax"
                            label-for="business_tax"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-form-select
                              id="business_tax"
                              v-model="businessPartnerDraft.business_tax"
                              :options="options.businessTaxes"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy || fetching.options"
                            >
                              <template #first>
                                <b-form-select-option
                                  :value="null"
                                  disabled
                                >
                                  -- select business tax here --
                                </b-form-select-option>
                              </template>
                            </b-form-select>
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="tax code"
                          vid="tax_code"
                          rules=""
                        >
                          <b-form-group
                            label="Tax Code"
                            label-for="tax_code"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-form-select
                              id="tax_code"
                              v-model="businessPartnerDraft.tax_code"
                              :options="taxCodes"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy || fetching.options"
                            >
                              <template #first>
                                <b-form-select-option
                                  :value="null"
                                  disabled
                                >
                                  -- select tax code here --
                                </b-form-select-option>
                              </template>
                            </b-form-select>
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="document type"
                          vid="document_type"
                          rules=""
                        >
                          <b-form-group
                            label="Document Type"
                            label-for="document_type"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-form-select
                              id="document_type"
                              v-model="businessPartnerDraft.document_type"
                              :options="documentTypes"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy || fetching.options"
                            >
                              <template #first>
                                <b-form-select-option
                                  :value="null"
                                  disabled
                                >
                                  -- select document type here --
                                </b-form-select-option>
                              </template>
                            </b-form-select>
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="transaction type"
                          vid="transaction_type"
                          rules="required"
                        >
                          <b-form-group
                            label="Transaction Type"
                            label-for="transaction_type"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-form-select
                              id="transaction_type"
                              v-model="businessPartnerDraft.transaction_type"
                              :options="options.transactionTypes"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy || fetching.options"
                            >
                              <template #first>
                                <b-form-select-option
                                  :value="null"
                                  disabled
                                >
                                  -- select transaction type here --
                                </b-form-select-option>
                              </template>
                            </b-form-select>
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          name="payment nature"
                          vid="payment_nature"
                        >
                          <b-form-group
                            label="Payment Nature"
                            label-for="payment_nature"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <v-select
                              v-model="selected.paymentNature"
                              input-id="payment_nature"
                              type="text"
                              append-to-body
                              label="nature_name"
                              placeholder="search payment nature here"
                              :options="options.paymentNatures"
                              :calculate-position="calculateDropPosition"
                              :class="errors.length > 0 ? 'is-invalid' : null"
                              :disabled="state.busy || fetching.options"
                              :loading="fetching.options"
                            >
                              <template #no-options="">
                                no available payment nature
                              </template>
                            </v-select>
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              <span>{{ errors[0] }}</span>
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                    </b-row>
                  </b-col>
                </b-row>

              </form>
            </ValidationObserver>

          </b-tab>

          <b-tab class="p-2">
            <template #title>
              <div class="text-md-nowrap">
                <i class="ri-attachment-line h4" />
                <b>Attachments</b>
              </div>
            </template>

            <BPAttachment
              :file-path="businessPartnerDraft.attachment_path"
              :file-list="businessPartnerDraft.attachments"
              :show-uploader="false"
            />
          </b-tab>
        </b-tabs>
      </b-container>

      <template #modal-footer="{cancel}">
        <div class="col-12 d-flex justify-content-between px-0 pb-0 mb-0">
          <div>
            <b-button
              class="mr-1"
              variant="success"
              :disabled="state.busy"
              @click="$event => onValidateBusinessPartnerDraft($event, 'Approved')"
            >
              Approve
            </b-button>
            <b-button
              class="mr-1"
              variant="outline-danger"
              :disabled="state.busy"
              @click="$event => onValidateBusinessPartnerDraft($event, 'Disapproved')"
            >
              Disapprove
            </b-button>
          </div>
          <div />
          <div>
            <b-button
              variant="outline-dark"
              :disabled="state.busy"
              @click="cancel()"
            >
              Close <span class="d-none d-md-inline-block">Window</span>
            </b-button>
          </div>
        </div>
      </template>

    </b-modal>

  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { STABusinessPartnerDraft, SSharedList } from '@/services'
import BPAttachment from '@/components/custom/BPAttachment'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'

export default {
  name: 'TaMaintenancesBusinessPartners',

  middleware: ['auth', 'ta'],

  components: {
    BPAttachment
  },

  mixins: [formatter, misc],

  metaInfo: () => ({
    title: 'Business Partners'
  }),

  props: {
    currentTabIndex: {
      type: Number,
      default: () => {
        return 1
      }
    }
  },

  data () {
    return {
      state: {
        busy: false
      },
      fetching: {
        options: false
      },
      options: {
        partnerTypes: [],
        vendorTypes: [],
        businessTypes: [],
        customerTypes: [],
        taxStatuses: [],
        businessTaxes: [],
        taxCodes: [],
        documentTypes: [],
        transactionTypes: [],
        paymentNatures: []
      },
      selected: {
        paymentNature: null
      },
      businessPartnerDraft: {
        id: null,
        draft: null,
        partner_code: null,
        partner_name: null,
        tin: null,
        partner_type: null,
        vendor_type: null,
        business_type: null,
        trade_name: null,
        corporation_name: null,
        owner_first_name: null,
        owner_middle_name: null,
        owner_last_name: null,
        customer_type: null,
        telephone_number: null,
        mobile_number: null,
        email: null,
        address: null,
        contact_person: null,
        tax_status: null,
        business_tax: null,
        tax_code: null,
        document_type: null,
        transaction_type: null,
        payment_nature: null,
        attachment_path: null,
        attachments: [],
        approver_remarks: null,
        active: 1
      },
      tableBusinessPartnerDraft: {
        busy: false,
        fetching: {
          options: false
        },
        options: {
          partnerTypes: []
        },
        filter: {
          search: null,
          partner_type: 'All'
        },
        filterOn: [],
        stacked: this.isMobile(),
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 11, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'created_at', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'user', label: 'Submitted By' },
          { mobile: 3, key: 'tin' },
          { mobile: 4, key: 'partner_type' },
          { mobile: 5, key: 'vendor_type' },
          { mobile: 6, key: 'customer_type' },
          { mobile: 7, key: 'mobile_number', label: 'Mobile' },
          { mobile: 8, key: 'email' },
          { mobile: 9, key: 'active', class: 'text-center' },
          { mobile: 10, key: 'updated_at', formatter: this.dateTimeShortFormatter, sortable: true }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  computed: {

    filterPartnerTypes () {
      return [{ text: 'All', value: 'All' }].concat(this.tableBusinessPartnerDraft.options.partnerTypes)
    },

    taxCodes () {
      return [].concat(this.options.taxCodes)
    },

    documentTypes () {
      return [].concat(this.options.documentTypes)
    },

    paymentNatures () {
      return [].concat(this.options.paymentNatures)
    }
  },

  watch: {

    'businessPartnerDraft.partner_name' (partnerName) {
      if (partnerName?.search(/\n/g)) {
        this.businessPartnerDraft.partner_name = partnerName.replace(/\n/g, '')
      }
    },

    'businessPartnerDraft.partner_type' (partnerType) {
      if (partnerType) {
        if (partnerType === 'Vendor') {
          this.businessPartnerDraft.customer_type = null
          this.businessPartnerDraft.vendor_type = null
        }

        if (partnerType === 'Customer') {
          this.businessPartnerDraft.business_type = null
          this.businessPartnerDraft.trade_name = null
          this.businessPartnerDraft.corporation_name = null
          this.businessPartnerDraft.vendor_type = null
        }
      } else {
        this.businessPartnerDraft.business_type = null
        this.businessPartnerDraft.customer_type = null
        this.businessPartnerDraft.vendor_type = null
        this.businessPartnerDraft.trade_name = null
        this.businessPartnerDraft.corporation_name = null
        this.businessPartnerDraft.owner_first_name = null
        this.businessPartnerDraft.owner_middle_name = null
        this.businessPartnerDraft.owner_last_name = null
      }
    },

    'businessPartnerDraft.business_type' (businessType) {
      if (businessType !== 'Sole-Proprietorship') {
        this.businessPartnerDraft.owner_first_name = null
        this.businessPartnerDraft.owner_middle_name = null
        this.businessPartnerDraft.owner_last_name = null
      }
    },

    'selected.paymentNature' (paymentNature) {
      this.businessPartnerDraft.payment_nature = paymentNature?.id || null
    }
  },

  mounted () {
    core.index()
    this.getFilterOptions()
  },

  methods: {

    vTabBusinessPartner () {
      return this.$refs.formBusinessPartner && Object.keys(this.$refs.formBusinessPartner.errors).find(
        key => this.$refs.formBusinessPartner.errors[key]?.length > 0
      )
    },

    onPreventEnterOnKeyboard (event) {
      if (event.keyCode === 13) {
        event.preventDefault()
      }
    },

    // BUSINESS PARTNER
    async tableBusinessPartnerDraftProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableBusinessPartnerDraft.busy = true
      const filters = this.objectToUrl({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_text: filter.search,
        partner_type: filter.partner_type
      })
      return await STABusinessPartnerDraft.get(filters).then(({ data }) => {
        this.tableBusinessPartnerDraft.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableBusinessPartnerDraft.busy = false
      })
    },

    async getFilterOptions () {
      this.tableBusinessPartnerDraft.fetching.options = true
      return new Promise(resolve => {
        const payload = this.toArrayListParam([
          'partner_types'
        ])

        SSharedList.getCustomList(payload).then(({ data }) => {
          this.tableBusinessPartnerDraft.options.partnerTypes = data.partner_types
        }).catch(() => {
          this.tableBusinessPartnerDraft.options.partnerTypes = []
        }).finally(resolve)
      }).finally(() => {
        this.tableBusinessPartnerDraft.fetching.options = false
      })
    },

    async getOptions () {
      this.fetching.options = true
      return new Promise(resolve => {
        const payload = this.toArrayListParam([
          'partner_types',
          'vendor_types',
          'business_types',
          'customer_types',
          'tax_statuses',
          'business_taxes',
          'tax_codes',
          'document_types',
          'transaction_types',
          'payment_natures'
        ])

        SSharedList.getCustomList(payload).then(({ data }) => {
          this.options.partnerTypes = data.partner_types
          this.options.vendorTypes = data.vendor_types
          this.options.businessTypes = data.business_types
          this.options.customerTypes = data.customer_types
          this.options.taxStatuses = data.tax_statuses
          this.options.businessTaxes = data.business_taxes
          this.options.taxCodes = data.tax_codes.map(
            taxCode => ({
              text: `${taxCode.code} - ${taxCode.description}`,
              value: taxCode.id
            })
          )
          this.options.documentTypes = data.document_types.map(
            documentType => ({
              text: documentType.document_type_name,
              value: documentType.id
            })
          )
          this.options.transactionTypes = data.transaction_types
          this.options.paymentNatures = data.payment_natures
        }).catch(() => {
          this.options.partnerTypes = []
          this.options.vendorTypes = []
          this.options.businessTypes = []
          this.options.customerTypes = []
          this.options.taxStatuses = []
          this.options.businessTaxes = []
          this.options.taxCodes = []
          this.options.documentTypes = []
          this.options.transactionTypes = []
          this.options.paymentNatures = []
        }).finally(resolve)
      }).finally(() => {
        this.fetching.options = false
      })
    },

    onProcessBusinessPartnerDraft (businessPartnerDraft) {
      this.businessPartnerDraft.id = businessPartnerDraft.id
      this.businessPartnerDraft.draft = businessPartnerDraft.id
      this.businessPartnerDraft.partner_code = null
      this.businessPartnerDraft.partner_name = businessPartnerDraft.partner_name
      this.businessPartnerDraft.tin = businessPartnerDraft.tin
      this.businessPartnerDraft.trade_name = businessPartnerDraft.trade_name
      this.businessPartnerDraft.corporation_name = businessPartnerDraft.corporation_name
      this.businessPartnerDraft.owner_first_name = businessPartnerDraft.owner_first_name
      this.businessPartnerDraft.owner_middle_name = businessPartnerDraft.owner_middle_name
      this.businessPartnerDraft.owner_last_name = businessPartnerDraft.owner_last_name
      this.businessPartnerDraft.telephone_number = businessPartnerDraft.telephone_number
      this.businessPartnerDraft.mobile_number = businessPartnerDraft.mobile_number
      this.businessPartnerDraft.email = businessPartnerDraft.email
      this.businessPartnerDraft.address = businessPartnerDraft.address
      this.businessPartnerDraft.contact_person = businessPartnerDraft.contact_person
      this.businessPartnerDraft.tax_status = null
      this.businessPartnerDraft.business_tax = businessPartnerDraft.business_tax
      this.businessPartnerDraft.tax_code = null
      this.businessPartnerDraft.document_type = null
      this.businessPartnerDraft.transaction_type = null
      this.selected.paymentNature = null
      this.businessPartnerDraft.attachment_path = businessPartnerDraft.attachment_path
      this.businessPartnerDraft.attachments = businessPartnerDraft.attachments
      this.businessPartnerDraft.active = businessPartnerDraft.active
      this.businessPartnerDraft.approver_remarks = businessPartnerDraft.approver_remarks

      this.getOptions().finally(() => {
        this.businessPartnerDraft.partner_type = businessPartnerDraft.partner_type
        this.businessPartnerDraft.vendor_type = null
        this.businessPartnerDraft.business_type = businessPartnerDraft.business_type
        this.businessPartnerDraft.customer_type = businessPartnerDraft.customer_type
      })

      this.$bvModal.show('modal-pending-business-partner')
    },

    onValidateBusinessPartnerDraft (event, status = 'Disapproved') {
      const isDisapprove = status === 'Disapproved'

      if (!isDisapprove) {
        this.$refs.formBusinessPartner.validate().then(
          allFieldsAreValid => {
            if (!allFieldsAreValid) {
              return this.swalInvalid()
            }
          }
        )
      }

      this.swalConfirmWithRemarks({
        html: `<h6>${event.target.innerText} this new Business Partner?</h6>`,
        confirmButtonText: event.target.innerText,
        remarksRequired: isDisapprove,
        customClass: {
          confirmButton: `btn btn-sm btn-${isDisapprove ? 'danger' : 'success'}`
        },
        preConfirm: approverRemarks => {
          this.state.busy = true
          this.businessPartnerDraft.status = status
          this.businessPartnerDraft.approver_remarks = approverRemarks

          if (isDisapprove) {
            return this.onPutBusinessPartnerDraft()
          }

          return this.onPostBusinessPartnerDraft()
        }
      })
    },

    async onPostBusinessPartnerDraft () {
      return await STABusinessPartnerDraft.post(this.businessPartnerDraft).then(
        ({ data: { message } }) => {
          this.$refs.tableBusinessPartnerDraft.refresh()
          this.swalSuccess(message).then(() => {
            this.$bvModal.hide('modal-pending-business-partner')
          })
        }
      ).catch(({ message }) => {
        this.$refs.formBusinessPartner.setErrors(message)
      }).finally(() => {
        this.state.busy = false
      })
    },

    async onPutBusinessPartnerDraft () {
      return await STABusinessPartnerDraft.put(this.businessPartnerDraft).then(
        ({ data }) => {
          this.$refs.tableBusinessPartnerDraft.refresh()
          this.swalSuccess(data.message).then(() => {
            this.$bvModal.hide('modal-pending-business-partner')
          })
        }
      ).catch(({ message }) => {
        this.$refs.formBusinessPartner.setErrors(message)
      }).finally(() => {
        this.state.busy = false
      })
    }
  }
}
</script>
