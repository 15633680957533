<template>
  <b-container
    fluid
  >
    <div class="d-flex justify-content-end mb-0 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-tabs
      v-model="state.currentTabIndex"
      active-nav-item-class="font-weight-bold text-uppercase text-success"
    >

      <b-tab>
        <template #title>
          <i :class="iconState('ri-file-edit', state.currentTabIndex === 0)" /> Pending
        </template>
        <BusinessPartnerPending
          ref="prPending"
          :busy="state.busy"
          :current-tab-index="state.currentTabIndex"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <i :class="iconState('ri-archive', state.currentTabIndex === 1)" /> Registered
        </template>
        <BusinessPartnerRegistered
          ref="prHistories"
          :busy="state.busy"
          :current-tab-index="state.currentTabIndex"
        />
      </b-tab>
    </b-tabs>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import BusinessPartnerPending from '@/views/ta/maintenances/business-partners/Pending'
import BusinessPartnerRegistered from '@/views/ta/maintenances/business-partners/Registered'
import formatter from '@/mixins/formatter'
import formulas from '@/mixins/formulas'
import misc from '@/mixins/misc'

export default {

  name: 'TABusinessPartnerBase',

  middleware: ['auth', 'ta'],

  metaInfo: () => ({
    title: 'Business Partners'
  }),

  components: {
    BusinessPartnerPending,
    BusinessPartnerRegistered
  },

  mixins: [formatter, misc, formulas],

  data () {
    return {
      state: {
        busy: false,
        processing: false,
        currentTabIndex: this.getCurrentTabIndexByURL()
      },
      paymentRequest: {
        id: null,
        stage: 0,
        bp_code: null,
        bp_name: null,
        bp_tin: null,
        bp_partner_type: null,
        bp_vendor_type: null,
        bp_customer_type: null,
        bp_tax_status: null,
        bp_business_tax: null,
        bp_document_type: null,
        bp_tax_code: null,
        bp_tax_description: null,
        is_cash_advance: 0,
        customer_code: null,
        customer_name: null,
        customer_tin: null,
        amount: 0.00,
        budget_code: null,
        budget_name: null,
        item_code: null,
        item_name: null,
        budget_amount: null,
        particulars: null,
        bp_transaction_type: null,
        mop: null,
        branch: null,
        report_period: null,
        for_deposit: 1,
        deposit_bank: null,
        deposit_account_number: null,
        deposit_account_name: null,
        invoice_amount: 0.00,
        taxable_amount: 0.00,
        withholding_tax: 0.00,
        amount_due: 0.00,
        approvals: [],
        attachment_path: null,
        attachments: [],
        status: null,
        remarks: null,
        created_at: null
      },
      requesterSignatory: {
        stage: 0,
        approver_label: 'Requester',
        approver_action: 'Requisition',
        approver_name: null,
        last_seen: null,
        status: 'Requested',
        status_at: null,
        remarks: null
      },
      cancellationSignatory: {
        stage: 0,
        approver_label: 'Disbursement Head',
        approver_action: 'Cancellation of Request',
        approver_name: null,
        last_seen: null,
        status: 'Canceled',
        status_at: null,
        remarks: null
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    getCurrentTabIndexByURL () {
      switch (this.$route.params.approver) {
        case 'histories' : {
          return 1
        }
        default: {
          return 0
        }
      }
    },

    onShowPaymentRequest (paymentRequest, approval, processing) {
      this.state.processing = processing
      this.paymentRequest.id = approval.id
      this.paymentRequest.stage = paymentRequest.stage
      // BUSINESS PARTNER
      this.paymentRequest.bp_code = paymentRequest.bp_code
      this.paymentRequest.bp_name = paymentRequest.bp_name
      this.paymentRequest.bp_tin = paymentRequest.bp_tin
      this.paymentRequest.bp_partner_type = paymentRequest.bp_partner_type
      this.paymentRequest.bp_vendor_type = paymentRequest.bp_vendor_type
      this.paymentRequest.bp_customer_type = paymentRequest.bp_customer_type
      this.paymentRequest.bp_tax_status = paymentRequest.bp_tax_status
      this.paymentRequest.bp_business_tax = paymentRequest.bp_business_tax
      this.paymentRequest.bp_tax_code = paymentRequest.bp_tax_code
      if (paymentRequest.tax_code) {
        this.paymentRequest.bp_tax_description = `${paymentRequest.bp_tax_code} - ${paymentRequest.tax_code.description}`
      }
      this.paymentRequest.bp_document_type = paymentRequest.bp_document_type
      // CASH ADVANCE ACCOUNT
      this.paymentRequest.is_cash_advance = paymentRequest.is_cash_advance
      this.paymentRequest.customer_code = paymentRequest.customer_code
      this.paymentRequest.customer_name = paymentRequest.customer_name
      this.paymentRequest.customer_tin = paymentRequest.customer_tin
      // BANK DETAILS
      this.paymentRequest.for_deposit = paymentRequest.for_deposit
      this.paymentRequest.deposit_bank = paymentRequest.deposit_bank
      this.paymentRequest.deposit_account_number = paymentRequest.deposit_account_number
      this.paymentRequest.deposit_account_name = paymentRequest.deposit_account_name
      // REQUEST DETAILS
      this.paymentRequest.amount = this.numberFormatter(paymentRequest.amount)
      this.paymentRequest.particulars = paymentRequest.particulars
      this.paymentRequest.bp_transaction_type = paymentRequest.bp_transaction_type
      this.paymentRequest.mop = paymentRequest.mop
      this.paymentRequest.branch = paymentRequest.branch
      this.paymentRequest.report_period = paymentRequest.report_period
      this.paymentRequest.invoice_amount = this.numberFormatter(paymentRequest.invoice_amount)
      this.paymentRequest.taxable_amount = this.numberFormatter(paymentRequest.taxable_amount)
      this.paymentRequest.withholding_tax = this.numberFormatter(paymentRequest.withholding_tax)
      this.paymentRequest.amount_due = this.numberFormatter(paymentRequest.amount_due)
      this.paymentRequest.approvals = paymentRequest.approvals
      this.paymentRequest.attachment_path = paymentRequest.attachment_path
      this.paymentRequest.attachments = paymentRequest.attachments

      this.paymentRequest.remarks = approval.remarks
      this.paymentRequest.created_at = paymentRequest.created_at

      // REQUESTER
      if (paymentRequest.requestor) {
        this.requesterSignatory.approver_name = `${paymentRequest.requestor.first_name} ${paymentRequest.requestor.last_name}`
        this.requesterSignatory.status_at = paymentRequest.created_at
        this.requesterSignatory.status = 'Requested'
        this.requesterSignatory.remarks = null

        if (paymentRequest.status === 'Canceled') {
          if (paymentRequest.canceled_at && paymentRequest.canceled_by) {
            if (Number(paymentRequest.stage) >= 3) {
              this.cancellationSignatory.approver_name = paymentRequest.canceled_by
              this.cancellationSignatory.status_at = paymentRequest.canceled_at
              this.cancellationSignatory.status = 'Canceled'
              this.cancellationSignatory.remarks = paymentRequest.canceled_remarks
            }
          }
        }
      }
      if (paymentRequest.budget_center) {
        this.paymentRequest.budget_code = paymentRequest.budget_center.budget_code
        this.paymentRequest.budget_name = paymentRequest.budget_center.budget_name
      }

      if (paymentRequest.item_center) {
        this.paymentRequest.budget_amount = paymentRequest.item_center.budget_amount
        if (paymentRequest.item_center.item) {
          this.paymentRequest.item_code = paymentRequest.item_center.item.item_code
          this.paymentRequest.item_name = paymentRequest.item_center.item.item_name
        }
      }

      this.$bvModal.show('modal-payment-request')
    },

    onValidatePaymentRequest (event, status = null) {
      event.preventDefault()
      this.$refs.formPaymentRequest.validate().then(
        allFieldsAreValid => {
          if (allFieldsAreValid && status) {
            this.swalConfirmWithRemarks({
              html: `<h6>${event.target.innerText} this Request?</h6>`,
              confirmButtonText: event.target.innerText,
              remarksRequired: status === 'Disapproved',
              customClass: {
                confirmButton: status === 'Disapproved' ? 'btn btn-sm btn-danger' : 'btn btn-sm btn-success'
              },
              preConfirm: remarks => {
                this.state.busy = true
                this.paymentRequest.status = status
                this.paymentRequest.remarks = remarks
                return this.onPutPaymentRequest({
                  id: this.paymentRequest.id,
                  status: status,
                  remarks: remarks
                })
              }
            })
          } else {
            this.swalInvalid()
          }
        }
      )
    },

    async onPutPaymentRequest (payload) {
      return await this.$refs.prPending.onPut(payload).then(
        data => {
          this.$refs.prPending.tablePendingRefresh()
          this.swalSuccess(
            () => (
              `<div>
                <p>${data.message}</p>
                ${
                  data.consumed_message && data.consumed_message.length > 4 ? (
                  `<div class="w-100 text-left d-flex flex-column">
                    <b class="text-secondary mb-2">Notice:</b>
                    <span>${data.consumed_message}</span>  
                  </div>`
                  ) : ''
                }
              </div>`
            )
          ).then(() => {
            this.$bvModal.hide('modal-payment-request')
          })
        }
      ).catch(errors => {
        this.$refs.formPaymentRequest.setErrors(errors)
      }).finally(() => {
        this.state.busy = false
      })
    }
  }
}
</script>
