const UserMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-objects-column',
    active_icon: 'fad fa-objects-column'
  },
  {
    title: 'Budgets',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.budgets'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-file-signature',
    active_icon: 'fad fa-file-signature'
  },
  {
    title: 'Payments',
    is_heading: true,
    is_active: false,
    link: {
      name: 'user.payments'
    },
    class_name: 'mt-3 font-weight-bolder'
  },
  {
    title: 'Requests',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.payments.requests'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-file',
    active_icon: 'fad fa-file'
  },
  {
    title: 'Approvals',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.payments.approvals'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-file-signature',
    active_icon: 'fad fa-file-signature'
  },
  {
    title: 'Registrations',
    is_heading: true,
    is_active: false,
    link: {
      name: 'user.registrations'
    },
    class_name: 'mt-3 font-weight-bolder'
  },
  {
    title: 'Business Partners',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.registrations.business-partners'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-user-group',
    active_icon: 'fad fa-user-group'
  }
]

export default UserMenu
